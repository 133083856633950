export   function Circle() {
    return (
      <div
        style={{
          width: "28px",
          height: "13px",
          backgroundColor: "white",
          borderTopLeftRadius: "110px",
          borderTopRightRadius: "110px",
          border: "2px solid gray",
          borderBottom: "0",
        }}
      ></div>
    );
  }