import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { BASE_URL } from '../apiConfig'
import { message, notification } from 'antd'

export const fetchPDDMissingDocs = createAsyncThunk(
    'fetchPDDMissingDocs',
    async ({ jwtBearer, requestBody }) => {
        console.log('Request Body - ', requestBody);
        const options = {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + jwtBearer,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        };

        try {
            const response = await fetch(
                `${BASE_URL}pdd/get/missing/docs/`,
                options
            );

            console.log('Request Options - ', options);
            console.log('Response -- ', response);

            // Check if response is not ok
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to add branch entry');
            }

            return await response.json(); // Assuming the response is JSON
        } catch (error) {
            console.log('Error', error);

            // Extracting error message for notification
            const errorMessage = error.message || 'An unexpected error occurred.';

            // Use a notification or alert here instead of trying to render the error object
            notification.error({
                message: 'Error',
                description: errorMessage,
                placement: 'topRight',
                duration: 5,
            });

            throw error; // Rethrow error for rejected case
        }
    }
);

const fetchPDDMissingDocsSlice = createSlice({
    name: 'fetchPDDMissingDocs',
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
        errorMessage: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchPDDMissingDocs.pending, state => {
            state.isLoading = true
            state.isError = false
            state.errorMessage = null
        })
        builder.addCase(fetchPDDMissingDocs.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
            state.isError = false
            state.errorMessage = null
        })
        builder.addCase(fetchPDDMissingDocs.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.errorMessage = action.error.message
        })
    }
})

export default fetchPDDMissingDocsSlice.reducer
