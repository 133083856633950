import React from "react";
import { Drawer, List, Typography } from "antd";

const { Title, Paragraph } = Typography;

function DocsDetailsDrawer({ docDetails, visible, onClose }) {
  const {
    missing_documents = [],
    not_applicable_docs = [],
    damaged_docs = [],
    verified_docs = [],
  } = docDetails || {}; 

  const renderDocumentCategory = (title, documents, categoryType) => (
    <div style={{ marginBottom: "20px" }}>
      <Title
        level={4}
        style={{
          fontWeight: "bold",
          marginBottom: "10px",
          color: getCategoryColor(categoryType),
        }}
      >
        {title}
      </Title>
      <List
        bordered
        dataSource={documents}
        renderItem={(item, index) => (
          <List.Item style={{ fontSize: "14px", color: "#595959" }}>
            <span>{index + 1}. {item}</span>
          </List.Item>
        )}
      />
    </div>
  );

  const getCategoryColor = (categoryType) => {
    switch (categoryType) {
      case "missing":
        return "#f5222d"; 
      case "not-applicable":
        return "#faad14"; 
      case "damaged":
        return "#1890ff"; 
      case "verified":
        return "#52c41a"; 
      default:
        return "#000"; 
    }
  };

  return (
    <Drawer
      title="Document Details"
      visible={visible}
      onClose={onClose}
      width={600}
    >
      <div>
        {renderDocumentCategory("Verified Documents", verified_docs, "verified")}
        {renderDocumentCategory("Missing Documents", missing_documents, "missing")}
        {renderDocumentCategory("Not Applicable Documents", not_applicable_docs, "not-applicable")}
        {renderDocumentCategory("Damaged Documents", damaged_docs, "damaged")}
      </div>
    </Drawer>
  );
}

export default DocsDetailsDrawer;
