import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { BASE_URL } from '../apiConfig'
import { message, notification } from 'antd'

// export const addBranchEntry = createAsyncThunk(
//     'addBranchEntry',
//     async ({ jwtBearer, requestBody }) => {
//         console.log('Request Body - ', requestBody)
//         const options = {
//             method: 'POST',
//             headers: {
//                 Authorization: 'Bearer ' + jwtBearer,
//                 'Content-Type': 'application/json' // Set content type to JSON
//             },
//             body: JSON.stringify(requestBody) // Wrap requestBody as needed
//         }

//         try {
//             const response = await fetch(
//                 `${BASE_URL}pdd/add/branch/entry/`,
//                 options
//             )
//             console.log('Request Options - ', options)
//             console.log('Response -- ', response)
//             return response.json() // Assuming the response is JSON
//         } catch (error) {
//             console.log('Error', error)
//             alert(error.message)
//             throw error
//         }
//     }
// )

export const addBranchEntry = createAsyncThunk(
    'addBranchEntry',
    async ({ jwtBearer, requestBody }) => {
        console.log('Request Body - ', requestBody);
        const options = {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + jwtBearer,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        };

        try {
            const response = await fetch(
                `${BASE_URL}pdd/add/branch/entry/`,
                options
            );

            console.log('Request Options - ', options);
            console.log('Response -- ', response);

            // Check if response is not ok
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to add branch entry');
            }

            return await response.json(); // Assuming the response is JSON
        } catch (error) {
            throw Error(error.response.data.message || error.message);
          }
    }
);

const addBranchEntrySlice = createSlice({
    name: 'addBranchEntry',
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
        errorMessage: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(addBranchEntry.pending, state => {
            state.isLoading = true
            state.isError = false
            state.errorMessage = null
        })
        builder.addCase(addBranchEntry.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
            state.isError = false
            state.errorMessage = null
        })
        builder.addCase(addBranchEntry.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.errorMessage = action.error.message
        })
    }
})

export default addBranchEntrySlice.reducer
