import React, { useState, useEffect } from 'react';
import { Drawer, Upload, message, Form, Button, Progress } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { uploadDocuments } from '../../../../redux/slice/uploadDocToWarehouse';

const { Dragger } = Upload;

const WarehouseUploadDrawer = ({ showAddBranchEntryModalVisible, handleCloseModal, jwtBearer,fetchData }) => {
    const dispatch = useDispatch();
    const { isLoading, isError, errorMessage } = useSelector((state) => state.uploadPddDocuments);

    const [fileList, setFileList] = useState([]); 
    const [secureFiles, setSecureFiles] = useState({ secure_kyc: [], secure_properties: [] }); 
    const [uploading, setUploading] = useState(false);
    const [uploadingType, setUploadingType] = useState(''); 

    // Progress states for each upload type
    const [unsecureKycProgress, setUnsecureKycProgress] = useState(0);
    const [secureKycProgress, setSecureKycProgress] = useState(0);
    const [securePropertiesProgress, setSecurePropertiesProgress] = useState(0);
    
    const [productType, setProductType] = useState('unsecure_kyc');

    useEffect(() => {
        if (productType === 'unsecure_kyc') {
            setSecureFiles({ secure_kyc: [], secure_properties: [] });
        } else {
            setFileList([]); 
        }
    }, [productType]);

    const handleFileChange = (info, type) => {
        if (productType === 'unsecure_kyc') {
            setFileList(info.fileList.slice(-1));
        } else {
            const updatedFiles = { ...secureFiles, [type]: info.fileList.slice(-1) }; 
            setSecureFiles(updatedFiles);
        }
    };

    const handleUpload = async (type) => {
        if (
            (productType === 'unsecure_kyc' && fileList.length === 0) ||
            (productType === 'secure' && (
                (secureFiles.secure_kyc.length === 0 && type === 'secure_kyc') ||
                (secureFiles.secure_properties.length === 0 && type === 'secure_properties')
            ))
        ) {
            message.error('Please select a file before uploading');
            return;
        }

        setUploading(true);
        setUploadingType(type);

        let progressValue = 0;
        let uploadStartTime = Date.now();

        // Starting progress simulation for each type
        const progressInterval = setInterval(() => {
            const elapsedTime = (Date.now() - uploadStartTime) / 1000;
            progressValue = Math.min((elapsedTime / 10) * 100, 100);

            if (type === 'unsecure_kyc') {
                setUnsecureKycProgress(progressValue);
            } else if (type === 'secure_kyc') {
                setSecureKycProgress(progressValue);
            } else if (type === 'secure_properties') {
                setSecurePropertiesProgress(progressValue);
            }

            if (progressValue >= 100) {
                clearInterval(progressInterval);
            }
        }, 2000); 

        try {
           
            if (productType === 'unsecure_kyc' && type === 'unsecure_kyc') {
                const file = fileList[0]?.originFileObj;
                if (file) {
                    await dispatch(uploadDocuments({ jwtBearer, type: `unsecure_kyc`, file })).unwrap();
                }
            } else if (productType === 'secure') {
                if (type === 'secure_kyc') {
                    const file = secureFiles.secure_kyc[0]?.originFileObj;
                    if (file) {
                        await dispatch(uploadDocuments({ jwtBearer, type: `secure_kyc`, file })).unwrap();
                    }
                } else if (type === 'secure_properties') {
                    const file = secureFiles.secure_properties[0]?.originFileObj;
                    if (file) {
                        await dispatch(uploadDocuments({ jwtBearer, type: `secure_properties`, file })).unwrap();
                    }
                }
            }

            message.success('File uploaded successfully');
            // Reset after upload
            setFileList([]);
            setSecureFiles({ secure_kyc: [], secure_properties: [] });
            setUploading(false);
            setUploadingType(''); 

            // Set all progress to 100
            setUnsecureKycProgress(100);
            setSecureKycProgress(100);
            setSecurePropertiesProgress(100);

            handleCloseModal();
        } catch (error) {
            clearInterval(progressInterval);
            setUploading(false);
            message.error(errorMessage || 'File upload failed');
            // Reset progress on failure
            setUnsecureKycProgress(0);
            setSecureKycProgress(0);
            setSecurePropertiesProgress(0);
            setUploadingType(''); 
        }
        finally{
            fetchData()
        }
    };

    const buttonStyles = () => ({
        backgroundColor: 'dodgerblue',
        color: '#fff',
        width: '100%',
        textAlign: 'center',
    });

    return (
        <Drawer
            title="Upload Document"
            placement="right"
            onClose={handleCloseModal}
            open={showAddBranchEntryModalVisible}
            width={700}
        >
            <Form layout="vertical" style={{ fontSize: '18px', padding: '0 30px' }}>

                {/* Product Type Selection */}
                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    <Button
                        onClick={() => {
                            setProductType('unsecure_kyc');
                            setSecureFiles({ secure_kyc: [], secure_properties: [] });
                        }}
                        style={{
                            width: '280px',
                            backgroundColor: productType === 'unsecure_kyc' ? '#1890ff' : 'white',
                            color: productType === 'unsecure_kyc' ? 'white' : '#333',
                        }}
                    >
                        Unsecure
                    </Button>

                    <Button
                        onClick={() => {
                            setProductType('secure');
                            setFileList([]); 
                        }}
                        style={{
                            width: '280px',
                            backgroundColor: productType === 'secure' ? '#1890ff' : 'white',
                            color: productType === 'secure' ? 'white' : '#333',
                        }}
                    >
                        Secure
                    </Button>
                </div>

                {/* Upload Section for Unsecure_kyc */}
                {productType === 'unsecure_kyc' && (
                    <div style={{ fontSize: '18px', marginTop: '70px' }}>
                        <Form.Item
                            label="Upload Excel File"
                            name="file"
                            rules={[{ required: true, message: 'Please upload an Excel file!' }]} >
                            <Dragger
                                fileList={fileList}
                                onChange={(info) => handleFileChange(info, 'unsecure_kyc')}
                                beforeUpload={() => false}
                                accept=".xlsx, .xls"
                                maxCount={1}
                                style={{ height: '180px', fontSize: '18px' }}
                            >
                                <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Dragger>

                              {/* Progress Bar */}
                        {uploading && uploadingType === 'unsecure_kyc' && (
                            <Progress
                                percent={unsecureKycProgress}
                                size="small"
                                 showInfo={false}
                                status={unsecureKycProgress < 100 ? 'active' : 'success'}
                                style={{ marginTop: '10px' }}
                            />
                        )}

                            {/* Upload button for unsecure_kyc */}
                            {fileList.length > 0 && (
                                <Button
                                    onClick={() => handleUpload('unsecure_kyc')}
                                    style={buttonStyles()}
                                    loading={uploading || isLoading}
                                >
                                    {uploading || isLoading ? 'Uploading...' : 'Upload'}
                                </Button>
                            )}
                        </Form.Item>

                      
                    </div>
                )}

                {/* Upload Section for Secure Products */}
                {productType === 'secure' && (
                    <>
                        <div style={{ fontSize: '18px', marginTop: '70px' }}>
                            <Form.Item
                                label="Upload Secure KYC Document"
                                name="secure_kyc"
                                rules={[{ required: true, message: 'Please upload the secure KYC document!' }]}>
                                <Dragger
                                    fileList={secureFiles.secure_kyc}
                                    onChange={(info) => handleFileChange(info, 'secure_kyc')}
                                    beforeUpload={() => false}
                                    accept=".xlsx, .xls"
                                    maxCount={1}
                                    style={{ height: '180px', fontSize: '18px' }}
                                >
                                    <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload Secure KYC</p>
                                </Dragger>

                                  {/* Progress Bar for secure_kyc */}
                            {uploading && uploadingType === 'secure_kyc' && (
                                <Progress
                                    percent={secureKycProgress}
                                    size="small"
                                     showInfo={false}
                                    status={secureKycProgress < 100 ? 'active' : 'success'}
                                    style={{ marginTop: '10px' }}
                                />
                            )}

                                {/* Upload button for secure_kyc */}
                                {secureFiles.secure_kyc.length > 0 && (
                                    <Button
                                        onClick={() => handleUpload('secure_kyc')}
                                        style={buttonStyles()}
                                        loading={uploading || isLoading}
                                    >
                                        {uploading || isLoading ? 'Uploading...' : 'Upload'}
                                    </Button>
                                )}
                            </Form.Item>

                          
                        </div>

                        <div style={{ fontSize: '18px', marginTop: '40px' }}>
                            <Form.Item
                                label="Upload Secure Property Document"
                                name="secure_properties"
                                rules={[{ required: true, message: 'Please upload the secure property document!' }]}>
                                <Dragger
                                    fileList={secureFiles.secure_properties}
                                    onChange={(info) => handleFileChange(info, 'secure_properties')}
                                    beforeUpload={() => false}
                                    accept=".xlsx, .xls"
                                    maxCount={1}
                                    style={{ height: '180px', fontSize: '18px' }}
                                >
                                    <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload Secure Properties</p>
                                </Dragger>

                                       {/* Progress Bar for secure_properties */}
                            {uploading && uploadingType === 'secure_properties' && (
                                <Progress
                                    percent={securePropertiesProgress}
                                    size="small"
                                     showInfo={false}
                                    status={securePropertiesProgress < 100 ? 'active' : 'success'}
                                    style={{ marginTop: '10px' }}
                                />
                            )}
                                {/* Upload button for secure_properties */}
                                {secureFiles.secure_properties.length > 0 && (
                                    <Button
                                        onClick={() => handleUpload('secure_properties')}
                                        style={buttonStyles()}
                                        loading={uploading || isLoading}
                                    >
                                        {uploading || isLoading ? 'Uploading...' : 'Upload'}
                                    </Button>
                                )}
                            </Form.Item>

                     
                        </div>
                    </>
                )}
            </Form>
        </Drawer>
    );
};

export default WarehouseUploadDrawer;
