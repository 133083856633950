import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  InputNumber,
  Card,
  Tag,
  Modal,
} from "antd";
import {
  SearchOutlined,
  DropboxOutlined,
  CodeSandboxOutlined,
} from "@ant-design/icons";
import {
  StyledCard,
  RefreshButton,
  CardContainer,
} from "../../style/styleComp";
import { useSelector, useDispatch } from "react-redux";
import { fetchPDD } from "../../../../redux/slice/getPDDData";
import { fetchPDDHoToBranch } from "../../../../redux/slice/getPDDHoToBranchData";
import { fetchPDDAppID } from "../../../../redux/slice/getPDDAppID";
import { HashLoader } from "react-spinners";
import ReturningToBranchInTransitDrawer from "./ReturningToBranchInTransitDrawer";
import ReturningToBranchReceivedDrawer from "./ReturningToBranchReceivedDrawer";
import jwt_decode from "jwt-decode";
import { addMissingDocsHoToBranch } from "../../../../redux/slice/pddAddMissingDocsHoToBranch";
import { updateHoToBranchStatus } from "../../../../redux/slice/pddUpdateHoToBranchStatus";
import { RiRefreshLine } from "react-icons/ri";
import { useSpring, animated } from "react-spring";
import { motion } from "framer-motion";
import { LeftTyre } from "../../style/truck/LeftTyre";
import { RightTyre } from "../../style/truck/RightTyre";
import { Circle } from "../../style/truck/Circle";


const { Option } = Select;

const BranchHoToBranch = ({ jwtBearer, extraProp, isInQueryChecked }) => {
  console.log("Branch JWT - ", jwtBearer);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [searchField, setSearchField] = useState("Select");
  const [enableSearch, setEnableSearch] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [modalVisible, setDetailModalVisible] = useState(false);
  const [returningToBranchReceivedDrawer, setReturningToBranchReceivedDrawer] = useState(false);
  const [selectedCardData, setSelectedCardData] = useState(null);
  const [selectedAppIDs, setSelectedAppIDs] = useState([]);
  const [branches, setBranches] = useState([]);
  const [isAnimated, setIsAnimated] = useState(false);
  const [openBox, setOpenBox] = useState(true);
  const [selectedIndex,setSelectedIndex]=useState(null)

  const floatButtonProps = useSpring({
    to: { transform: "translateY(0px)", opacity: 1 },
    from: { transform: "translateY(100px)", opacity: 0 },
    config: { tension: 200, friction: 10 },
  });


  
  const fetchData = () => {
    setLoading(true);
    Promise.all([
      dispatch(fetchPDDHoToBranch({ jwtBearer, movement: "Branch" })),
      dispatch(fetchPDDAppID({ jwtBearer })),
    ])
      .then(() => setLoading(false))
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    // const intervalId = setInterval(fetchData, 1000 * 60 * 5);
    // return () => clearInterval(intervalId);
  }, [dispatch, jwtBearer]);

  const chatData = useSelector(
    (state) => state.fetchPDDHoToBranch?.data?.message || []
  );
  const appIDData = useSelector(
    (state) => state.fetchPDDAppID?.data?.message || []
  );

  console.log("pdd ho to brach - ", chatData);

  // const branchArray = jwt_decode(jwtBearer).branch_id.replace(/[()]/g, '').split(',');
  const branchArray = extraProp.branch_id.replace(/[()]/g, "").split(",");

  const cleanAppIDData = appIDData.filter((app) =>
    branchArray.includes(app.BR_CD.split(" ")[0])
  );

  useEffect(() => {
    if (chatData && chatData.length > 0) {
      const formattedData = chatData.map((item) => ({
        ...item,
      }));
      setTableData(formattedData);
    }
  }, [chatData]);

  const handleRowClick = (record) => {
    setSelectedCardData(record);
    setDetailModalVisible(true);
    setReturningToBranchReceivedDrawer(true)
  };

  const getColumns = (data) => {
    if (!data || data.length === 0) return [];

    const keys = Object.keys(data[0]);
    return keys.map((key) => ({
      title: (
        <strong style={{ fontSize: "18px" }}>
          {key.replace(/_/g, " ").toUpperCase()}
        </strong>
      ),
      dataIndex: key,
      key: key,
      render: (text) => (
        <span style={{ fontSize: "18px" }}>
          {Array.isArray(text) ? text.join(", ") : text}
        </span>
      ),
    }));
  };

  const columns = getColumns(chatData);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearchFieldChange = (value) => {
    setSearchField(value);
    if (value) {
      setEnableSearch(false);
    }
  };

  var filteredData = tableData.filter((item) => {
    const valueToSearch = item[searchField]?.toString().toLowerCase() || ""; // Ensure it's a string or empty
    const statusCheck = !isInQueryChecked || item.status === "In-Query";

    // Ensure valueToSearch is a string before using includes
    return statusCheck && valueToSearch.includes(searchValue.toLowerCase());
  });

  filteredData = filteredData.reverse();

  console.log("filtered data - ", filteredData);

  const handleSelectChange = (value) => {
    if (!selectedAppIDs.includes(value)) {
      setSelectedAppIDs([...selectedAppIDs, value]);
    }
  };

  const handleRemoveAppID = (appID) => {
    setSelectedAppIDs(selectedAppIDs.filter((id) => id !== appID));
  };

  const handleCloseModal = () => {
    setDetailModalVisible(false);
    setSelectedCardData(null);
    setReturningToBranchReceivedDrawer(false)
  };

  const handleStatusChange = (updatedData) => {
    console.log("Change Status - ", updatedData);
    const requestBody = {
      app_id: updatedData.app_id,
      status: updatedData.status,
    };

    dispatch(updateHoToBranchStatus({ jwtBearer, requestBody }))
      .then(() => {
        dispatch(fetchPDDHoToBranch({ jwtBearer, movement: "Branch" }));
        setDetailModalVisible(false);
      })
      .catch((error) => {
        console.error("Error updating courier status:", error);
      });
  };

  const handleMissingDocs = (updatedData) => {
    console.log("Missing doc - ", updatedData);
    const requestBody = {
      branch: updatedData.branch,
      tracking_number: updatedData.tracking_number,
      docs: updatedData.docs,
    };

    dispatch(addMissingDocsHoToBranch({ jwtBearer, requestBody }))
      .then(() => {
        dispatch(fetchPDDHoToBranch({ jwtBearer, movement: "Branch" }));
        setDetailModalVisible(false);
      })
      .catch((error) => {
        console.error("Error updating courier status:", error);
      });
  };

  const handleRefresh = () => {
    setIsAnimated(true);
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      setIsAnimated(false);
    }, 1000);
    fetchData();
  };

  const boxShadowFunc = (e) => {
    console.log("Color func - ", e);
    switch (e) {
      case "In-Transit":
        return "0px 4px 8px rgba(245, 144, 39, 0.3)";
      case "In-Query":
        return "0px 4px 8px rgba(255, 0, 0, 0.3)";
      case "Received":
        return "0px 4px 8px rgba(rgba(79, 176, 41, 0.3))";
      default:
        return "0px 4px 8px rgba(0, 0, 0, 0.1)";
    }
  };

  const groupByTrkNo = (data) => {
    return data.reduce((groups, item) => {
      const { trk_no } = item;
      if (!groups[trk_no]) {
        groups[trk_no] = [];
      }
      groups[trk_no].push(item);
      return groups;
    }, {});
  };

  console.log("Branch group data - ", groupByTrkNo(filteredData));

  const bottomFrameVerification = (e) => {
    console.log("verification - ", e);
    switch (e) {
      case "4":
        return "orange";
      case "5":
        return "green";
      case "8":
        return "red";
      default:
        return "0px 4px 8px rgba(0, 0, 0, 0.1)";
    }
  };
  const headLightGradient = (e) => {
    // console.log("Color func - ", e);
    switch (e) {
      // verification pending
      case "4":
        return "linear-gradient(to right, white,white)";
      // verified
      case "5":
        return "linear-gradient(to right, white,white)";
      // missing/damaged
      case "8":
        return "linear-gradient(to right, rgba(255, 0, 0, 0.8) , rgba(255, 0, 0, 0.1))";
      default:
        return "linear-gradient(to right, white,white)";
    }
  };

  const handleInQueryCheckboxChange = (e) => {
    setIsInQueryChecked(e.target.checked);
  };

  console.log("my selected",selectedCardData)

  function TruckCargo() {
    return (
      <div
        style={{
          width: "150px",
          height: "60px",
          border: "2px solid black",
          backgroundColor: "transparent",
          // position: 'absolute',
          top: "10px",
          left: "0px",
          // borderRadius: '5px',
          borderRadius: "10px 10px 0 0",
        }}
      >
        {/* Cargo Area */}
        <div
          style={{
            color: "black",
            fontSize: "16px",
            textAlign: "center",
            lineHeight: "60px",
            fontWeight: "bold",
            //   position: 'absolute',
            width: "100%",
            top: 0,
            left: 0,
          }}
        >
          TRK1001
        </div>
      </div>
    );
  }

  function TruckEngine() {
    return (
      <>
        <div style={{ display: "flex" }}>
          <div
            style={{
              border: "solid 2px",
              height: "20px",
              width: "20px",
              borderRadius: "0 10px 0 0",
              borderLeft: "white",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              gap: "0",
              position: "relative",
              top: "11px",
              left: "-3px",
            }}
          >
            <div
              style={{
                width: "10px",
                height: "5px",
                background: "white",
                transform: "perspective(60px) rotateY(-70deg)",
                // margin: "50px",
                alignSelf: "start",
                //   backgroundColor: "yellow",
                border: "solid 2px black",
              }}
            ></div>
            <motion.span
              style={{
                // width: "60px",
                height: "10px",
                background: "white",
                //   border: "solid 1px black",
                transform: "perspective(60px) rotateY(-70deg)",
                // margin: "50px",
                alignSelf: "self-start",
                position: "relative",
                left: "-27px",
                top: "-2px",
                background: headLightGradient(items[0]?.status),
                //   "linear-gradient(to right, rgba(255, 0, 0, 0.8) , rgba(255, 0, 0, 0.1))",
              }}
              className="headLight"
              transition={{
                duration: 0.5,
                repeat: Infinity,
                repeatType: "reverse",
              }}
            ></motion.span>
          </div>
        </div>
      </>
    );
  }


  const handleCardClick = async (items, index) => {
    // Log the index of the clicked card
    console.log("Card clicked at index:", index); 
  
    // Log the entire array of items (all items with the same `trkNo`)
    console.log("Clicked card data:", items);
  
    // Access the clicked item using the index
    const clickedItem = items[index]; // Access the specific item dynamically based on the index
    console.log("Card status:", clickedItem.status); // Log the clicked item's status

    setSelectedIndex(index);
    setSelectedCardData(items)
    console.log("selected card data",selectedCardData)
  
    // Check status directly on the clicked item
    if (clickedItem.status === "1") {
      setDetailModalVisible(true);
      console.log("in status 1");
    } else if (clickedItem.status === "3") {
      setReturningToBranchReceivedDrawer(true);
      console.log("in status 3");
    } else {
      setDetailModalVisible(false);
      setReturningToBranchReceivedDrawer(false);
      console.log("in else");
    }
  };
  




console.log("is received?",returningToBranchReceivedDrawer)
  return (
    <>
      <Form layout="inline">
        <div
          className="selectAndsearch"
          style={{ display: "flex", margin: "0 30px" }}
        >
          <Form.Item style={{ marginBottom: "20px" }}>
            <Select
              value={searchField}
              onChange={handleSearchFieldChange}
              style={{
                width: 170,
                height: 49,
                borderRadius: "5px",
                border: "1px solid #d9d9d9",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                fontSize: "16px",
                //   textAlign:"center"
              }}
              dropdownStyle={{
                borderRadius: "5px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              {/* <Option value="tracking_number">Tracking Number</Option> */}
              <Option value="application_ids">Application ID</Option>
            </Select>
          </Form.Item>

          <Form.Item className="searchbarContainer">
            <Input
              placeholder="Search..."
              value={searchValue}
              onChange={handleSearchChange}
              prefix={
                <SearchOutlined
                  style={{ color: "#1890ff", marginRight: "10px" }}
                />
              }
              style={{
                height: 49,
                borderRadius: "5px",
                border: "1px solid #d9d9d9",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                fontSize: "16px",
              }}
              className="mySearchBar"
              disabled={enableSearch}
            />
          </Form.Item>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "78vw",
            textAlign: "end",
          }}
        >
          <animated.div
            style={{
              ...floatButtonProps,
              position: "fixed",
              top: "140px",
              right: "20px",
            }}
          ></animated.div>
        </div>

        <animated.div
          style={{
            ...floatButtonProps,
            position: "fixed",
            bottom: "20px",
            right: "20px",
          }}
        >
          <Button
            type="primary"
            shape="circle"
            icon={
              openBox ? (
                <CodeSandboxOutlined
                  style={{ fontSize: "36px", marginLeft: "3px" }}
                />
              ) : (
                <DropboxOutlined
                  style={{ fontSize: "36px", marginLeft: "3px" }}
                />
              )
            }
            size="large"
            // onClick={openBoxDrawer}
            onMouseEnter={() => setOpenBox(false)}
            onMouseLeave={() => setOpenBox(true)}
            style={{
              width: "70px", // Increased width
              height: "70px", // Increased height
              fontSize: "24px", // Increased font size
              backgroundColor: "red",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
            }}
          />
        </animated.div>
      </Form>
      <CardContainer>
        {Object.entries(groupByTrkNo(filteredData)).map(([trkNo, items],index) => {
          // console.log("items.verification: " + items[0].verification)
          const isAllStatusInTransit = items.every(
            (item) => item.status === "1"
          );
          const isAllVerificationPending = items.every(
            (item) => item.verification === "4"
          );

          const statusText = isAllStatusInTransit ? "IN-TRANSIT" : "RECEIVED";
          const verificationText = isAllVerificationPending
            ? "VERIFICATION PENDING"
            : "VERIFIED";

          return (
            <card
              style={{
                position: "relative",
                marginBottom: "40px",
                cursor: "pointer",
                margin: "0 30px",
              }}
              onClick={() => handleCardClick(items, index)} 
            >
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    width: "150px",
                    height: "60px",
                    border: "2px solid black",
                    backgroundColor: "transparent",
                    // position: 'absolute',
                    top: "10px",
                    left: "0px",
                    // borderRadius: '5px',
                    borderRadius: "10px 10px 0 0",
                  }}
                >
                  {/* Cargo Area */}
                  <div
                    style={{
                      color: "black",
                      fontSize: "16px",
                      textAlign: "center",
                      lineHeight: "60px",
                      fontWeight: "bold",
                      //   position: 'absolute',
                      width: "100%",
                      top: 0,
                      left: 0,
                    }}
                  >
                    {trkNo.toUpperCase()}
                  </div>
                </div>
                <div style={{ alignSelf: "flex-end" }}>
                  <div
                    style={{
                      height: "40px",
                      width: "46px",
                      borderRadius: "0 20px 0 0",
                      border: "2px solid",
                      borderLeft: "white",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        border: "solid black 2px",
                        height: "15px",
                        width: "30px",
                        marginTop: "2px",
                        borderRadius: "0 12px 0 0",
                      }}
                    ></div>
                    <span style={{ fontSize: "10px", whiteSpace: "nowrap" }}>
                      {items[0].vendor}
                    </span>
                  </div>
                </div>
                <div style={{ alignSelf: "flex-end" }}>
                  {/* <TruckEngine /> */}

                  {/* truck engine + head light */}

                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        border: "solid 2px",
                        height: "20px",
                        width: "20px",
                        borderRadius: "0 10px 0 0",
                        borderLeft: "white",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        gap: "0",
                        position: "relative",
                        top: "11px",
                        left: "-3px",
                      }}
                    >
                      <div
                        style={{
                          width: "10px",
                          height: "5px",
                          background: "white",
                          transform: "perspective(60px) rotateY(-70deg)",
                          // margin: "50px",
                          alignSelf: "start",
                          //   backgroundColor: "yellow",
                          border: "solid 2px black",
                        }}
                      ></div>
                      <motion.span
                        style={{
                          width: "60px",
                          height: "10px",
                          background: "white",
                          //   border: "solid 1px black",
                          transform: "perspective(60px) rotateY(-70deg)",
                          // margin: "50px",
                          alignSelf: "self-start",
                          position: "relative",
                          left: "-27px",
                          top: "-2px",
                          background: headLightGradient(items[0].verification),
                          //   "linear-gradient(to right, rgba(255, 0, 0, 0.8) , rgba(255, 0, 0, 0.1))",
                        }}
                        className="headLight"
                        transition={{
                          duration: 0.5,
                          repeat: Infinity,
                          repeatType: "reverse",
                        }}
                      ></motion.span>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    height: "13px",
                    width: "220px",
                    border: "2px solid",
                    borderTopColor: bottomFrameVerification(
                      items[0].verification
                    ),
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: bottomFrameVerification(
                      items[0].verification
                    ),
                  }}
                >
                  <span style={{ color: "white" }}>
                    {items[0].status && (
                      <b>
                        {" "}
                        <p
                          style={{
                            textAlign: "left",
                            width: "100%",
                            paddingLeft: "10px",
                            fontSize: "12px",
                            fontWeight: "800",
                            fontFamily: "Poppins",
                            letterSpacing: "3px",
                            marginLeft: "17px",
                          }}
                        >
                          {Array.isArray(items[0].status)
                            ? items[0].status.join(", ")
                            : items[0].status === "1"
                            ? " IN-TRANSIT"
                            : items[0].status === "3"
                            ? " RECEIVED"
                            : items[0].status}
                        </p>
                      </b>
                    )}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    position: "absolute",
                    top: "0",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      left: "180px",
                      top: "2.5px",
                    }}
                  >
                    <Circle />
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "35px",
                      top: "2.5px",
                    }}
                  >
                    <Circle />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      {" "}
                      <LeftTyre />
                    </div>
                    <div>
                      <RightTyre />
                    </div>
                  </div>
                </div>
              </div>
            </card>
          );
        })}
      </CardContainer>

      <ReturningToBranchInTransitDrawer
        visible={modalVisible}
        onClose={handleCloseModal}
        data={selectedCardData}
        all_app_id={appIDData}
        app_id={cleanAppIDData}
        onStatusChange={handleStatusChange}
        onMissingDocs={handleMissingDocs}
      />
      <ReturningToBranchReceivedDrawer
        returningToBranchReceivedDrawer={returningToBranchReceivedDrawer}
        onClose={handleCloseModal}
        data={selectedCardData}
        all_app_id={appIDData}
        app_id={cleanAppIDData}
        onStatusChange={handleStatusChange}
        onMissingDocs={handleMissingDocs}
        selectedIndex={selectedIndex}
        jwtBearer={jwtBearer}
      />
    </>
  );
};

export default BranchHoToBranch;
