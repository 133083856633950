import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { BASE_URL } from '../apiConfig'
import { message, notification } from 'antd'


export const addWarehouseEntry = createAsyncThunk(
    'addWarehouseEntry',
    async ({ jwtBearer, requestBody }) => {
        console.log('Request Body - ', requestBody);
        const options = {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + jwtBearer,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        };

        try {
            const response = await fetch(
                `${BASE_URL}pdd/warehouse/`,
                options
            );

            console.log('Request Options - ', options);
            console.log('Response -- ', response);

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to add warehouse entry');
            }

            const responseData = await response.json();

            notification.success({
                message: 'Success',
                description: responseData.message || 'Warehouse entry added successfully.',
                placement: 'topRight',
                duration: 5,
            });

            return responseData; 
        } catch (error) {
            console.log('Error', error);

            const errorMessage = error.message || 'An unexpected error occurred.';

            notification.error({
                message: 'Error',
                description: errorMessage,
                placement: 'topRight',
                duration: 5,
            });

            throw error; 
        }
    }
);


const addWarehouseEntrySlice = createSlice({
    name: 'addWarehouseEntry',
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
        errorMessage: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(addWarehouseEntry.pending, state => {
            state.isLoading = true
            state.isError = false
            state.errorMessage = null
        })
        builder.addCase(addWarehouseEntry.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
            state.isError = false
            state.errorMessage = null
        })
        builder.addCase(addWarehouseEntry.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.errorMessage = action.error.message
        })
    }
})

export default addWarehouseEntrySlice.reducer
