import React from 'react'

function CargoArea({data}) {
  return (
    <div>
      {/* Cargo Area */}
      <div
                              style={{
                                color: "black",
                                fontSize: "16px",
                                textAlign: "center",
                                lineHeight: "60px",
                                fontWeight: "bold",
                                //   position: 'absolute',
                                width: "100%",
                                top: 0,
                                left: 0,
                              }}
                            >
                              {data.tracking_number.toUpperCase()}
                            </div>
    </div>
  )
}

export default CargoArea
