import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BASE_URL } from '../apiConfig';

const formatDate = (month) => {
    const [year, monthNum] = month.split('-');
    const options = { year: 'numeric', month: '2-digit' };
    const date = new Date(`${year}-${monthNum}-01`).toLocaleDateString('en-GB', options);
    return date.replace(/ /g, '-');
};

export const downloadPendingMonthwiseSlice = createAsyncThunk(
    'downloadPendingMonthwise/downloadReport',
    async ({ jwtBearer, selectedMonth }, { rejectWithValue }) => {
        try {
            const response = await fetch(`${BASE_URL}collection/pending/month/download?month=${selectedMonth}`, {
                headers: {
                    Authorization: `Bearer ${jwtBearer}`,
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const blob = await response.blob();
            const url = URL.createObjectURL(blob);

            // Use the selected month to format the file name
            const formattedDate = formatDate(selectedMonth);
            const link = document.createElement('a');
            link.href = url;
            link.download = `Pending_Report_${formattedDate}.xlsx`; // Dynamically set the filename
            link.click();
            URL.revokeObjectURL(url);

        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export default downloadPendingMonthwiseSlice.reducer;
