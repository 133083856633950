import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BASE_URL } from '../apiConfig';

// Define the async thunk for updating in-transit application
export const updateInTransit = createAsyncThunk(
    'updateInTransit',
    async ({ jwtBearer, requestBody }) => {
        console.log('Request Body - ', requestBody);

        const options = {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + jwtBearer,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        };

        try {
            const response = await fetch(
                `${BASE_URL}pdd/update/appid/entry/intransit`,
                options
            );

            console.log('Request Options - ', options);
            console.log('Response -- ', response);

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to update branch entry');
            }

            return await response.json();
        } catch (error) {
            console.error('Error during fetch:', error.message || error);
            throw error;
        }
    }
);


export const deleteAppId = createAsyncThunk(
    'deleteAppId',
    async ({ jwtBearer, requestBody }) => {
        console.log('Request Body - ', requestBody);

        const application_id = requestBody.application_id; // Extracting application_id from requestBody
        if (!application_id) {
            throw new Error('application_id is missing');
        }

        const options = {
            method: 'DELETE',
            headers: {
                Authorization: 'Bearer ' + jwtBearer,
                'Content-Type': 'application/json',
            },
        };

        try {
            // Send DELETE request with application_id as a query parameter
            const response = await fetch(
                `${BASE_URL}pdd/delete/application/id/?application_id=${application_id}`, // Use query parameter
                options
            );

            console.log('Request Options - ', options);
            console.log('Response -- ', response);

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to delete application');
            }

            return await response.json();
        } catch (error) {
            console.error('Error during fetch:', error.message || error);
            throw error;
        }
    }
);



// Redux slice to handle both updateInTransit and deleteAppId actions
const updateInTransitSlice = createSlice({
    name: 'updateApplicationIntransit',
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
        errorMessage: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        // Handle the pending, fulfilled, and rejected states for both thunks
        builder
            // For updateInTransit
            .addCase(updateInTransit.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.errorMessage = null;
            })
            .addCase(updateInTransit.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
                state.isError = false;
                state.errorMessage = null;
            })
            .addCase(updateInTransit.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.errorMessage = action.error.message || 'An error occurred';
            })
            // For deleteAppId
            .addCase(deleteAppId.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.errorMessage = null;
            })
            .addCase(deleteAppId.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload; // This can be customized based on API response
                state.isError = false;
                state.errorMessage = null;
            })
            .addCase(deleteAppId.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.errorMessage = action.error.message || 'An error occurred';
            });
    },
});

export default updateInTransitSlice.reducer;
