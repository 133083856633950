import React, { useState } from "react";
import {
  Modal,
  Button,
  Input,
  Typography,
  Space,
  Popconfirm,
  Select,
  Drawer,
  notification,
  Popover,
  Upload
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  MyDetailedStyledTable,
  MyStyledTable,
  StyledTable,
} from "../../style/styleComp";
import {
  PlusOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  SaveOutlined,
  UploadOutlined
} from "@ant-design/icons";
import { uploadNocCalFile } from "../../../../redux/slice/uploadNocCal";

const { Text, Title } = Typography;

const ReturningToBranchInTransitDrawer = ({
  returningToBranchReceivedDrawer,
  onClose,
  data,
  onStatusChange,
  selectedIndex,
  jwtBearer
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentAppId, setCurrentAppId] = useState("");

  const dispatch=useDispatch()

  console.log("Callback data modal received- ", data);

  const isDataAvailable = Array.isArray(data) && data.length > 0;
  
  const mydata = isDataAvailable ? data : [];

  const handleFinalMarkReceived = () => {
    console.log("mark as received - ", data.application_ids);
    if (onStatusChange) {
      const callbackData = {
        app_id: data.flatMap((item) => item.app_id),
        status: "Received",
      };
      console.log("Callback data - ", callbackData);
      onStatusChange(callbackData);
    }
  };


  const { uploading, fileUrl, error } = useSelector((state) => state.uploadNocCal);



  // Columns for the table
  const columns = [
    {
      title: "POD No",
      dataIndex: "trk_no",
      key: "trk_no",
      render: (value) => value,
    },
    {
      title: "Name",
      dataIndex: "app_name",
      key: "app_name",
      render: (value) => value,
    },
    // {
    //   title: "Branch",
    //   dataIndex: "branch",
    //   key: "branch",
    //   render: (value) => value,
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (value) => value,
    // },
    {
      title: "Carton No",
      dataIndex: "carton_no",
      key: "carton_no",
      render: (value) => value,
    },
    {
      title: "Prop File No",
      dataIndex: "prop_file_no",
      key: "prop_file_no",
      render: (value) => value || "N/A", // Handle null or undefined values
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (value) => new Date(value).toLocaleString(),
    },
    {
      title: "Partner Loan ID",
      dataIndex: "partner_loan_id",
      key: "partner_loan_id",
      render: (value) => value,
    },
    {
      title: "App ID",
      dataIndex: "app_id",
      key: "app_id",
      render: (value) => value,
    },
    {
      title: "Verification",
      dataIndex: "verification",
      key: "verification",
      render: (value) => value,
    },
    {
      title: "Box No",
      dataIndex: "box_no",
      key: "box_no",
      render: (value) => value,
    },
    {
      title: "File Barcode No",
      dataIndex: "file_barcode_no",
      key: "file_barcode_no",
      render: (value) => value,
    },
    {
      title: "Prop File Barcode No",
      dataIndex: "prop_file_barcode_no",
      key: "prop_file_barcode_no",
      render: (value) => value,
    },
    // {
    //   title: "Updated At",
    //   dataIndex: "updated_at",
    //   key: "updated_at",
    //   render: (value) => new Date(value).toLocaleString(),
    // },
    {
      title: "noc_file",
      key: "noc_file",
      dataIndex:"noc_file",
      render:(value)=> {
        return  <a href={`${value}`}>Download</a>
      },
     
    },
    {
      title: "CAL",
      key: "cal_file",
      render: (_, record) => {
        const { app_id, status, cal_file } = record;
    
        // Check if noc_file has the value '10'
        if (cal_file) {
          return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
             <a href={cal_file} target="_blank" rel="noopener noreferrer">Download</a> 
            </div>
          );
        }
    
        // If status is "13", show the file upload components
        if (status === "3") {
          return (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
              <Upload
                showUploadList={false}
                beforeUpload={(file) => {
                  setSelectedFile(file);
                  setCurrentAppId(app_id); 
                  return false; 
                }}
              >
                <Button icon={<UploadOutlined />} style={{ marginTop: "10px" }}>Select File</Button>
              </Upload>
              <Button
                type="primary"
                onClick={handleFileUpload}
                loading={uploading}
                style={{ marginTop: 10 }}
              >
                {uploading ? "Uploading..." : "Upload"}
              </Button>
            </div>
          );
        }
    
        return null;
      },
    }
  ];

  // Helper functions for displaying status and verification text
  const getStatusText = (statusCode) => {
    switch (statusCode) {
      case "1":
        return "In-Transit";
      case "2":
        return "In-Query";
      case "3":
        return "Received";
      default:
        return "";
    }
  };

  const getVerificationText = (statusCode) => {
    switch (statusCode) {
      case "4":
        return "Pending at HO";
      case "5":
        return "Verified";
      case "8":
        return "Missing/Damaged Documents";
      default:
        return "";
    }
  };

  // Handling the drawer close event
  const handleDrawerClose = () => {
    if (openDialog) {
      setOpenDialog(true);
      console.log("open dialog", openDialog);
    } else {
      onClose();
    }
  };

  // Save and close the modal
  const handleSaveAndClose = () => {
    handleFinalMarkReceived();
    setOpenDialog(false);
  };

  const handleFileChange = (info) => {
    if (info.file.status === "done") {
      notification.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      notification.error(`${info.file.name} file upload failed.`);
    }
  };

  // Handle file upload (triggered by button click)
  const handleFileUpload = () => {
    if (!selectedFile || !jwtBearer) {
      console.error("Please select a file and ensure you are logged in.");
      return;
    }

    const reqBody={
      applicationId: currentAppId,
      fileType: "cal",
    }

    console.log("reqBody",reqBody)

    // Dispatch the thunk to upload the file with the appId and file type
    dispatch(
      uploadNocCalFile({
        file: selectedFile,
      ...reqBody,
        jwtBearer,
      })
    );

    
  };

  return (
    <Drawer
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          <Popover title="In-Transit" trigger="hover">
            <InfoCircleOutlined
              style={{
                marginRight: "8px",
                color: "#1890ff",
                fontSize: "28px",
              }}
            />
          </Popover>

          <Title
            level={4}
            style={{
              marginBottom: "16px",
              color: "rgba(0, 0, 0, 0.7)",
              fontSize: "20px",
              fontFamily: "Poppins",
              marginLeft: "5px",
            }}
          >
            Courier details
          </Title>
        </div>
      }
      visible={returningToBranchReceivedDrawer}
      onClose={handleDrawerClose}
      width={850}
    >
      <div style={{ padding: "0 20px" }}>
        {/* Status Section */}
        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "0 8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            justifyContent: "space-between",
            height: "50px",
          }}
        >
          <strong style={{ color: "#555" }}>Status :</strong>
          <Text
            style={{
              fontSize: "20px",
              fontFamily: "Poppins",
              backgroundColor: "green",
              padding: "0px 8px",
              color: "white",
              borderRadius: "6px",
            }}
          >
            {getStatusText(mydata[0]?.status)} {/* Access status correctly */}
          </Text>
        </Space>

        {/* POD Number */}
        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>POD Number :</strong>
          <Text
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              fontWeight: "800",
            }}
          >
            {mydata[0]?.trk_no}
          </Text>
        </Space>
        {/* POD Number */}
        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>Branch:</strong>
          <Text
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              fontWeight: "800",
            }}
          >
            {mydata[0]?.branch}
          </Text>
        </Space>

        {/* Verification Status */}
        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>Verification Status :</strong>
          <Text
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              fontWeight: "800",
            }}
          >
            {getVerificationText(mydata[0]?.verification)}
          </Text>
        </Space>
      </div>

      <MyStyledTable
        columns={columns}
        style={{ marginTop: "40px", marginLeft: "20px", marginRight: "20px" }}
        dataSource={
          isDataAvailable
            ? data.map((item, index) => ({
                ...item,
                key: index,
              }))
            : []
        }
        // expandable={{
        //   expandedRowRender: (record) =>
        //     record.doc_details ? renderDocDetails(record.doc_details) : null,
        //   rowExpandable: (record) => !!record.doc_details,
        // }}
        pagination={{ pageSize: 5 }}
      />

      {/* Modal to confirm marking as received */}
      <Modal
        title="Mark as Received"
        visible={openDialog}
        onOk={handleSaveAndClose}
        onCancel={() => setOpenDialog(false)}
      >
        <p>Are you sure you want to mark this as received?</p>
      </Modal>
    </Drawer>
  );
};

export default ReturningToBranchInTransitDrawer;
