import React, { useState } from "react";
import {
  Drawer,
  Button,
  Popconfirm,
  Typography,
  Popover,
  Space,
  Modal,
} from "antd";
import { MyStyledTable } from "../../style/styleComp";
import Card from "antd/es/card/Card";
import moment from "moment";
import {
  PlusOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";

const HoDetailDrawer = ({
  visible,
  onClose,
  data = {},
  onStatusChange,
  statusDetails,
  countDetails,
}) => {
  const { Text, Title } = Typography;

  const [openDialog, setOpenDialog] = useState(false);
  const [istempMark, setIsTempMark] = useState(false);

  console.log("Data passed to the modal:", data, countDetails, statusDetails);

  if (!data || typeof data !== "object" || !data.tracking_number) {
    console.error("Invalid data format:", data);
    return <div></div>;
  }

  const getStatusText = (statusCode) => {
    switch (statusCode) {
      case "1":
        return "In-Transit";
      case "2":
        return "In-Query";
      case "3":
        return "Received";
      default:
        return "";
    }
  };

  const handleFinalMarkReceived = () => {
    console.log("mark as received - ", data.application_ids);
    if (onStatusChange) {
      const callbackData = {
        tracking_number: data.tracking_number,
        app_id: data.application_ids,
        status: "Received",
      };
      console.log("Callback data - ", callbackData);
      onStatusChange(callbackData);
    }
  };

  const tableData = data.application_ids.map((appId) => {
    const applicant = data.applicant_details[appId];
    return {
      key: appId,
      tracking_number: data.tracking_number,
      status: getStatusText(data?.status),
      branch: data.branch,
      created_at: moment.utc(data.created_at).utcOffset(5.5).format("DD-MM-YYYY"),
      applicant_name: applicant ? applicant.applicant_name : "",
      partner_loan_id: applicant ? applicant.partner_loan_id : "",
      application_id: appId,
    };
  });

  console.log("tableData", tableData);

  const columns = [
    // {
    //   title: "POD Number",
    //   dataIndex: "tracking_number",
    //   key: "tracking_number",
    // },
    {
      title: "Application ID",
      dataIndex: "application_id",
      key: "application_id",
    },

    {
      title: "Partner Loan ID",
      dataIndex: "partner_loan_id",
      key: "partner_loan_id",
    },
    {
      title: "Applicant Name",
      dataIndex: "applicant_name",
      key: "applicant_name",
    },

    // { title: "Status", dataIndex: "status", key: "status" },

    { title: "Branch", dataIndex: "branch", key: "branch" },

    { title: "Sent on", dataIndex: "created_at", key: "created_at" },
  ];

  const handleDrawerClose = () => {
    if (istempMark) {
      setOpenDialog(true);
      console.log("open dialog", openDialog);
    }
  else{
 onClose()
  }
  };

  const handleSaveAndClose = () => {
    handleFinalMarkReceived();
    // onClose();
    setOpenDialog(false);
  };

  const handleTempMarkReceived = () => {
    setIsTempMark(!istempMark);
  };

  const handleCancelAndClose = () => {
    setOpenDialog(false);
    onClose();
    setIsTempMark(false)
  };

  const isDataAvailable = tableData.length > 0;

  return (
    // new design
    <Drawer
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          <Popover
            // content={`This courier has ${data.} number of Application ID (s) which are In-Transit now`}
            title="In-Transit"
            trigger="hover"
          >
            <InfoCircleOutlined
              style={{
                marginRight: "8px",
                color: "#1890ff",
                fontSize: "28px",
              }}
            />
          </Popover>

          <Title
            level={4}
            style={{
              marginBottom: "16px",
              color: "rgba(0, 0, 0, 0.7)",
              fontSize: "20px",
              fontFamily: "Poppins",
              marginLeft: "5px",
            }}
          >
            Courier details
          </Title>
        </div>
      }
      visible={visible}
      onClose={handleDrawerClose}
      width={850}
    >
      <div style={{ padding: "0 20px" }}>
        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "0 8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>Status :</strong>
          {istempMark ? (
            <Text
              style={{
                fontSize: "18px",
                fontFamily: "Poppins",
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "green",
                padding: "3px 8px",
                color: "white",
                borderRadius: "6px",
              }}
            >
              Received
            </Text>
          ) : (
            <Text
              style={{
                fontSize: "18px",
                fontFamily: "Poppins",
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "orange",
                padding: "3px 8px",
                color: "white",
                borderRadius: "6px",
              }}
            >
              {tableData[0]?.status}
            </Text>
          )}
        </Space>

        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>Tracking Number :</strong>
          <Text
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              display: "flex",
              justifyContent: "space-between",
              fontWeight: "800",
            }}
          >
            {tableData[0]?.tracking_number}
          </Text>
        </Space>

        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>Branch :</strong>
          <Text
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {tableData[0]?.branch}
          </Text>
        </Space>
        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "8px",
            borderRadius: "4px",
            // backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
            color: "white",
          }}
        >
          <strong style={{ color: "#555" }}>Recieved :</strong>

          {/* rendering received btn according to temp mark  */}

          {istempMark ? (
            <Button
              type="danger"
              onClick={handleTempMarkReceived}
              disabled={
                isDataAvailable &&
                (data.status === "Received" || data.status === "3")
              }
              style={{
                textAlign: "start",
                marginLeft: "25px",
                backgroundColor: "orange",
                color: "white",
                fontFamily: "Poppins",
                fontSize: "18px",
              }}
            >
              {isDataAvailable && data.status === "Received"
                ? "All Items Received"
                : "Click Here To Mark As In-Transit"}
            </Button>
          ) : (
            <Button
              type="primary"
              onClick={handleTempMarkReceived}
              disabled={
                isDataAvailable &&
                (data.status === "Received" || data.status === "3")
              }
              style={{
                textAlign: "start",
                marginLeft: "25px",
                backgroundColor: "red",
                color: "white",
                fontFamily: "Poppins",
                fontSize: "18px",
              }}
            >
              {isDataAvailable && data.status === "Received"
                ? "All Items Received"
                : "Click Here To Mark As Received"}
            </Button>
          )}
        </Space>

        <MyStyledTable
          columns={columns}
          dataSource={isDataAvailable ? tableData : []}
          pagination={{
            pageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "20", "50"],
          }}
          rowKey="key"
          style={{ marginTop: "30px" }}
        />
      </div>

      {/* Confirmation Dialog */}
      <Modal
        visible={openDialog}
        title="Unsaved Changes"
        onOk={handleSaveAndClose}
        onCancel={handleCancelAndClose}
        cancelText="Don't Save"
        okText="Save Changes"
        centered
      >
        <Text>Do you want to save ?</Text>
      </Modal>
    </Drawer>
  );
};

export default HoDetailDrawer;
