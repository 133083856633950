import React from 'react'

function BottomFrame({ data }) {
    const bottomFrameVerification = (e) => {
        // console.log("verification status - ", e);
        switch (e) {
            case "4":
                return "orange";
            case "5":
                return "green";
            case "8":
                return "red";
            default:
                return "0px 4px 8px rgba(0, 0, 0, 0.1)";
        }
    };
    return (
        <div>
            <div
                style={{
                    height: "13px",
                    width: "220px",
                    border: "2px solid",
                    borderTopColor: bottomFrameVerification(
                        data.verification
                    ),
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: bottomFrameVerification(
                        data.verification
                    ),
                }}
            >
                <span style={{ color: "white" }}>
                    {data.status && (
                        <b>
                            {" "}
                            <p
                                style={{
                                    textAlign: "left",
                                    width: "100%",
                                    paddingLeft: "10px",
                                    fontSize: "12px",
                                    fontWeight: "800",
                                    fontFamily: "Poppins",
                                    letterSpacing: "3px",
                                    marginLeft: "17px",
                                }}
                            >
                                {Array.isArray(data.status)
                                    ? data.status.join(", ")
                                    : data.status === "1"
                                        ? " IN-TRANSIT"
                                        : data.status === "3"
                                            ? " RECEIVED"
                                            : data.status}
                            </p>
                        </b>
                    )}
                </span>
            </div>
        </div>
    )
}

export default BottomFrame
