import React, { useState } from "react";
import {
  Modal,
  Button,
  Input,
  Typography,
  Space,
  Popconfirm,
  Select,
  Drawer,
  notification,
  Popover,
} from "antd";
import {
  MyDetailedStyledTable,
  MyStyledTable,
  StyledTable,
} from "../../style/styleComp";
import {
  PlusOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";

const { Text, Title } = Typography;

const DetailModalHO = ({ visible, onClose, data, onStatusChange }) => {
  console.log("Callback data modal - ", data);
  const [openDialog, setOpenDialog] = useState(false);
  const [istempMark, setIsTempMark] = useState(false);

  const handleFinalMarkReceived = () => {
    console.log("mark as received - ", data.application_ids);
    if (onStatusChange) {
      const callbackData = {
        app_id: data.flatMap((item) => item.app_id),
        status: "Received",
      };
      console.log("Callback data - ", callbackData);
      onStatusChange(callbackData);
    }
  };

  // Render document details for the expanded row
  const renderDocDetails = (docDetails) => {
    if (!docDetails) {
      return <p>No document details available.</p>;
    }

    const {
      verified_docs = [],
      missing_documents = [],
      not_applicable_docs = [],
      damaged_docs = [],
    } = docDetails;

    // const uploadColumn = {
    //     title:"upload",
    //     render: (text, record) => {
    //         const status = statusDetails.find(
    //           (item) => item.app_id === record.APP_ID
    //         );
    // };

    return (
      <div>
        <p>
          <strong>Verified Documents:</strong>{" "}
          {verified_docs.length > 0 ? verified_docs.join(", ") : "None"}
        </p>
        <p>
          <strong>Missing Documents:</strong>{" "}
          {missing_documents.length > 0 ? missing_documents.join(", ") : "None"}
        </p>
        <p>
          <strong>Not Applicable Documents:</strong>{" "}
          {not_applicable_docs.length > 0
            ? not_applicable_docs.join(", ")
            : "None"}
        </p>
        <p>
          <strong>Damaged Documents:</strong>{" "}
          {damaged_docs.length > 0 ? damaged_docs.join(", ") : "None"}
        </p>
      </div>
    );
  };

  const columns = [
    {
      title: "Trk No",
      dataIndex: "trk_no",
      key: "trk_no",
      render: (value) => value,
    },
    {
      title: "App Name",
      dataIndex: "app_name",
      key: "app_name",
      render: (value) => value,
    },
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
      render: (value) => value,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value) => value,
    },
    {
      title: "Carton No",
      dataIndex: "carton_no",
      key: "carton_no",
      render: (value) => value,
    },
    {
      title: "Prop File No",
      dataIndex: "prop_file_no",
      key: "prop_file_no",
      render: (value) => value || "N/A", // Handle null or undefined values
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (value) => new Date(value).toLocaleString(),
    },
    {
      title: "Partner Loan ID",
      dataIndex: "partner_loan_id",
      key: "partner_loan_id",
      render: (value) => value,
    },
    {
      title: "App ID",
      dataIndex: "app_id",
      key: "app_id",
      render: (value) => value,
    },
    {
      title: "Verification",
      dataIndex: "verification",
      key: "verification",
      render: (value) => value,
    },
    {
      title: "Box No",
      dataIndex: "box_no",
      key: "box_no",
      render: (value) => value,
    },
    {
      title: "File Barcode No",
      dataIndex: "file_barcode_no",
      key: "file_barcode_no",
      render: (value) => value,
    },
    {
      title: "Prop File Barcode No",
      dataIndex: "prop_file_barcode_no",
      key: "prop_file_barcode_no",
      render: (value) => value || "N/A",
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (value) => new Date(value).toLocaleString(),
    },
  ];

  // Check if data is not null and is an array
  const isDataAvailable = Array.isArray(data) && data.length > 0;

  const getStatusText = (statusCode) => {
    switch (statusCode) {
      case "1":
        return "In-Transit";
      case "2":
        return "In-Query";
      case "3":
        return "Received";
      default:
        return "";
    }
  };

  const getVerificationText = (statusCode) => {
    switch (statusCode) {
      case "4":
        return "Pending at HO";
      case "5":
        return "Verified";
      case "8":
        return "Missing/Damaged Documents";
      default:
        return "";
    }
  };

  const handleDrawerClose = () => {
    if (istempMark) {
      setOpenDialog(true);
      console.log("open dialog", openDialog);
    } else {
      onClose();
    }
  };

  const handleSaveAndClose = () => {
    handleFinalMarkReceived();
    // onClose();
    setOpenDialog(false);
  };

  const handleTempMarkReceived = () => {
    setIsTempMark(!istempMark);
  };

  const handleCancelAndClose = () => {
    setOpenDialog(false);
    onClose();
    setIsTempMark(false);
  };

  return (
    <Drawer
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          <Popover
            // content={`This courier has ${myAppIdArr.length} number of Application ID (s) which are In-Transit now`}
            title="In-Transit"
            trigger="hover"
          >
            <InfoCircleOutlined
              style={{
                marginRight: "8px",
                color: "#1890ff",
                fontSize: "28px",
              }}
            />
          </Popover>

          <Title
            level={4}
            style={{
              marginBottom: "16px",
              color: "rgba(0, 0, 0, 0.7)",
              fontSize: "20px",
              fontFamily: "Poppins",
              marginLeft: "5px",
            }}
          >
            Courier details
          </Title>
        </div>
      }
      visible={visible}
      width={700}
      placement="right"
      onClose={onClose}
      visible={visible}
    >
      <div style={{ padding: "0 20px" }}>
        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "0 8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>Status :</strong>
          {istempMark ? (
            <Text
              style={{
                fontSize: "18px",
                fontFamily: "Poppins",
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "green",
                padding: "3px 8px",
                color: "white",
                borderRadius: "6px",
              }}
            >
              Received
            </Text>
          ) : (
            <Text
              style={{
                fontSize: "18px",
                fontFamily: "Poppins",
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "orange",
                padding: "3px 8px",
                color: "white",
                borderRadius: "6px",
              }}
            >
              {getStatusText(data?.[0]?.status)}
            </Text>
          )}
        </Space>

        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>Tracking Number :</strong>
          <Text
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              display: "flex",
              justifyContent: "space-between",
              fontWeight: "800",
            }}
          >
            {data?.[0]?.trk_no}
          </Text>
        </Space>

        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>Branch :</strong>
          <Text
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {data?.[0]?.branch}
          </Text>
        </Space>
        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "8px",
            borderRadius: "4px",
            // backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
            color: "white",
          }}
        >
          <strong style={{ color: "#555" }}>Recieved :</strong>

          {/* rendering received btn according to temp mark  */}

          {istempMark ? (
            <Button
              type="danger"
              onClick={handleTempMarkReceived}
              disabled={
                isDataAvailable &&
                (data.status === "Received" || data.status === "3")
              }
              style={{
                textAlign: "start",
                marginLeft: "25px",
                backgroundColor: "orange",
                color: "white",
                fontFamily: "Poppins",
                fontSize: "18px",
              }}
            >
              {isDataAvailable && data.status === "Received"
                ? "All Items Received"
                : "Click Here To Mark As In-Transit"}
            </Button>
          ) : (
            <Button
              type="primary"
              onClick={handleTempMarkReceived}
              disabled={
                isDataAvailable &&
                (data.status === "Received" || data.status === "3")
              }
              style={{
                textAlign: "start",
                marginLeft: "25px",
                backgroundColor: "red",
                color: "white",
                fontFamily: "Poppins",
                fontSize: "18px",
              }}
            >
              {isDataAvailable && data.status === "Received"
                ? "All Items Received"
                : "Click Here To Mark As Received"}
            </Button>
          )}
        </Space>
      </div>

      <MyStyledTable
        columns={columns}
        style={{ marginTop: "40px", marginLeft: "20px", marginRight: "20px" }}
        dataSource={
          isDataAvailable
            ? data.map((item, index) => ({
                ...item,
                key: index,
              }))
            : []
        }
        // expandable={{
        //   expandedRowRender: (record) =>
        //     record.doc_details ? renderDocDetails(record.doc_details) : null,
        //   rowExpandable: (record) => !!record.doc_details,
        // }}
        pagination={{ pageSize: 5 }}
      />
      <Popconfirm
        title="Are you sure you want to mark as received?"
        onConfirm={handleFinalMarkReceived}
        okText="Yes"
        cancelText="No"
      >
        <Button
          type="primary"
          disabled={
            isDataAvailable &&
            (data.every((item) => item.status === "Received") ||
              data.some((item) => item.status === "3"))
          }
          style={{ textAlign: "start" }}
        >
          {isDataAvailable && data.every((item) => item.status === "Received")
            ? "All Items Received"
            : "Mark as Received"}
        </Button>
      </Popconfirm>

      {/* Confirmation Dialog */}
      <Modal
        visible={openDialog}
        title="Unsaved Changes"
        onOk={handleSaveAndClose}
        onCancel={handleCancelAndClose}
        cancelText="Don't Save"
        okText="Save Changes"
        centered
      >
        <Text>Do you want to save ?</Text>
      </Modal>
    </Drawer>
  );
};

export default DetailModalHO;
