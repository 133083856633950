import { configureStore } from '@reduxjs/toolkit'
import stageReportReducer from './slice/stageReportSlice'
import downloadCreditReducer from './slice/downloadCreditSlice'
import branchReportReducer from './slice/branchReportSlice'
import creditReportReducer from './slice/creditReportSlice'
import supervisorReportReducer from './slice/supervisorReportSlice'
import LTReportReducer from './slice/LTReportSlice'
import adminReducer from './slice/adminSlice'
// import collectionReducer from './slice/collectionSlice'
import collectionReducer from './slice/collectionReportSlice'
import loginUserReducer from './slice/loginUserSlice'
import getGroupReducer from './slice/getGroupSlice'
import createGroupReducer from './slice/createGroupSlice'
import createUserReducer from './slice/createUserSlice'
import updatedOnReducer from './slice/updatedOnSlice'
import creditUpdatedOnReducer from './slice/updatedOnCreditSlice'
import supervisorUpdatedOnReducer from './slice/updatedOnSupervisorSlice'
import sidenavCollapseReducer from './slice/sidenavCollapseSlice'
import LTUpdatedOnReducer from './slice/updatedOnLTSlice'
import LeadUpdatedOnReducer from './slice/updatedOnLeadSlice'
import filterReducer from './slice/filterSlice'
import leadsReducer from './slice/leadsSlice'
import branchMISReducer from './slice/getMISSlice'
import opsMISReducer from './slice/getOpsMISSlice'
import cmaRegisteredUsersReducer from './slice/cmaRegUserSlice'
import inventoryReducer from './slice/getInventorySlice'
import userTicketsSliceReducer from './slice/getCMAUserTickets'
import defaultResolutionSliceReducer from './slice/getDefaultResolution'
import defaultQuestionSliceReducer from './slice/getDefaultQuestion'
import CMADepartmentSliceReducer from './slice/getCMADepartment'
import CMAUserDepartmentSliceReducer from './slice/getCMAUserDepartment'
import getAllBASliceReducer from './slice/getAllBASlice'
import fetchSavedCMAWidgets from './slice/fetchSavedCMAWidgets'
import fetchChatDataReducer from './slice/getChatData'
import fetchPDDReducer from './slice/getPDDData'
import fetchPDDHoToBranchReducer from './slice/getPDDHoToBranchData'
import fetchPDDMasterReducer from './slice/getPDDMaster'
import fetchPDDAppIDReducer from './slice/getPDDAppID'
import fetchPDDAppIDRFWReducer from './slice/getPDDAppIDRFW'
import fetchPDDAppIDRFWPhyReducer from './slice/getPDDAppIDRFWPhy'
import fetchPDDAppIDRecalledReducer from './slice/getPDDAppIDRecalled'
import fetchBureauReducer from './slice/fetchBureau'
import fetchAssignedBranchReducer from './slice/getUserDetails'
import fetchPDDWarehouseReducer from './slice/getPDDDWarehouseData'
import fetchPDDWarehouseToHoReducer from './slice/getPDDDWarehouseToHoData'
import fetchOperationsData from './slice/fetchOperationsData'
import updateOperationsIsAvailableReducer from './slice/updateOperations'
import fetchBranchReducer from './slice/getBranchData'
import getRepeatEligibilityReducer from './slice/fetchRepeatEligibilityData'
import downloadRepeatEligibilityReducer from './slice/downloadRepeatEligibility'
import uploadDocToWarehouseReducer from './slice/uploadDocToWarehouse'
import updateApplicationIntransitReducer from './slice/pddUpdateIntransitEntry'
import downloadPendingMonthReportReducer from "./slice/downloadPendingMonth"
import getPDDAppIdReducer from "./slice/getPDDAppID"
import uploadNocCalReducer from "./slice/uploadNocCal"
import downloadZohoMisLeadReducer from './slice/downloadZohoMisLead'

export const store = configureStore({
  reducer: {
    stageReport: stageReportReducer,
    leadsReports: leadsReducer,
    branchReports: branchReportReducer,
    creditReports: creditReportReducer,
    supervisorReports: supervisorReportReducer,
    LTReports: LTReportReducer,
    admin: adminReducer,
    // collection: collectionReducer,
    collectionData: collectionReducer,
    loginUser: loginUserReducer,
    groupDetails: getGroupReducer,
    createGroup: createGroupReducer,
    createUser: createUserReducer,
    updatedOn: updatedOnReducer,
    creditUpdatedOn: creditUpdatedOnReducer,
    supervisorUpdatedOn: supervisorUpdatedOnReducer,
    sidenavCollapse: sidenavCollapseReducer,
    LTUpdatedOn: LTUpdatedOnReducer,
    LeadUpdatedOn: LeadUpdatedOnReducer,
    filter: filterReducer,
    branchMIS: branchMISReducer,
    opsMIS: opsMISReducer,
    cmaRegUsers: cmaRegisteredUsersReducer,
    inventory: inventoryReducer,
    userTicketsSlice: userTicketsSliceReducer,
    defaultResolutionSlice: defaultResolutionSliceReducer,
    defaultQuestionSlice: defaultQuestionSliceReducer,
    CMADepartmentSlice: CMADepartmentSliceReducer,
    CMAUserDepartmentSlice: CMAUserDepartmentSliceReducer,
    getAllBASlice: getAllBASliceReducer,
    fetchSavedCMAWidgets: fetchSavedCMAWidgets,
    fetchChatData: fetchChatDataReducer,
    fetchPDD: fetchPDDReducer,
    fetchPDDHoToBranch: fetchPDDHoToBranchReducer,
    fetchPDDMaster: fetchPDDMasterReducer,
    fetchPDDAppID: fetchPDDAppIDReducer,
    fetchPDDAppIDRFW: fetchPDDAppIDRFWReducer,
    fetchPDDAppIDRFWPhy: fetchPDDAppIDRFWPhyReducer,
    fetchPDDAppIDRecalled: fetchPDDAppIDRecalledReducer,
    fetchPDDWarehouse: fetchPDDWarehouseReducer,
    fetchPDDWarehouseToHo: fetchPDDWarehouseToHoReducer,
    fetchBureau: fetchBureauReducer,
    fetchAssignedBranch: fetchAssignedBranchReducer,
    operationsData: fetchOperationsData,
    updateOperationsIsAvailable: updateOperationsIsAvailableReducer,
    fetchBranch: fetchBranchReducer,
    repeatEligibility: getRepeatEligibilityReducer,
    downloadRepeatEligibility: downloadRepeatEligibilityReducer,
    downloadZohoMisLead: downloadZohoMisLeadReducer,
    uploadPddDocuments: uploadDocToWarehouseReducer,
    updateApplicationIntransit: updateApplicationIntransitReducer,
    pddDataSlice: getPDDAppIdReducer,
    uploadNocCal: uploadNocCalReducer

  }
})
