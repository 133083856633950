import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { useAuth } from "../../../context/AuthContext";
import { fetchAllBA } from "../../../redux/slice/getAllBASlice";
import {
  Spin,
  Table,
  Card,
  Modal,
  Button,
  Input,
  Tabs,
  Upload,
  message,
  Tooltip,
  Radio,
  Typography,
  Divider,
  Form,
  Select,
  Row,
  Col,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { CSVLink } from "react-csv";
import { updateCollectionBA } from "../../../redux/slice/updateCollectionBA";
import { downloadZohoMisLead, downloadZohoFTDLead, downloadZohoFTMLead } from "../../../redux/slice/downloadZohoMisLead";
import { StyledButton } from "../style/styleComp";

const ZohoMIS = (props) => {
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const { authState, onLogout } = useAuth();
  const [iframeSrc1, setIframeSrc1] = useState("");
  const [iframeSrc2, setIframeSrc2] = useState("");
  const [activeTabKey, setActiveTabKey] = useState("1");

  var token_decoded = authState.token;
  var jwtBearer = authState.token;
  var jwt_payload = "";
  var jwt_payload_group = [];
  var jwt_payload_user = "";
  var jwt_payload_role = "";
  var jwt_payload_branch = "";
  if (jwtBearer) {
    console.log("Token decoded");
    jwt_payload = jwt_decode(jwtBearer);
    // console.log("Token payload", jwt_payload);
    // jwt_payload_group = jwt_payload.group_id
    jwt_payload_group = props.userDetails?.group_id;
    jwt_payload_user = jwt_payload.user_id;
    // jwt_payload_role = jwt_payload.role
    jwt_payload_role = props.userDetails?.role;
    // console.log("jwt_payload_user", jwt_payload_user);
    // console.log("jwt_payload_role", jwt_payload_role);
    // jwt_payload_branch = jwt_payload.branch_id
    jwt_payload_branch = props.userDetails?.branch_id;
  }

  const { isDownloading, isErrorDownload, isDownloadingFTM, isDownloadingFTD, isErrorDownloadFTM, isErrorDownloadFTD } = useSelector(
    (state) => state.downloadZohoMisLead
  );

  // console.log("getting error in downloading lead mis data?", isErrorDownload);

  const handleAPIDownload = (type) => {
    dispatch(downloadZohoMisLead({ jwtBearer }));
  };
  const handleFTMAPIDownload = (type) => {
    dispatch(downloadZohoFTMLead({ jwtBearer }));
  };
  const handleFTDAPIDownload = (type) => {
    dispatch(downloadZohoFTDLead({ jwtBearer }));
  };

  const handleTabChange = (key) => {
    setActiveTabKey(key);
  }


  return (
    <>
      <center>
        <p
          style={{
            fontSize: "26px",
            textAlign: "center",
            marginBottom: "10px",
            color: "blue",
          }}
        >
          Reach out to IT support to get the password for this dashboard.
        </p>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Tabs
            defaultActiveKey="1"
            onChange={handleTabChange}
            style={{
              flex: 1,
              border: "1px solid #d9d9d9",
              borderRadius: "8px",
            }}
            tabBarExtraContent={
              (jwt_payload_role === "Zonal Head" || activeTabKey === "2") && (
                <div style={{ display: "flex" }}>
                  <StyledButton onClick={handleAPIDownload} loading={isDownloading} type="primary" style={{ marginRight: "1.5rem" }}>
                    Download Lead MIS
                  </StyledButton>
                  <StyledButton onClick={handleFTMAPIDownload} loading={isDownloadingFTM} type="primary" style={{ marginRight: "1.5rem" }}>
                    Download FTM Lead
                  </StyledButton>
                  <StyledButton onClick={handleFTDAPIDownload} loading={isDownloadingFTD} type="primary">
                    Download FTD Lead
                  </StyledButton>
                </div>
              )
            }
          >
            {jwt_payload_role !== "Zonal Head" ? (
              <TabPane
                tab={
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#4A4A4A",
                      fontSize: "16px",
                      padding: "10px 20px",
                      borderBottom: "2px solid transparent",
                      transition: "border-bottom 0.3s ease, color 0.3s ease",
                      cursor: "pointer",
                      display: "inline-block",
                    }}
                  >
                    Branch MIS
                  </span>
                }
                key="1"
              >
                <div className="zohoContainer">
                  <iframe
                    className="zoho-responsive-iframe"
                    src={
                      jwt_payload_role === "Supervisor"
                        ? `https://analytics.zoho.in/open-view/249291000004505001/a6dc8080260c2eeb1575a02b4af15ef2?ZOHO_CRITERIA=%22branch_info_master%22.%22supervisor_emp_code%22='${jwt_payload_user}'`
                        : "https://analytics.zoho.in/open-view/249291000004505001/a6dc8080260c2eeb1575a02b4af15ef2"
                    }
                    title="Dashboard"
                    style={{
                      width: "100%",
                      height: "600px",
                      border: "none",
                      borderRadius: "4px",
                    }}
                  />
                </div>
              </TabPane>
            ) : null}

            <TabPane
              tab={
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#4A4A4A",
                    fontSize: "16px",
                    padding: "10px 20px",
                    borderBottom: "2px solid transparent",
                    transition: "border-bottom 0.3s ease, color 0.3s ease",
                    cursor: "pointer",
                    display: "inline-block",

                  }}

                >
                  Lead MIS
                </span>
              }
              key="2"
            >
              <div className="zohoContainer">
                <iframe
                  className="zoho-responsive-iframe"
                  src={
                    jwt_payload_role === "Supervisor"
                      ? `https://analytics.zoho.in/open-view/249291000005340317/36f9736695ceca8ea256edac1585a377?ZOHO_CRITERIA=%22branch_info_master%22.%22supervisor_emp_code%22='${jwt_payload_user}'`
                      : jwt_payload_role === "Zonal Head"
                        ? `https://analytics.zoho.in/open-view/249291000005340317/36f9736695ceca8ea256edac1585a377?ZOHO_CRITERIA=%22branch_info_master%22.%22zonal_emp_code%22='${jwt_payload_user}'`
                        : "https://analytics.zoho.in/open-view/249291000005340317/36f9736695ceca8ea256edac1585a377"
                  }
                  title="Reports"
                  style={{
                    width: "100%",
                    height: "600px",
                    border: "none",
                    borderRadius: "4px",
                  }}
                />
              </div>
            </TabPane>
          </Tabs>
        </div>
      </center>
    </>
  );
};

export default ZohoMIS;
