import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from 'antd';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import BranchToHO from '../components/HeadOffice/branchToHO';
import HoToVendor from '../components/HeadOffice/hoToVendor';
import VendorToHo from '../components/HeadOffice/vendorToHo_Not_used';
import HoToBranch from '../components/HeadOffice/ReturningHoToBranch';
import DetailModalHO from '../components/HeadOffice/HoInTransitDrawer';
import { useAuth } from "../../../context/AuthContext";
import Checkbox from '@mui/material/Checkbox';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const HoPDD = (props) => {
    console.log('PDD Prop - ', props);
    const dispatch = useDispatch();
    const [modalVisible, setDetailModalVisible] = useState(false);
    const [selectedCardData, setSelectedCardData] = useState(null);
    const { authState } = useAuth();
    const jwtBearer = authState.token;

    // State for checkboxes
    const [checkboxState, setCheckboxState] = useState({
        branch: true,
        warehouse: false,
        sent: false,
        inQuery: false, // New state for In-Query checkbox
    });

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;

        // Logic to handle checkbox states
        if (name === 'branch') {
            setCheckboxState({
                branch: checked,
                warehouse: false,
                sent: false,
                inQuery: false, // Uncheck In-Query when Branch is selected
            });
        } else if (name === 'warehouse') {
            setCheckboxState({
                branch: false,
                warehouse: checked,
                sent: checkboxState.sent, // Keep sent state
                inQuery: false, // Uncheck In-Query when Warehouse is selected
            });
        } else if (name === 'sent') {
            setCheckboxState(prevState => ({
                ...prevState,
                sent: checked,
            }));
        } else if (name === 'inQuery') {
            setCheckboxState(prevState => ({
                ...prevState,
                inQuery: checked,
            }));
        }
    };

    const handleCloseModal = () => {
        setDetailModalVisible(false);
        setSelectedCardData(null);
    };

    const renderComponent = () => {
        const { branch, warehouse, sent, inQuery } = checkboxState;

        if (warehouse) {
            // return sent ? <VendorToHo jwtBearer={jwtBearer} extraProp={props.userDetails} /> : <HoToVendor extraProp={props.userDetails} />;
            return <HoToVendor extraProp={props.userDetails}  />;
        } else if (branch) {
            return sent ? <HoToBranch jwtBearer={jwtBearer} extraProp={props.userDetails} /> : <BranchToHO jwtBearer={jwtBearer} extraProp={props.userDetails} />;
        }
        return null;
    };

    return (
        <>
            <div style={{ display: "flex", margin: "0 40px" }}>
                <p style={{ fontSize: "20px" }}>Filters: </p>
                <Checkbox
                    size='large'
                    checkedIcon={<SquareRoundedIcon style={{ color: 'dodgerBlue' }} />}
                    name="branch"
                    checked={checkboxState.branch}
                    onChange={handleCheckboxChange}
                />
                <p style={{ fontSize: "20px", fontWeight: 700 }}>Branch</p>

                <Checkbox
                    size='large'
                    checkedIcon={<SquareRoundedIcon style={{ color: 'dodgerBlue' }} />}
                    name="warehouse"
                    checked={checkboxState.warehouse}
                    onChange={handleCheckboxChange}
                />
                <p style={{ fontSize: "20px", fontWeight: 700 }}>Warehouse</p>

                <Checkbox
                    size='large'
                    checkedIcon={<SquareRoundedIcon style={{ color: 'dodgerBlue' }} />}
                    name="sent"
                    checked={checkboxState.sent}
                    onChange={handleCheckboxChange}
                />
                <p style={{ fontSize: "20px", fontWeight: 700 }}>Sent</p>
            </div>

            



            {renderComponent()}

            <Modal
                visible={modalVisible}
                onCancel={handleCloseModal}
                footer={null}
                width={800}
            >
                <DetailModalHO
                    visible={modalVisible}
                    onClose={handleCloseModal}
                    data={selectedCardData}
                />
            </Modal>
        </>
    );
};

export default HoPDD;
