import React from 'react'
import { motion } from "framer-motion"
function EngineHeadLight({ data }) {

    const headLightGradient = (e) => {
        // console.log("Color func - ", e);
        switch (e) {
            // verification pending
            case "4":
                return "linear-gradient(to right, white,white)";
            // verified
            case "5":
                return "linear-gradient(to right, white,white)";
            // missing/damaged
            case "8":
                return "linear-gradient(to right, rgba(255, 0, 0, 0.8) , rgba(255, 0, 0, 0.1))";
            default:
                return "linear-gradient(to right, white,white)";
        }
    };

    return (
        <div>
            <div style={{ display: "flex" }}>
                <div
                    style={{
                        border: "solid 2px",
                        height: "20px",
                        width: "20px",
                        borderRadius: "0 10px 0 0",
                        borderLeft: "white",
                    }}
                ></div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "start",
                        gap: "0",
                        position: "relative",
                        top: "11px",
                        left: "-3px",
                    }}
                >
                    <div
                        style={{
                            width: "10px",
                            height: "5px",
                            background: "white",
                            transform:
                                "perspective(60px) rotateY(-70deg)",
                            // margin: "50px",
                            alignSelf: "start",
                            //   backgroundColor: "yellow",
                            border: "solid 2px black",
                        }}
                    ></div>
                    <motion.span
                        style={{
                            width: "60px",
                            height: "10px",
                            background: "white",
                            //   border: "solid 1px black",
                            transform:
                                "perspective(60px) rotateY(-70deg)",
                            // margin: "50px",
                            alignSelf: "self-start",
                            position: "relative",
                            left: "-27px",
                            top: "-2px",
                            background: headLightGradient(
                                data.verification
                            ),
                            //   "linear-gradient(to right, rgba(255, 0, 0, 0.8) , rgba(255, 0, 0, 0.1))",
                        }}
                        className="headLight"
                        transition={{
                            duration: 0.5,
                            repeat: Infinity,
                            repeatType: "reverse",
                        }}
                    ></motion.span>
                </div>
            </div>
        </div>
    )
}

export default EngineHeadLight
