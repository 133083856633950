import { motion } from "framer-motion";

export function RightTyre({ position }) {
    return (
      <motion.div
        style={{
          position: "absolute",
          ...position,
          top: "6px",
          left: "180px",
        }}
        animate={{ y: [0, -3, 0] }}
        transition={{
          duration: 0.8,
          repeat: Infinity,
          repeatType: "loop",
        }}
      >
        <svg
          width="28"
          height="28"
          viewBox="0 0 687 684"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="tire"
        >
          <circle cx="358.5" cy="357.5" r="267.5" fill="black" />
          <path
            d="M259.348 136.702C259.2 138.215 257.485 139.016 256.229 138.159L179.209 85.5385C177.93 84.6646 178.087 82.7309 179.491 82.0751L265.574 41.8574C266.978 41.2016 268.562 42.3218 268.411 43.8637L259.348 136.702Z"
            fill="white"
          />
          <path
            d="M182.797 183.801C183.302 185.236 182.085 186.687 180.585 186.439L88.5491 171.261C87.0205 171.009 86.3466 169.19 87.342 168.003L148.393 95.198C149.389 94.011 151.298 94.3576 151.812 95.8188L182.797 183.801Z"
            fill="white"
          />
          <path
            d="M135.634 250.422C136.62 251.579 136.006 253.37 134.517 253.679L43.1751 272.588C41.6581 272.902 40.3754 271.447 40.8777 269.981L71.6884 180.1C72.1907 178.635 74.0967 178.272 75.102 179.451L135.634 250.422Z"
            fill="white"
          />
          <path
            d="M114.266 343.431C115.544 344.255 115.471 346.147 114.134 346.871L32.0997 391.271C30.7373 392.008 29.0899 390.984 29.1492 389.436L32.7859 294.49C32.8452 292.942 34.5662 292.047 35.8682 292.886L114.266 343.431Z"
            fill="white"
          />
          <path
            d="M130.52 439.649C132.029 439.841 132.78 441.579 131.886 442.809L77.0388 518.259C76.1279 519.513 74.1996 519.299 73.5851 517.877L35.8986 430.656C35.2841 429.233 36.4501 427.683 37.9869 427.878L130.52 439.649Z"
            fill="white"
          />
          <path
            d="M180.457 517.425C181.908 516.97 183.315 518.237 183.015 519.728L164.609 611.173C164.303 612.692 162.461 613.301 161.31 612.265L90.6979 548.69C89.5466 547.654 89.9601 545.758 91.4385 545.295L180.457 517.425Z"
            fill="white"
          />
          <path
            d="M256.636 576.109C257.93 575.31 259.607 576.188 259.686 577.707L264.524 670.86C264.605 672.407 262.971 673.454 261.599 672.736L177.43 628.651C176.058 627.932 175.989 625.993 177.306 625.178L256.636 576.109Z"
            fill="white"
          />
          <path
            d="M358.602 600.121C359.381 598.815 361.275 598.822 362.044 600.134L409.253 680.585C410.037 681.921 409.07 683.603 407.521 683.597L312.506 683.244C310.957 683.238 310.002 681.549 310.796 680.219L358.602 600.121Z"
            fill="white"
          />
          <path
            d="M525.497 532.429C525.241 530.93 526.686 529.705 528.123 530.203L616.274 560.703C617.738 561.21 618.095 563.117 616.914 564.119L544.445 625.569C543.264 626.571 541.441 625.907 541.18 624.38L525.497 532.429Z"
            fill="white"
          />
          <path
            d="M449.696 585.589C449.891 584.081 451.631 583.334 452.859 584.231L528.18 639.256C529.431 640.169 529.213 642.097 527.789 642.708L440.48 680.189C439.056 680.8 437.508 679.631 437.707 678.094L449.696 585.589Z"
            fill="white"
          />
          <path
            d="M579.182 453.018C578.385 451.724 579.266 450.047 580.784 449.971L673.945 445.271C675.492 445.192 676.537 446.827 675.816 448.199L631.606 532.302C630.885 533.673 628.946 533.739 628.133 532.421L579.182 453.018Z"
            fill="white"
          />
          <path
            d="M601.587 361.688C600.302 360.875 600.357 358.983 601.688 358.247L683.324 313.118C684.68 312.369 686.336 313.379 686.29 314.927L683.499 409.901C683.454 411.45 681.741 412.361 680.432 411.533L601.587 361.688Z"
            fill="white"
          />
          <path
            d="M589.871 263.863C588.351 263.878 587.371 262.257 588.089 260.918L632.182 178.717C632.914 177.352 634.853 177.302 635.655 178.628L684.838 259.923C685.64 261.248 684.695 262.943 683.146 262.958L589.871 263.863Z"
            fill="white"
          />
          <path
            d="M352.137 112.285C351.368 113.597 349.475 113.606 348.694 112.301L300.823 32.2426C300.028 30.913 300.981 29.2233 302.53 29.2163L397.544 28.7845C399.093 28.7775 400.062 30.4585 399.279 31.7953L352.137 112.285Z"
            fill="white"
          />
          <path
            d="M453.884 132.091C452.704 133.05 450.928 132.394 450.655 130.899L433.887 39.1388C433.608 37.6148 435.093 36.3665 436.547 36.9031L525.682 69.8076C527.136 70.3441 527.453 72.258 526.251 73.2355L453.884 132.091Z"
            fill="white"
          />
          <path
            d="M535.143 188.422C533.655 188.737 532.375 187.342 532.815 185.886L559.823 96.6027C560.272 95.1198 562.163 94.688 563.211 95.8292L627.466 165.823C628.513 166.964 627.922 168.812 626.406 169.132L535.143 188.422Z"
            fill="white"
          />
          <path
            d="M562 360C562 470.457 472.457 560 362 560C251.543 560 162 470.457 162 360C162 249.543 251.543 160 362 160C472.457 160 562 249.543 562 360Z"
            fill="white"
          />
          <path
            d="M437.593 398.55C433.4 391.907 438.152 383.245 446.008 383.213L537.619 382.837C545.215 382.806 550.071 390.923 546.452 397.602L504.023 475.898C500.381 482.618 490.854 482.935 486.774 476.471L437.593 398.55Z"
            fill="black"
          />
          <path
            d="M363.561 453.519C366.817 446.369 376.666 445.592 381.002 452.143L431.561 528.54C435.753 534.875 431.63 543.387 424.06 544.023L335.319 551.489C327.703 552.129 322.213 544.336 325.38 537.38L363.561 453.519Z"
            fill="black"
          />
          <path
            d="M283.307 409.771C290.958 407.987 297.584 415.315 295.041 422.748L265.377 509.424C262.917 516.611 253.658 518.539 248.534 512.931L188.462 447.189C183.307 441.547 186.129 432.441 193.573 430.705L283.307 409.771Z"
            fill="black"
          />
          <path
            d="M278.455 315.11C283.552 321.088 280.082 330.339 272.311 331.49L181.689 344.917C174.175 346.03 168.212 338.689 170.842 331.562L201.68 248.018C204.327 240.848 213.712 239.177 218.671 244.993L278.455 315.11Z"
            fill="black"
          />
          <path
            d="M382.883 166.875C390.623 165.531 396.819 173.226 393.855 180.501L359.282 265.339C356.415 272.374 347.061 273.769 342.266 267.877L286.053 198.807C281.228 192.879 284.567 183.95 292.098 182.642L382.883 166.875Z"
            fill="black"
          />
          <path
            d="M433.944 311.838C426.311 313.697 419.614 306.434 422.085 298.977L450.903 212.016C453.292 204.805 462.532 202.786 467.711 208.344L528.42 273.498C533.631 279.09 530.897 288.222 523.471 290.031L433.944 311.838Z"
            fill="black"
          />
          <path
            d="M467 361.5C467 423.632 417.751 474 357 474C296.249 474 247 423.632 247 361.5C247 299.368 296.249 249 357 249C417.751 249 467 299.368 467 361.5Z"
            fill="white"
          />
          <path
            d="M419 358.5C419 391.361 392.361 418 359.5 418C326.639 418 300 391.361 300 358.5C300 325.639 326.639 299 359.5 299C392.361 299 419 325.639 419 358.5Z"
            fill="black"
          />
          <circle cx="356.5" cy="283.5" r="9.5" fill="black" />
          <circle cx="418.5" cy="318.5" r="9.5" fill="black" />
          <circle cx="429.5" cy="385.5" r="9.5" fill="black" />
          <circle cx="374.5" cy="433.5" r="9.5" fill="black" />
          <circle cx="300.5" cy="409.5" r="9.5" fill="black" />
          <circle cx="284.5" cy="328.5" r="9.5" fill="black" />
        </svg>
      </motion.div>
    );
  }