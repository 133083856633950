import React from "react";
import { Drawer, Table, Typography, Divider,Space } from "antd";
import { StyledTable } from "../../style/styleComp";
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";


const { Title, Text } = Typography;

const ReceivedModal = ({ visible, onClose, data }) => {
  console.log("returning reveived data", data);
  // Define columns for the table

  if (data == null) {
    return
  }

  const columns = [
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
    },
    {
      title: "Application Name",
      dataIndex: "app_name",
      key: "app_name",
    },
    {
      title: "Tracking Number",
      dataIndex: "trk_no",
      key: "trk_no",
    },
    {
      title: "Partner Loan ID",
      dataIndex: "partner_loan_id",
      key: "partner_loan_id",
    },
    {
      title: "Verification Status",
      dataIndex: "verification",
      key: "verification",
    },
    {
      title: "Physical Carton No",
      dataIndex: "phy_carton_no",
      key: "phy_carton_no",
    },
    {
      title: "Physical Box No",
      dataIndex: "phy_box_no",
      key: "phy_box_no",
    },
  ];

  // Function to render expanded row with `doc_details`
  const expandedRowRender = (record) => {
    const { doc_details } = record;

    if (!doc_details) return null;

    const renderDocSection = (title, docs) => (
      <div style={{ marginBottom: "16px", textAlign: "left" }}>
        <Title level={5} style={{ marginBottom: "8px" }}>
          {title}
        </Title>
        {docs.length > 0 ? (
          <ul style={{ paddingLeft: "20px", margin: 0 }}>
            {docs.map((doc, index) => (
              <li key={index} style={{ textAlign: "left" }}>
                <Text>{doc}</Text>
              </li>
            ))}
          </ul>
        ) : (
          <Text type="secondary">None</Text>
        )}
        <Divider />
      </div>
    );



    return (
      <div style={{ padding: "16px" }}>
        {renderDocSection("Missing Documents", doc_details.missing_documents)}
        {renderDocSection(
          "Not Applicable Documents",
          doc_details.not_applicable_docs
        )}
        {renderDocSection("Damaged Documents", doc_details.damaged_docs)}
        {renderDocSection("Verified Documents", doc_details.verified_docs)}
      </div>
    );
  };

  const getStatusText = (statusCode) => {
    switch (statusCode) {
        case "1":
            return "In-Transit";
        case "2":
            return "In-Query";
        case "3":
            return "Received";
        default:
            return "";
    }
};
const getVerificationText = (statusCode) => {
  switch (statusCode) {
      case "4":
          return "Pending at HO";
      case "5":
          return "Verified";
      case "8":
          return "Missing/Damaged Documents";
      default:
          return "";
  }
};

const handleClose = () => {
  onClose();
};


  return (
    <Drawer
      visible={visible}
      onClose={handleClose}
      footer={null}
      width={900}
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          <InfoCircleOutlined
            style={{
              marginRight: "8px",
              color: "#1890ff",
              fontSize: "28px",
            }}
          />
          <Title
            level={4}
            style={{
              marginBottom: "16px",
              color: "rgba(0, 0, 0, 0.7)",
              fontSize: "20px",
              fontFamily: "Poppins",
              marginLeft: "5px",
            }}
          >
            Courier details
          </Title>
        </div>
      }
    >
      <div style={{ padding: "0 20px" }}>
        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "0 8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
            height: "50px",
          }}
        >
          <strong style={{ color: "#555" }}>Status :</strong>

          <Text
            style={{
              fontSize: "20px",
              fontFamily: "Poppins",
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "green",
              padding: "0px 8px",
              color: "white",
              borderRadius: "6px",
            }}
          >
            {getStatusText(data?.status)}
          </Text>
        </Space>

        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>POD Number :</strong>
          <Text
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              display: "flex",
              justifyContent: "space-between",
              fontWeight: "800",
            }}
          >
            {data?.tracking_number}
          </Text>
        </Space>

        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>Verification:</strong>
          <Text
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              display: "flex",
              justifyContent: "space-between",
              backgroundColor:
                data.verification === "5"
                  ? "green"
                  : data.verification === "4"
                  ? "orange"
                  : data.verification === "8"
                  ? "red"
                  : "",

              padding: "3px 8px",
              color: "white",
              borderRadius: "6px",
            }}
          >
            {getVerificationText(data?.verification)}
          </Text>
        </Space>

        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>Branch :</strong>
          <Text
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {data?.branch}
          </Text>
        </Space>
      </div>

      <StyledTable
        columns={columns}
        dataSource={data}
        rowKey="app_id"
        expandable={{ expandedRowRender }}
        pagination={false}
      />
    </Drawer>
  );
};

export default ReceivedModal;
