import BranchPDD from '../views/branchPDD';
import HoPDD from '../views/hoPDD';
import jwt_decode from 'jwt-decode';
import { useAuth } from '../../../context/AuthContext';
import React from 'react';

function PDDModule(props) {
  const { authState } = useAuth();

  var jwtBearer = authState.token;
  var jwt_payload = '';
  var jwt_payload_group = '';
  var user_id = '';

  if (jwtBearer) {
    jwt_payload = jwt_decode(jwtBearer);
    user_id = jwt_payload.user_id;
    // jwt_payload_group = jwt_payload.group_id;
    jwt_payload_group = props.userDetails?.group_id;
  }

  // Conditionally render the appropriate component based on jwt_payload_group
  return (
    <>
      {jwt_payload_group === '10' && <HoPDD userDetails={props.userDetails} />}
      {jwt_payload_group === '3' && <BranchPDD userDetails={props.userDetails} />}
      {/* Optionally add a fallback if neither condition matches */}
      {jwt_payload_group !== '10' && jwt_payload_group !== '3' && (
        <div>No matching component for this group.</div>
      )}
    </>
  );
}

export default PDDModule;
