import React, { useState, useEffect, useMemo } from 'react';
import { Drawer, Typography, List, Empty, Row, Col, Card } from 'antd';
import { useDispatch } from 'react-redux';
import { fetchPDDMissingDocs } from '../../../../redux/slice/getPDDMissingDocs';
import { useAuth } from '../../../../context/AuthContext';
import { CheckCircleFilled, CheckCircleOutlined, ExclamationCircleOutlined, ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { StyledTable } from "../../style/styleComp";
const { Title } = Typography;

const BranchMissDamDrawer = ({ visible, onClose, data, statusDetails }) => {
    if (!data) {
        return null;
    }

    const dispatch = useDispatch();
    const { authState } = useAuth();
    const [loading, setLoading] = useState(false);
    const [missingPDDDocs, setMissingPDDDocs] = useState({});
    const jwtBearer = authState.token;

    const fetchData = async () => {
        setLoading(true);
        try {
            const requestBody = {
                app_ids: data.application_ids,
            };
            const missingResponse = await dispatch(fetchPDDMissingDocs({ jwtBearer, requestBody }));
            setMissingPDDDocs(missingResponse.payload?.data || {});
        } catch (error) {
            console.error("Error fetching missing docs:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [dispatch]);

    const getStatusText = (statusCode) => {
        switch (statusCode) {
          case "1":
            return "In-Transit";
          case "2":
            return "In-Query";
          case "3":
            return "Received";
          default:
            return "";
        }
    };

    const verificationColumns = [
        {
            title: 'Verification',
            key: 'verificationStatus',
            render: (text, record) => {
                const status = statusDetails.find(item => item.app_id === record.application_id);
                const myVerification = status?.verification;

                if (myVerification === "5") {
                    return <CheckCircleFilled style={{ color: "green", fontSize: "28px" }} />;
                } else if (myVerification === "4") {
                    return <CheckCircleOutlined style={{ color: "grey", fontSize: "28px" }} />;
                } else if (myVerification === "8") {
                    return <ExclamationCircleOutlined style={{ color: "red", fontSize: "28px" }} />;
                } else {
                    return <ExclamationCircleOutlined style={{ color: "orange", fontSize: "28px" }} />;
                }
            },
        }
    ];

    const myColumns = [

        {
            title: 'Partner Loan ID',
            dataIndex: 'partner_loan_id',
            key: 'partner_loan_id',
        },
        {
            title: 'Vendor',
            dataIndex: 'vendor',
            key: 'vendor',
        },
        {
            title: 'Branch',
            dataIndex: 'branch',
            key: 'branch',
        },
        // {
        //     title: 'Created At',
        //     dataIndex: 'created_at',
        //     key: 'created_at',
        // },
        {
            title: 'Name',
            dataIndex: 'applicant_name',
            key: 'applicant_name',
        },
        // {
        //     title: 'Status',
        //     dataIndex: 'status',
        //     key: 'status',
        // }
    ];

    const columns = [
        ...verificationColumns,
        ...myColumns,
    ];

    // Prepare data source dynamically
    const dataSource = useMemo(() => {
        return data.application_ids.map((appId) => ({
            key: appId,
            application_id: appId, 
            // tracking_number: data.tracking_number,
            status: getStatusText(data.status),
            vendor: data.vendor,
            branch: data.branch,
            // created_at: data.created_at,
            applicant_name: data.applicant_details[appId]?.applicant_name,
            partner_loan_id: data.applicant_details[appId]?.partner_loan_id,
        }));
    }, [data]);

    const renderExpandedRow = (record) => {
        const docData = missingPDDDocs[record.application_id] || {};
        const sections = [
            { title: 'Missing Documents', items: docData.missing_documents || [], bgColor: 'red' },
            { title: 'Damaged Documents', items: docData.damaged_docs || [], bgColor: 'red' },
            { title: 'Not Applicable Documents', items: docData.not_applicable_docs || [], bgColor: 'green' },
            { title: 'Verified Documents', items: docData.verified_docs || [], bgColor: 'green' },
        ];

        return (
            <Row gutter={16}>
                {sections.map((section) => (
                    <Col span={6} key={section.title}>
                        <Card
                            title={
                                <span
                                    style={{
                                        backgroundColor: section.bgColor, 
                                        color: 'white', 
                                        padding: '5px 10px',
                                        borderRadius: '5px', 
                                        display: 'block', 
                                        position: 'sticky', 
                                        top: 0, 
                                        zIndex: 1, 
                                    }}
                                >
                                    {section.title}
                                </span>
                            }
                            bordered={false}
                            style={{
                                height: '400px',
                                overflowY: 'auto',
                                paddingTop: '30px',
                            }}
                        >
                            {section.items.length === 0 ? (
                                <Empty description="No data available" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            ) : (
                                <List
                                    size="small"
                                    dataSource={section.items}
                                    renderItem={(item) => <List.Item>{item}</List.Item>}
                                />
                            )}
                        </Card>
                    </Col>
                ))}
            </Row>
        );
    };

    return (
        // <Drawer
        //     visible={visible}
        //     onClose={onClose}
        //     footer={null}
        //     width={1500}
        //     title={
        //         <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        //             <Title
        //                 level={4}
        //                 style={{
        //                     marginBottom: "18px",
        //                     color: "rgba(0, 0, 0, 0.7)",
        //                     fontSize: "20px",
        //                     fontFamily: "Poppins",
        //                     marginLeft: "5px",
        //                     marginBottom: "25px"
        //                 }}
        //             >
        //                 Tracking Number : {data.tracking_number}
        //             </Title>
        //         </div>
        //     }
        // >
        //     <StyledTable
        //         columns={columns}
        //         dataSource={dataSource}
        //         loading={loading}
        //         expandable={{
        //             expandedRowRender: renderExpandedRow,
        //             rowExpandable: (record) => !!missingPDDDocs[record.application_id], // Check if docs exist for the application_id
        //             expandIcon: ({ expanded, onExpand, record }) => (
        //                 <div
        //                     onClick={(e) => onExpand(record, e)}
        //                     style={{
        //                         color: expanded ? '#1890ff' : '#8c8c8c',
        //                         cursor: 'pointer',
        //                         display: 'flex',
        //                         alignItems: 'center',
        //                         fontSize: '16px',
        //                         fontWeight: 'bold',
        //                         transition: 'color 0.3s ease',
        //                     }}
        //                 >
        //                     {expanded ? (
        //                         <ArrowUpOutlined style={{ marginRight: 8 }} />
        //                     ) : (
        //                         <ArrowDownOutlined style={{ marginRight: 8 }} />
        //                     )}
        //                     {expanded ? 'Collapse' : 'Expand'}
        //                 </div>
        //             ),
        //         }}
        //         pagination={false}
        //     />
        // </Drawer>
        <Drawer
            visible={visible}
            onClose={onClose}
            footer={null}
            width={1500}
            title={
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Title
                        level={4}
                        style={{
                            marginBottom: "18px",
                            color: "rgba(0, 0, 0, 0.7)",
                            fontSize: "20px",
                            fontFamily: "Poppins",
                            marginLeft: "5px",
                            marginBottom: "25px"
                        }}
                    >
                        Tracking Number : {data.tracking_number}
                    </Title>
                </div>
            }
        >
            <StyledTable
                columns={columns}
                dataSource={dataSource}
                loading={loading}
                expandable={{
                    expandedRowRender: renderExpandedRow,
                    rowExpandable: (record) => !!missingPDDDocs[record.application_id], // Check if docs exist for the application_id
                    expandIcon: ({ expanded, onExpand, record }) => (
                        <div
                            onClick={(e) => onExpand(record, e)}
                            style={{
                                color: expanded ? '#1890ff' : '#8c8c8c',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                transition: 'color 0.3s ease',
                            }}
                        >
                            {expanded ? (
                                <ArrowUpOutlined style={{ marginRight: 8 }} />
                            ) : (
                                <ArrowDownOutlined style={{ marginRight: 8 }} />
                            )}
                            {expanded ? 'Collapse' : 'Expand'}
                        </div>
                    ),
                }}
                pagination={false}
            />
        </Drawer>

        // new drawer
    );
};

export default BranchMissDamDrawer;
