import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Modal,
  Typography,
  List,
  Checkbox,
  Button,
  Popconfirm,
  Drawer,
  Space
} from "antd";
import { debounce } from "lodash";
import { fetchPDDMaster } from "../../../../redux/slice/getPDDMaster";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../../context/AuthContext";
import { MyStyledTable, ReceivedStyledTable } from "../../style/styleComp";
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
const { Title,Text } = Typography;

const BranchReceivedDrawer = ({
  visible,
  onClose,
  data,
  all_app_id,
  statusDetails,
}) => {
  console.log("In detail modal app  ", data, statusDetails);
  if (!data || !all_app_id) {
    return null;
  }

  const dispatch = useDispatch();
  const { authState } = useAuth();

  const [loading, setLoading] = useState(true);
  var jwtBearer = authState.token;

  // Fetch master data on component mount
  const fetchData = () => {
    console.log("fetching data detail modal");
    setLoading(true);
    dispatch(fetchPDDMaster({ jwtBearer }))
      .then(() => setLoading(false))
      .catch((error) => {
        console.error("Error fetching PDD master data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 1000 * 60 * 5); // 5 minutes interval
    return () => clearInterval(intervalId);
  }, [dispatch]);

  const masterData = useSelector(
    (state) => state.fetchPDDMaster?.data?.message || []
  );
  console.log("Master data - ", masterData);

  // Memoized checklists based on masterData
  const checklistPLUSEC = useMemo(() => {
    const usecData = masterData.find((item) => item.field === "usec_docs");
    return usecData ? usecData.value : [];
  }, [masterData]);

  const checklistPLSEC = useMemo(() => {
    const secData = masterData.find((item) => item.field === "sec_docs");
    return secData ? secData.value : [];
  }, [masterData]);

  const matchedAppIDs = useMemo(() => {
    return all_app_id.filter((app) =>
      data.application_ids.includes(app.APP_ID)
    );
  }, [all_app_id, data.application_ids]);

  console.log("Matched App ID - ", matchedAppIDs);

  const [checkedItems, setCheckedItems] = useState({});
  const [missingDocs, setMissingDocs] = useState({});
  const [allCheckedState, setAllChecked] = useState(false);

  useEffect(() => {
    const initialCheckedItems = {};
    const initialMissingDocs = {};

    matchedAppIDs.forEach((app) => {
      const checklist =
        app.PRODUCT === "PL-USEC" ? checklistPLUSEC : checklistPLSEC;
      initialCheckedItems[app.APP_ID] = {};

      checklist.forEach((item) => {
        initialCheckedItems[app.APP_ID][item] = true;
      });

      const missingDocsForApp =
        data.missing_docs && Array.isArray(data.missing_docs)
          ? data.missing_docs.find((doc) => doc.application_id === app.APP_ID)
          : null;

      if (missingDocsForApp) {
        initialMissingDocs[app.APP_ID] = missingDocsForApp.missing_documents;
        missingDocsForApp.missing_documents.forEach((missingItem) => {
          initialCheckedItems[app.APP_ID][missingItem] = false; // Uncheck missing docs
        });
      }
    });

    setCheckedItems((prev) => ({ ...prev, ...initialCheckedItems }));
    setMissingDocs((prev) => ({ ...prev, ...initialMissingDocs }));
  }, [data.missing_docs, matchedAppIDs, checklistPLUSEC, checklistPLSEC]);

  const getChecklist = useCallback(
    (product) => {
      return product === "PL-USEC" ? checklistPLUSEC : checklistPLSEC;
    },
    [checklistPLUSEC, checklistPLSEC]
  );

  const handleCheckboxChange = useCallback(
    debounce((appId, item, checked) => {
      setCheckedItems((prevState) => {
        const updatedState = {
          ...prevState,
          [appId]: {
            ...prevState[appId],
            [item]: checked,
          },
        };

        setMissingDocs((prevMissingDocs) => {
          const updatedMissingDocs = { ...prevMissingDocs };

          if (!checked) {
            if (!updatedMissingDocs[appId]) {
              updatedMissingDocs[appId] = [];
            }

            if (!updatedMissingDocs[appId].includes(item)) {
              updatedMissingDocs[appId] = [...updatedMissingDocs[appId], item];
            }
          } else {
            if (updatedMissingDocs[appId]) {
              updatedMissingDocs[appId] = updatedMissingDocs[appId].filter(
                (doc) => doc !== item
              );
              if (updatedMissingDocs[appId].length === 0) {
                delete updatedMissingDocs[appId];
              }
            }
          }

          return updatedMissingDocs;
        });

        return updatedState;
      });
    }, 300),
    []
  );

  useEffect(() => {
    const allChecked = matchedAppIDs.every((app) => {
      const checklist = getChecklist(app.PRODUCT);
      return checklist.every(
        (item) => checkedItems[app.APP_ID]?.[item] !== false
      );
    });

    setAllChecked(allChecked);
  }, [checkedItems, matchedAppIDs, getChecklist]);

  const handleSubmitMissingDocs = () => {
    const missingDocsJSON = Object.keys(missingDocs).map((appId) => ({
      application_id: appId,
      missing_documents: missingDocs[appId],
    }));

    const callbackData = {
      tracking_number: data.tracking_number,
      branch: data.branch,
      docs: missingDocsJSON,
    };

    if (onMissingDocs) {
      onMissingDocs(callbackData);
    }
  };

  // Define the custom "Verification Status" column
  const verificationColumn = {
    title: "VERIFIED",
    key: "verificationStatus",
    render: (text, record) => {
      const status = statusDetails.find(
        (item) => item.app_id === record.APP_ID
      );
      const verification = status?.verification;

      if (verification === "5") {
        return (
          <CheckCircleFilled style={{ color: "green", fontSize: "28px" }} />
        );
      } else if (verification === "4") {
        return (
          <CheckCircleOutlined style={{ color: "grey", fontSize: "28px" }} />
        );
      } else if (verification === "8") {
        return (
          <ExclamationCircleOutlined
            style={{ color: "red", fontSize: "28px" }}
          />
        );
      } else {
        return (
          <ExclamationCircleOutlined
            style={{ color: "orange", fontSize: "28px" }}
          />
        );
      }
    },
  };

  // const columns = Object.keys(matchedAppIDs[0] || {}).map((key) => ({
  //     title: key.replace(/_/g, ' ').toUpperCase(),
  //     dataIndex: key,
  //     key: key,
  // }));

  const columns = [
    {
      title: "Application ID",
      dataIndex: "APP_ID",
      key: "APP_ID",
    },
    { title: "BRANCH", dataIndex: "BR_CD", key: "BR_CD" },
    {
      title: "NAME",
      dataIndex: "APPLICANT_NAME",
      key: "APPLICANT_NAME",
    },
    {
      title: "PARTNER LOAN ID",
      dataIndex: "PARTNER_LOAN_ID",
      key: "PARTNER_LOAN_ID",
    },
    { title: "PRODUCT", dataIndex: "PRODUCT", key: "PRODUCT" },
  ];

  const dataSource = matchedAppIDs.map((app, index) => ({
    ...app,
    key: index + 1,
  }));

  const getStatusText = (statusCode) => {
    switch (statusCode) {
        case "1":
            return "In-Transit";
        case "2":
            return "In-Query";
        case "3":
            return "Received";
        default:
            return "";
    }
};
const getVerificationText = (statusCode) => {
  switch (statusCode) {
      case "4":
          return "Pending at HO";
      case "5":
          return "Verified";
      case "8":
          return "Missing/Damaged Documents";
      default:
          return "";
  }
};


  columns.unshift(verificationColumn);
  return (
    <Drawer
      visible={visible}
      onClose={onClose}
      footer={null}
      width={900}
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          <InfoCircleOutlined
            style={{
              marginRight: "8px",
              color: "#1890ff",
              fontSize: "28px",
            }}
          />
          <Title
            level={4}
            style={{
              marginBottom: "16px",
              color: "rgba(0, 0, 0, 0.7)",
              fontSize: "20px",
              fontFamily: "Poppins",
              marginLeft: "5px",
            }}
          >
            Courier details
          </Title>
        </div>
      }
    >

<div style={{ padding: "0 20px" }}>

<Space
                    style={{
                        display: "flex",
                        marginBottom: "8px",
                        padding: "0 8px",
                        borderRadius: "4px",
                        backgroundColor: "#ffffff",
                        boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                        fontSize: "20px",
                        fontFamily: "Poppins",
                        display: "flex",
                        justifyContent: "space-between",
                        height: "50px"
                    }}

                >
                    <strong style={{ color: "#555" }}>Status :</strong>

                    <Text
                        style={{
                            fontSize: "20px",
                            fontFamily: "Poppins",
                            display: "flex",
                            justifyContent: "space-between",
                            backgroundColor: "green",
                            padding: "0px 8px",
                            color: "white",
                            borderRadius: "6px",
                        }}
                    >
                        {getStatusText(data.status)}
                    </Text>

                </Space>

                <Space
                    style={{
                        display: "flex",
                        marginBottom: "8px",
                        padding: "8px",
                        borderRadius: "4px",
                        backgroundColor: "#ffffff",
                        boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                        fontSize: "20px",
                        fontFamily: "Poppins",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <strong style={{ color: "#555" }}>POD Number :</strong>
                    <Text
                        style={{
                            fontSize: "18px",
                            fontFamily: "Poppins",
                            display: "flex",
                            justifyContent: "space-between",
                            fontWeight: "800",
                        }}
                    >
                        {data.tracking_number}
                    </Text>
                </Space>

                <Space
                    style={{
                        display: "flex",
                        marginBottom: "8px",
                        padding: "8px",
                        borderRadius: "4px",
                        backgroundColor: "#ffffff",
                        boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                        fontSize: "20px",
                        fontFamily: "Poppins",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <strong style={{ color: "#555" }}>Verification:</strong>
                    <Text
                        style={{
                            fontSize: "18px",
                            fontFamily: "Poppins",
                            display: "flex",
                            justifyContent: "space-between",
                            // backgroundColor: { data.verification === "5" ? "green" ? data.verification === "4" ? "orange" ? data.verification === "8" ? "red" : "red" },
                            backgroundColor: data?.verification === "5"
                                ? "green"
                                : data?.verification === "4"
                                    ? "orange"
                                    : data?.verification === "8"
                                        ? "red"
                                        : "",

                            padding: "3px 8px",
                            color: "white",
                            borderRadius: "6px",
                        }}
                    >
                        {getVerificationText(data?.verification)}
                    </Text>
                </Space>

                <Space
                    style={{
                        display: "flex",
                        marginBottom: "8px",
                        padding: "8px",
                        borderRadius: "4px",
                        backgroundColor: "#ffffff",
                        boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                        fontSize: "20px",
                        fontFamily: "Poppins",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <strong style={{ color: "#555" }}>Branch :</strong>
                    <Text
                        style={{
                            fontSize: "18px",
                            fontFamily: "Poppins",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        {data.branch}
                    </Text>
                </Space>
                </div>

      <MyStyledTable
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        style={{marginTop:"30px"}}
      />
    </Drawer>
  );
};

export default BranchReceivedDrawer;
