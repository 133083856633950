import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import truckAnimation from "../../lotties/loaderTruck.json";
import moment from 'moment'
import Lottie from "lottie-react";
import {
  Typography,
  Tabs,
  Button,
  Input,
  Tag,
  Card,
  Form,
  Space,
  Divider,
  Progress,
  Row,
  Col,
  Radio,
  Select,
  Steps,
  Spin,
  FloatButton,
  Slider,
  InputNumber,
  Collapse,
  Drawer,
  Alert,
} from "antd";
import styled from "styled-components";
import {
  SearchOutlined,
  PlusOutlined,
  DeleteOutlined,
  CodeSandboxOutlined,
  DropboxOutlined,
} from "@ant-design/icons";
import jwt_decode from "jwt-decode";
import {
  StyledModal,
  StyledCard,
  StyledRow,
  StyledCol,
  StyledTable,
  RefreshButton,
  StyledButton,
  CardContainer,
  MyStyledTable
} from "../../style/styleComp";
import { useAuth } from "../../../../context/AuthContext";
import { fetchPDDHoToBranch } from "../../../../redux/slice/getPDDHoToBranchData";
import { fetchPDDAppID } from "../../../../redux/slice/getPDDAppID";
import { fetchPDDAppIDRecalled } from "../../../../redux/slice/getPDDAppIDRecalled";
import { HashLoader } from "react-spinners";
import { addHoToBranchEntry } from "../../../../redux/slice/pddAddHoToBranchEntry ";
import { updateHoToBranchEntry } from "../../../../redux/slice/pddUpdateHoToBranchEntry";
import { fetchBranch } from "../../../../redux/slice/getBranchData";
import { addMissingDocs } from "../../../redux/slice/pddAddMissingDocs";
import DetailModal from "../HeadOffice/ReturningInTransitDrawer";
import ReceivedModal from "../HeadOffice/ReturningReceivedDrawer";
import { RiRefreshLine } from "react-icons/ri";
import { useSpring, animated } from "react-spring";
import "../../style/customStyles.css";
import { PiSquaresFour } from "react-icons/pi";
import { IoListOutline } from "react-icons/io5";
import { LeftTyre } from "../../style/truck/LeftTyre";
import { RightTyre } from "../../style/truck/RightTyre";
import { Circle } from "../../style/truck/Circle";

const { Title, Text } = Typography;

const { TabPane } = Tabs;

const { Panel } = Collapse;

const { Option } = Select;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const HoToBranch = ({ extraProp }) => {
  const [receivedModalVisible, setReceivedModalVisible] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [activeTabKey, setActiveTabKey] = useState("allTables");
  const [tabList, setTabList] = useState([
    { key: "allTables", title: "Branch PDD" },
  ]);
  const [openBox, setOpenBox] = useState(true);
  const [showAddBranchEntryModalVisible, setShowAddBranchEntryModalVisible] =
    useState(false);
  const [selectedAppIDs, setSelectedAppIDs] = useState([]);
  const [searchField, setSearchField] = useState("Select");
  const [searchValue, setSearchValue] = useState("");
  const [enableSearch, setEnableSearch] = useState(true);
  const [modalVisible, setDetailModalVisible] = useState(false);
  const [queryModalVisible, setQueryModalVisible] = useState(false);
  const [selectedCardData, setSelectedCardData] = useState([]);
  const [isAnimated, setIsAnimated] = useState(false);
  const [filteredAppIDData, setFilteredAppIDData] = useState([]);

  const [selectedBranch, setSelectedBranch] = useState("");

  const [selectedButton, setSelectedButton] = useState("truckIcon");

  const [viewTable, setViewTable] = useState(false);

  const { authState } = useAuth();

  var token_decoded = authState.token;
  var jwtBearer = authState.token;
  var jwt_payload = "";
  var user_branch = "";
  if (jwtBearer) {
    jwt_payload = jwt_decode(jwtBearer);
    user_branch = extraProp.branch_id;
  }

  const [branches, setBranches] = useState([]);

  useEffect(() => {
    // Parse the user_branch string into an array of branch IDs
    const parsedBranches = user_branch.replace(/[()]/g, "").split(",");
    setBranches(parsedBranches);
  }, [user_branch]);

  const fetchData = () => {
    console.log("Fetching chat data and app ID...");
    setLoading(true);

    // Dispatch both actions in parallel
    Promise.all([
      dispatch(fetchPDDHoToBranch({ jwtBearer })),
      dispatch(fetchPDDAppIDRecalled({ jwtBearer })),
      dispatch(fetchBranch({ jwtBearer })),
    ])
      .then(() => {
        console.log("Chat data and app ID fetched successfully.");
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching chat data or app ID:", error);
        setLoading(false);
      });
  };

  // Initial fetch on component mount
  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 1000 * 60 * 5); // 5 minutes interval

    return () => {
      clearInterval(intervalId);
      console.log("Interval cleared.");
    };
  }, [dispatch]); // Fetch data initially and set up interval

  // Handle refresh button click
  const handleRefresh = () => {
    setIsAnimated(true);
    setLoading(true);
    console.log("refresing");
    setTimeout(() => {
      setLoading(false);
      setIsAnimated(false);
    }, 1000);
    fetchData();
  };

  const addBranchNewEntry = () => {
    setShowAddBranchEntryModalVisible(true);
  };

  var chatData = useSelector(
    (state) => state.fetchPDDHoToBranch?.data?.message || []
  );
  const appIDData = useSelector(
    (state) => state.fetchPDDAppIDRecalled?.data?.message || []
  );
  const allBranches = useSelector(
    (state) => state.fetchBranch?.data?.message.btrim || []
  );

  console.log("All Branches - ", appIDData);

  // Convert jwt_branch string to an array of branch codes
  const branchArray = user_branch.replace(/[()]/g, "").split(",");

  // Filter appIDData to include only entries where BR_CD matches jwt_branch
  // const cleanAppIDData = appIDData.filter((app) =>
  //     branchArray.includes(app.BR_CD.split(" ")[0])
  // );

  const cleanAppIDData = appIDData;
  console.log("App ID data - ", appIDData);

  console.log("chat data - ", chatData);

  // const handleRowClick = async (record) => {
  //     console.log("Selected record - ", record);
  // };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearchFieldChange = (value) => {
    setSearchField(value);
    if (value) {
      setEnableSearch(false);
    }
  };

  // Convert jwt_group string to an array of branch IDs
  const jwtGroupArray = user_branch.slice(1, -1).split(",");

  // chatData = chatData.reverse();

  const handleCloseModal = () => {
    setShowAddBranchEntryModalVisible(false);
    setDetailModalVisible(false);
    setQueryModalVisible(false);
    setSelectedCardData(null);
  };

  const onFinish = (values) => {
    console.log(values, selectedAppIDs);
    const requestBody = {
      tracking_no: values.trackingNumber,
      application_id: selectedAppIDs,
    };
    dispatch(addHoToBranchEntry({ jwtBearer, requestBody }))
      .then(() => {
        // Dispatch fetchChatData after updateRescheduleDate is successful
        dispatch(fetchPDDHoToBranch({ jwtBearer }));
        form.resetFields();
        setSelectedAppIDs([]);
        setShowAddBranchEntryModalVisible(false);
      })
      .catch((error) => {
        console.error("Error updating reschedule date:", error);
      });
  };

  const onReset = () => {
    form.resetFields();
  };
  const onFill = () => {
    form.setFieldsValue({
      note: "Hello world!",
      gender: "male",
    });
  };

  const handleSelectChange = (value) => {
    if (!selectedAppIDs.includes(value)) {
      setSelectedAppIDs([...selectedAppIDs, value]);
    }
  };

  const handleRemoveAppID = (appID) => {
    setSelectedAppIDs(selectedAppIDs.filter((id) => id !== appID));
  };

  const handleSaveModal = (updatedData) => {
    if (updatedData.updatedField == "tracking_number") {
      // Extract app_id values
      const appIds = Object.values(updatedData.updatedData) // Get the values of the object
        .filter((item) => item && item.app_id) // Ensure the item has 'app_id'
        .map((item) => item.app_id); // Extract the app_id

      console.log("App IDs:", appIds);

      const requestBody = {
        app_id: appIds,
        field: updatedData.updatedField,
        value: [updatedData.newValue],
      };

      dispatch(updateHoToBranchEntry({ jwtBearer, requestBody }))
        .then(() => {
          // Dispatch fetchChatData after updateRescheduleDate is successful
          dispatch(fetchPDDHoToBranch({ jwtBearer }));
        })
        .catch((error) => {
          console.error("Error updating reschedule date:", error);
        });
    } else if (updatedData.updatedField == "application_ids") {
      const appIds = Object.values(updatedData.updatedData) // Get the values of the object
        .filter((item) => item && item.app_id) // Ensure the item has 'app_id'
        .map((item) => item.app_id); // Extract the app_id

      console.log("App IDs:", appIds);

      const requestBody = {
        trk_no: updatedData.trk_no,
        app_id: appIds,
        field: updatedData.updatedField,
        value: updatedData.newValue,
      };

      dispatch(updateHoToBranchEntry({ jwtBearer, requestBody }))
        .then(() => {
          // Dispatch fetchChatData after updateRescheduleDate is successful
          dispatch(fetchPDDHoToBranch({ jwtBearer }));
        })
        .catch((error) => {
          console.error("Error updating reschedule date:", error);
        });
    }
  };

  const handleBranchChange = (branch) => {
    console.log("Handle branch change - ", branch, appIDData);
    form.setFieldsValue({ branch }); // Set the form field value

    // Extract and trim the numeric branch code part
    const branchCode = branch.split("-")[0].trim();
    console.log("Extracted branch code:", branchCode, branch);

    // Verify the structure and contents of appIDData
    console.log("appIDData:", JSON.stringify(appIDData, null, 2));

    // Filter application IDs based on the branch code
    const filteredAppIDs = appIDData
      .filter((app) => {
        console.log(
          "Comparing app.branch:",
          app.branch,
          "with branchCode:",
          branchCode
        );
        // Trim and adjust app.branch to match the format of branchCode
        const appBranchCode = app.branch.split("-")[0].trim(); // Extract the numeric part from app.branch
        return appBranchCode === branchCode; // Compare the branch codes directly
      })
      .flatMap((app) => app.application_ids)
      .map((appID) => ({ APP_ID: appID }));

    setFilteredAppIDData(filteredAppIDs); // Update the filteredAppIDData state
    console.log("Filtered app IDs:", filteredAppIDs);
  };

  // Filter appIDData based on the selected branch
  const flattenedAppIDData = appIDData.flatMap((app) =>
    app.application_ids.map((appId) => ({
      APP_ID: appId,
      BR_CD: app.branch,
    }))
  );

  const floatButtonProps = useSpring({
    to: { transform: "translateY(0px)", opacity: 1 },
    from: { transform: "translateY(100px)", opacity: 0 },
    config: { tension: 200, friction: 10 },
  });

  const boxShadowFunc = (e) => {
    console.log("Color func - ", e);
    switch (e) {
      case "In-Transit":
        return "0px 4px 8px rgba(245, 144, 39, 0.3)";
      case "In-Query":
        return "0px 4px 8px rgba(255, 0, 0, 0.3)";
      case "Received":
        return "0px 4px 8px rgba(33, 206, 66, 0.8)";
      default:
        return "0px 4px 8px rgba(0, 0, 0, 0.1)";
    }
  };

  const groupedData = chatData.reduce((acc, data) => {
    // Check if this tracking number already exists in the accumulator
    if (!acc[data.trk_no]) {
      acc[data.trk_no] = [];
    }
    // Push the current data entry into the group for the corresponding tracking number
    acc[data.trk_no].push(data);
    return acc;
  }, {});

  console.log("Group data - ", groupedData);

  const filteredGroupedData = Object.entries(groupedData).filter(
    ([trackingNumber, groupData]) => {
      if (!searchValue || searchField === "Select") {
        return true; // If no search value or field is selected, return all data
      }

      // Match based on selected search field
      return groupData.some((item) => {
        if (searchField === "box_number") {
          return item.kyc_carton_no
            .toLowerCase()
            .includes(searchValue.toLowerCase());
        }
        if (searchField === "tracking_number") {
          return trackingNumber
            .toLowerCase()
            .includes(searchValue.toLowerCase());
        }
        if (searchField === "application_ids") {
          return item.app_id.toLowerCase().includes(searchValue.toLowerCase());
        }
        return false; // Default case
      });
    }
  );


  const statusCodeToText = (status) => {
    switch (status) {
      case "1":
        return "In-Transit";
      case "2":
        return "In-Query";
      case "3":
        return "Received";
      default:
        return "Unknown Status";
    }
  };


  const bottomFrameVerification = (e) => {
    console.log("verification status - ", e);
    switch (e) {
      case "4":
        return "orange";
      case "5":
        return "green";
      case "8":
        return "red";
      default:
        return "0px 4px 8px rgba(0, 0, 0, 0.1)";
    }
  };
  const headLightGradient = (e) => {
    // console.log("Color func - ", e);
    switch (e) {
      // verification pending
      case "4":
        return "linear-gradient(to right, white,white)";
      // verified
      case "5":
        return "linear-gradient(to right, white,white)";
      // missing/damaged
      case "8":
        return "linear-gradient(to right, rgba(255, 0, 0, 0.8) , rgba(255, 0, 0, 0.1))";
      default:
        return "linear-gradient(to right, white,white)";
    }
  };

  const handleInQueryCheckboxChange = (e) => {
    setIsInQueryChecked(e.target.checked);
  };


  function TruckCargo() {
    return (
      <div
        style={{
          width: "150px",
          height: "60px",
          border: "2px solid black",
          backgroundColor: "transparent",
          // position: 'absolute',
          top: "10px",
          left: "0px",
          // borderRadius: '5px',
          borderRadius: "10px 10px 0 0",
        }}
      >
        {/* Cargo Area */}
        <div
          style={{
            color: "black",
            fontSize: "16px",
            textAlign: "center",
            lineHeight: "60px",
            fontWeight: "bold",
            //   position: 'absolute',
            width: "100%",
            top: 0,
            left: 0,
          }}
        >
          TRK1001
        </div>
      </div>
    );
  }

  function TruckDoor() {
    return (
      <div
        style={{
          height: "40px",
          width: "46px",
          borderRadius: "0 20px 0 0",
          border: "2px solid",
          borderLeft: "white",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            border: "solid black 2px",
            height: "15px",
            width: "30px",
            marginTop: "2px",
            borderRadius: "0 12px 0 0",
          }}
        ></div>
        <span style={{ fontSize: "10px", whiteSpace: "nowrap" }}>
          Blue dart
        </span>
      </div>
    );
  }

  function TruckEngine() {
    return (
      <>
        <div style={{ display: "flex" }}>
          <div
            style={{
              border: "solid 2px",
              height: "20px",
              width: "20px",
              borderRadius: "0 10px 0 0",
              borderLeft: "white",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              gap: "0",
              position: "relative",
              top: "11px",
              left: "-3px",
            }}
          >
            <div
              style={{
                width: "10px",
                height: "5px",
                background: "white",
                transform: "perspective(60px) rotateY(-70deg)",
                // margin: "50px",
                alignSelf: "start",
                //   backgroundColor: "yellow",
                border: "solid 2px black",
              }}
            ></div>
            <motion.span
              style={{
                // width: "60px",
                height: "10px",
                background: "white",
                //   border: "solid 1px black",
                transform: "perspective(60px) rotateY(-70deg)",
                // margin: "50px",
                alignSelf: "self-start",
                position: "relative",
                left: "-27px",
                top: "-2px",
                background: headLightGradient(items[0]?.status),
                //   "linear-gradient(to right, rgba(255, 0, 0, 0.8) , rgba(255, 0, 0, 0.1))",
              }}
              className="headLight"
              transition={{
                duration: 0.5,
                repeat: Infinity,
                repeatType: "reverse",
              }}
            ></motion.span>
          </div>
        </div>
      </>
    );
  }

  const handleCardClick = async (data) => {
    if (!data || data.length === 0) {
      console.error("Error: Card data is empty or undefined.");
      return;
    }

    setSelectedCardData(data); // Set the entire array as selected data
    console.log("Card click - ", data);

    // Check the status of the first item to decide which modal to open
    data[0].status === "1"
      ? setDetailModalVisible(true)
      : setQueryModalVisible(true);
  };

  const verificationCodeToText = (verification) => {
    switch (verification) {
      case "4":
        return "Pending at HO";
      case "5":
        return "Verified";
      case "8":
        return "Missing/Damaged Documents";
      default:
        return "Unknown Verification";
    }
  };
  const verificationCodeForTableToText = (verification) => {
    // console.log("verification status for table", verification);
    switch (verification) {
      case "4":
        return "Verification Pending At HO";
      case "5":
        return "Verified";
      case "8":
        return "Missing/Damaged Documents";
      default:
        return "Unknown Verification";
    }
  };

  const myColumns = [
    {
      title: "Tracking Number",
      dataIndex: "tracking_number",
      key: "tracking_number"
    },
    {
      title: "Verification",
      dataIndex: "verification",
      key: "verification",
      render: (verification) => verificationCodeToText(verification),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => statusCodeToText(status),
    },
    // { title: "Vendor", dataIndex: "vendor", key: "vendor" },
    { title: "Branch", dataIndex: "branch", key: "branch" },
    { title: "Created At", dataIndex: "created_at", key: "created_at" },
  ];

  console.log("filteredGroupDAta",filteredGroupedData)

  const myFormattedData = filteredGroupedData.map((group) => {
    // group[0] is the tracking number (e.g., "Track0001")
    // group[1] is an array of data objects for that tracking number
    const trackingNumber = group[0];
    const item = group[1][0]; 
  
    return {
      tracking_number: trackingNumber,
      verification: item.verification,
      status: item.status, 
    //   vendor: item.app_name,
      branch: item.branch, 
      created_at: moment(item.created_at).format("DD-MM-YYYY"), // Formatted created_at date
      fullData: item, 
    };
  });
  
  console.log("Formatted Data:", myFormattedData);
  

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "0 10px",
        }}
      >
        <Form layout="inline">
          <div
            className="selectAndsearch"
            style={{ display: "flex", margin: "0 30px" }}
          >
            <Form.Item style={{ marginBottom: "20px" }}>
              <Select
                value={searchField}
                onChange={handleSearchFieldChange}
                style={{
                  width: 170,
                  height: 49,
                  borderRadius: "5px",
                  border: "1px solid #d9d9d9",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  fontSize: "16px",
                  //   textAlign:"center"
                }}
                dropdownStyle={{
                  borderRadius: "5px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Option value="tracking_number">Tracking Number</Option>
                <Option value="application_ids">Application ID</Option>
              </Select>
            </Form.Item>

            <Form.Item className="searchbarContainer">
              <Input
                placeholder="Search..."
                value={searchValue}
                onChange={handleSearchChange}
                prefix={
                  <SearchOutlined
                    style={{ color: "#1890ff", marginRight: "10px" }}
                  />
                }
                style={{
                  height: 49,
                  borderRadius: "5px",
                  border: "1px solid #d9d9d9",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  fontSize: "16px",
                }}
                className="mySearchBar"
                disabled={enableSearch}
              />
            </Form.Item>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "78vw",
              textAlign: "end",
            }}
          >
            <animated.div
              style={{
                ...floatButtonProps,
                position: "fixed",
                top: "140px",
                right: "20px",
              }}
            ></animated.div>
          </div>

          <animated.div
            style={{
              ...floatButtonProps,
              position: "fixed",
              bottom: "20px",
              right: "20px",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              icon={
                openBox ? (
                  <CodeSandboxOutlined
                    style={{ fontSize: "36px", marginLeft: "3px" }}
                  />
                ) : (
                  <DropboxOutlined
                    style={{ fontSize: "36px", marginLeft: "3px" }}
                  />
                )
              }
              size="large"
              onClick={addBranchNewEntry}
              // onClick={openBoxDrawer}
              onMouseEnter={() => setOpenBox(false)}
              onMouseLeave={() => setOpenBox(true)}
              style={{
                width: "70px", // Increased width
                height: "70px", // Increased height
                fontSize: "24px", // Increased font size
                backgroundColor: "red",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
              }}
            />
          </animated.div>
        </Form>

        {/* right container */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "40px",
            marginBottom: "20px",
            justifyContent: "center",
            marginRight: "50px",
            position:"relative",
            top:"-50px"
          }}
        >
          {/* car and table */}

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              gap: "5px",
              borderRadius: "10px",
              // padding: "5px",
              // boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            }}
          >
            <button
              onClick={() => {
                setViewTable(false);
                setSelectedButton("truckIcon");
              }}
              style={{
                background:
                  selectedButton === "truckIcon" ? "#f0f0f0" : "transparent",
                border: "none",
                fontSize: "28px",
                cursor: "pointer",
                // padding: "5px 10px",
                paddingTop: "10px",
                paddingBottom: "5px",
                paddingLeft: "7px",
                paddingRight: "7px",
                borderRadius: "8px",
                transition: "background-color 0.3s ease, transform 0.2s ease",
                boxShadow:
                  selectedButton === "truckIcon"
                    ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                    : "none",
              }}
              onMouseEnter={(e) => (e.target.style.transform = "scale(1.1)")}
              onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
            >
              <PiSquaresFour />
            </button>
            <button
              onClick={() => {
                setViewTable(true);
                setSelectedButton("table");
              }}
              style={{
                background:
                  selectedButton === "table" ? "#f0f0f0" : "transparent",
                border: "none",
                fontSize: "28px",
                cursor: "pointer",
                paddingTop: "10px",
                paddingBottom: "5px",
                paddingLeft: "7px",
                paddingRight: "7px",
                borderRadius: "8px",
                transition: "background-color 0.3s ease, transform 0.2s ease",
                boxShadow:
                  selectedButton === "table"
                    ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                    : "none",
              }}
              onMouseEnter={(e) => (e.target.style.transform = "scale(1.1)")}
              onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
            >
              <IoListOutline />
            </button>
          </div>

          {/* refresh icon */}
          <div>
            <Button
              type="primary"
              shape="circle"
              size="large"
              onClick={handleRefresh}
              style={{
                width: "40px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                backgroundColor: "white",
                position: "relative",
                padding: 0,
                borderRadius: "50%",
              }}
            >
              {loading ? (
                <span
                  className={loading ? "spin" : ""}
                  style={{
                    fontSize: "16px",
                    fontWeight: "900",
                    color: "blue",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    transformOrigin: "50% 50%",
                  }}
                >
                  <RiRefreshLine
                    style={{
                      fontSize: "40px",
                      color: "blue",
                      marginLeft: "4px",
                    }}
                    className="spin"
                  />
                </span>
              ) : (
                <RiRefreshLine
                  style={{
                    fontSize: "40px",
                    color: "blue",
                    marginLeft: "4px",
                  }}
                />
              )}
            </Button>
          </div>
        </div>
      </div>

      {
  loading ? (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Lottie
        animationData={truckAnimation}
        loop={true}
        style={{ width: "400px", height: "400px" }}
      />
    </div>
  ) : (
    <>
      {viewTable ? (
        <MyStyledTable
          columns={myColumns}
          dataSource={myFormattedData}
          rowKey="tracking_number"
          pagination={{
            pageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "20", "50"],
          }}
          onRow={(record) => ({
            onClick: () => handleTableRowClick(record),
          })}
          style={{ margin: "40px" }}
        />
      ) : (
             <CardContainer style={{ marginLeft: "40px" }}>
          {/* {Object.entries(groupedData).map(([trackingNumber, groupData], index) => { */}
          {filteredGroupedData.map(([trackingNumber, groupData], index) => {
            // Determine the overall status and verification for the group
            const allStatusInTransit = groupData.every(
              (item) => item.status === "1"
            );
            const allVerificationPending = groupData.every(
              (item) => item.verification === "4"
            );

            const overallStatus = allStatusInTransit
              ? "IN-TRANSIT"
              : "RECEIVED";
            const overallVerification = allVerificationPending
              ? "VERIFICATION PENDING"
              : "VERIFIED";

            return (
              <card
                style={{
                  position: "relative",
                  marginBottom: "40px",
                  cursor: "pointer",
                }}
                onClick={() => handleCardClick(groupData)}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      width: "150px",
                      height: "60px",
                      border: "2px solid black",
                      backgroundColor: "transparent",
                      // position: 'absolute',
                      top: "10px",
                      left: "0px",
                      // borderRadius: '5px',
                      borderRadius: "10px 10px 0 0",
                    }}
                  >
                    {/* Cargo Area */}
                    <div
                      style={{
                        color: "black",
                        fontSize: "16px",
                        textAlign: "center",
                        lineHeight: "60px",
                        fontWeight: "bold",
                        //   position: 'absolute',
                        width: "100%",
                        top: 0,
                        left: 0,
                      }}
                    >
                      {groupData[0].trk_no.toUpperCase()}
                    </div>
                  </div>
                  <div style={{ alignSelf: "flex-end" }}>
                    <TruckDoor />
                  </div>
                  <div style={{ alignSelf: "flex-end" }}>
                    {/* <TruckEngine /> */}

                    {/* truck engine + head light */}

                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          border: "solid 2px",
                          height: "20px",
                          width: "20px",
                          borderRadius: "0 10px 0 0",
                          borderLeft: "white",
                        }}
                      ></div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          gap: "0",
                          position: "relative",
                          top: "11px",
                          left: "-3px",
                        }}
                      >
                        <div
                          style={{
                            width: "10px",
                            height: "5px",
                            background: "white",
                            transform: "perspective(60px) rotateY(-70deg)",
                            // margin: "50px",
                            alignSelf: "start",
                            //   backgroundColor: "yellow",
                            border: "solid 2px black",
                          }}
                        ></div>
                        <motion.span
                          style={{
                            width: "60px",
                            height: "10px",
                            background: "white",
                            //   border: "solid 1px black",
                            transform: "perspective(60px) rotateY(-70deg)",
                            // margin: "50px",
                            alignSelf: "self-start",
                            position: "relative",
                            left: "-27px",
                            top: "-2px",
                            background: headLightGradient(
                              groupData[0].verification
                            ),
                            //   "linear-gradient(to right, rgba(255, 0, 0, 0.8) , rgba(255, 0, 0, 0.1))",
                          }}
                          className="headLight"
                          transition={{
                            duration: 0.5,
                            repeat: Infinity,
                            repeatType: "reverse",
                          }}
                        ></motion.span>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      height: "13px",
                      width: "220px",
                      border: "2px solid",
                      borderTopColor: bottomFrameVerification(
                        groupData[0].verification
                      ),
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: bottomFrameVerification(
                        groupData[0].verification
                      ),
                    }}
                  >
                    <span style={{ color: "white" }}>
                      {groupData[0].status && (
                        <b>
                          {" "}
                          <p
                            style={{
                              textAlign: "left",
                              width: "100%",
                              paddingLeft: "10px",
                              fontSize: "10px",
                            }}
                          >
                            {Array.isArray(groupData[0].status)
                              ? groupData[0].status.join(", ")
                              : groupData[0].status === "1"
                              ? " IN-TRANSIT"
                              : groupData[0].status === "3"
                              ? " RECEIVED"
                              : groupData[0].status}
                          </p>
                        </b>
                      )}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      position: "absolute",
                      top: "0",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: "180px",
                        top: "2.5px",
                      }}
                    >
                      <Circle />
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        left: "35px",
                        top: "2.5px",
                      }}
                    >
                      <Circle />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        {" "}
                        <LeftTyre />
                      </div>
                      <div>
                        <RightTyre />
                      </div>
                    </div>
                  </div>
                </div>
              </card>
            );
          })}
        </CardContainer>
      )}
    </>
  )}

<Drawer
      open={showAddBranchEntryModalVisible}
      onClose={handleCloseModal}
      width={600}
    //   style={{ borderRadius: "15px" }}
    //   title={"Return Courier"}
    >
      <Form
        layout="vertical"
        style={{ padding: "0px 40px", fontFamily: "Arial, sans-serif" }}
        form={form}
        onFinish={onFinish}
      >
        {/* Branch Selection */}
        <Form.Item
          name="branch"
          label={<strong className="customerSubHead">Select Branch</strong>}
          rules={[
            {
              required: true,
              message: "Please select a branch",
            },
          ]}
        >
          <Select
            showSearch
            style={{
              width: "100%",
              height: "50px",
              borderRadius: "8px",
              fontWeight: "500",
            }}
            placeholder="Select a Branch"
            onChange={handleBranchChange}
          >
            {Object.values(allBranches).map((branch) => (
              <Option key={branch} value={branch}>
                {branch}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {/* Tracking Number */}
        <Form.Item
          name="trackingNumber"
          label={<strong className="customerSubHead">Enter POD Number</strong>}
          rules={[
            {
              required: true,
              message: "Please enter tracking number",
            },
          ]}
        >
          <Input
            style={{
              fontSize: "16px",
              fontWeight: "600",
              color: "black",
              borderRadius: "8px",
              padding: "12px",
              border: "1px solid #d9d9d9",
            }}
            placeholder="Enter POD Number"
          />
        </Form.Item>

        {/* Application ID Selection */}
        <Form.Item
          name="appIDs"
          label={
            <strong style={{ fontSize: "18px", color: "grey", fontWeight: "500" }}>
              Select Application ID:
            </strong>
          }
        >
          <Select
            showSearch
            style={{
              fontSize: "16px",
              fontWeight: "600",
              color: "black",
              borderRadius: "8px",
              height: "50px",
              border: "1px solid #d9d9d9",
            }}
            placeholder="Select an Application ID"
            onChange={handleSelectChange}
            filterOption={(input, option) =>
              option?.label?.toLowerCase().includes(input.toLowerCase())
            }
            options={filteredAppIDData
              .filter((app) => !selectedAppIDs.includes(app.APP_ID))
              .map((app) => ({
              value: app.APP_ID,
              label: app.APP_ID, // Display application ID only
            }))}
          />
        </Form.Item>

        {/* Display Selected Application IDs */}
        <div style={{ marginTop: "20px" }}>
          {selectedAppIDs.map((appID) => (
            <Tag
              key={appID}
              closable
              onClose={() => handleRemoveAppID(appID)}
              style={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#000",
                borderRadius: "8px",
                margin: "5px",
                padding: "10px 10px",
                backgroundColor: "#e6f7ff",
                border: "1px solid #91d5ff",
              }}
            >
              {appID}
            </Tag>
          ))}
        </div>

        {/* Submit Button */}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              width: "100%",
              height: "45px",
              fontSize: "16px",
              fontWeight: "600",
              borderRadius: "8px",
              backgroundColor: "#1890ff",
              borderColor: "#1890ff",
              color: "#fff",
              marginTop:"20px"
            }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
      <DetailModal
        visible={modalVisible}
        onClose={handleCloseModal}
        data={selectedCardData}
        app_id={cleanAppIDData}
        onSave={handleSaveModal} // Function to save the updated tracking number
      />
      <ReceivedModal
        visible={queryModalVisible}
        onClose={handleCloseModal}
        data={selectedCardData}
      />
    </>
  );
};

export default HoToBranch;
