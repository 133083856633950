import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
    Modal,
    Typography,
    List,
    Select,
    Button,
    Popconfirm,
    Popover,
    Drawer,
    Space
} from "antd";
import "../../style/styleComp"
import { debounce } from "lodash";
import { fetchPDDMaster } from "../../../../redux/slice/getPDDMaster";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../../context/AuthContext";
import { MyStyledTable, StyledTable } from "../../style/styleComp";
import {

    CheckCircleFilled,
    ExclamationCircleOutlined,
    InfoCircleOutlined

} from "@ant-design/icons";

import { RxCrossCircled } from "react-icons/rx";

// import { IoMdArrowDropupCircle } from "react-icons/io";
import { IoIosArrowDropup } from "react-icons/io";

// import { IoMdArrowDropdownCircle } from "react-icons/io";
import { IoIosArrowDropdown } from "react-icons/io";

const { Text, Title, View } = Typography;
const { Option } = Select;

const AfterReceivedHo = ({
    visible,
    onClose,
    data,
    all_app_id,
    missing_docs,
    onMissingDocs,
    statusDetails,
}) => {
    if (!data || !all_app_id) {
        return null;
    }

    console.log('Verify Drawer Data - ', data, statusDetails)

    const dispatch = useDispatch();
    const { authState } = useAuth();
    const [loading, setLoading] = useState(true);
    const [selectedValues, setSelectedValues] = useState({});
    const [selectedRows, setSelectedRows] = useState({}); // State to track selected rows
    const [isModified, setIsModified] = useState(false); // Tracks modification state
    const [openDialog, setOpenDialog] = useState(false);

    const jwtBearer = authState.token;

    const fetchData = () => {
        setLoading(true);
        dispatch(fetchPDDMaster({ jwtBearer }))
            .then(() => setLoading(false))
            .catch((error) => {
                console.error("Error fetching PDD master data:", error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
        const intervalId = setInterval(fetchData, 1000 * 60 * 5); // 5 minutes interval
        return () => clearInterval(intervalId);
    }, [dispatch]);

    const masterData = useSelector(
        (state) => state.fetchPDDMaster?.data?.message || []
    );

    const checklistPLUSEC = useMemo(() => {
        const usecData = masterData.find((item) => item.field === "usec_docs");
        return usecData ? usecData.value : [];
    }, [masterData]);

    const checklistPLSEC = useMemo(() => {
        const secData = masterData.find((item) => item.field === "sec_docs");
        return secData ? secData.value : [];
    }, [masterData]);

    const matchedAppIDs = useMemo(() => {
        return all_app_id.filter((app) =>
            data.application_ids.includes(app.APP_ID)
        );
    }, [all_app_id, data.application_ids]);

    useEffect(() => {
        const initialSelectedValues = {};
        const initialSelectedRows = {};

        matchedAppIDs.forEach((app) => {
            const checklist =
                app.PRODUCT === "PL-USEC" ? checklistPLUSEC : checklistPLSEC;
            initialSelectedValues[app.APP_ID] = {};

            checklist.forEach((item) => {
                const missingDocsForApp = missing_docs[app.APP_ID] || {};
                if (missingDocsForApp.verified_docs?.includes(item)) {
                    initialSelectedValues[app.APP_ID][item] = "Verified";
                } else if (missingDocsForApp.missing_documents?.includes(item)) {
                    initialSelectedValues[app.APP_ID][item] = "Missing";
                } else if (missingDocsForApp.not_applicable_docs?.includes(item)) {
                    initialSelectedValues[app.APP_ID][item] = "N/A";
                } else if (missingDocsForApp.damaged_docs?.includes(item)) {
                    initialSelectedValues[app.APP_ID][item] = "Damaged";
                } else {
                    initialSelectedValues[app.APP_ID][item] = "Verified";
                }
            });

            // Initialize all rows as unselected (gray tick)
            initialSelectedRows[app.APP_ID] = false;
        });

        setSelectedValues((prev) => ({ ...prev, ...initialSelectedValues }));
        setSelectedRows(initialSelectedRows);
    }, [matchedAppIDs, checklistPLUSEC, checklistPLSEC, missing_docs]);

    const getChecklist = useCallback(
        (product) => {
            return product === "PL-USEC" ? checklistPLUSEC : checklistPLSEC;
        },
        [checklistPLUSEC, checklistPLSEC]
    );

    const handleDropdownChange = (appId, item, value) => {
        setSelectedValues((prevState) => {
            const updatedValues = {
                ...prevState,
                [appId]: {
                    ...prevState[appId],
                    [item]: value,
                },
            };

            console.log('Updated Value - ', updatedValues)

            // If any document other than "Declaration for damaged docs" is marked as "Damaged"
            if (value === "Damaged" && item !== "Declaration for damaged docs") {
                // Automatically set "Declaration for damaged docs" to "Missing" if not already missing
                updatedValues[appId]["Declaration for damaged docs"] = "Missing";
            }

            return updatedValues;
        });
        setIsModified(true);
    };

    const handleRowSelect = (appId) => {
        setSelectedRows((prevState) => {
            const isSelected = !prevState[appId];

            if (isSelected) {
                // Update all missing documents to "Verified"
                setSelectedValues((prevValues) => {
                    const updatedValues = { ...prevValues };
                    const appValues = updatedValues[appId];

                    Object.keys(appValues).forEach((doc) => {
                        if (appValues[doc] === "Missing") {
                            appValues[doc] = "Verified";
                        }
                    });

                    return updatedValues;
                });
            }

            return {
                ...prevState,
                [appId]: isSelected, // Toggle selection
            };
        });
        setIsModified(true);
    };

    useEffect(() => {
        // Check if any rows are selected or if any value in selectedValues is modified
        const anySelected = Object.values(selectedRows).some((isSelected) => isSelected);
        const anyModified = Object.keys(selectedValues).some((appId) => {
            const values = selectedValues[appId];
            return Object.values(values || {}).some((value) => value !== "Verified"); // Check for non-verified values
        });

        // Set `isModified` based on selections or modifications
        // setIsModified(anySelected || anyModified);
    }, [selectedValues, selectedRows]);



    const handleSubmitMissingDocs = () => {
        const missingDocsJSON = Object.keys(selectedValues)
            .map((appId) => {
                // Only include selected rows or rows with changes other than 'Verified'
                if (!selectedRows[appId]) {
                    // Check if any item in this appId has a status other than 'Verified'
                    const hasNonVerified = Object.values(selectedValues[appId]).some(
                        (value) => value !== "Verified"
                    );
                    if (!hasNonVerified) {
                        return null; // Skip if nothing has changed
                    }
                }

                const missingDocsForApp = {
                    application_id: appId,
                    missing_documents: [],
                    not_applicable_docs: [],
                    damaged_docs: [],
                    verified_docs: [],
                };

                Object.keys(selectedValues[appId]).forEach((doc) => {
                    const selectedValue = selectedValues[appId][doc];
                    if (selectedValue === "Verified") {
                        missingDocsForApp.verified_docs.push(doc);
                    } else if (selectedValue === "N/A") {
                        missingDocsForApp.not_applicable_docs.push(doc);
                    } else if (selectedValue === "Damaged") {
                        missingDocsForApp.damaged_docs.push(doc);
                    } else {
                        missingDocsForApp.missing_documents.push(doc);
                    }
                });

                // Include only if there are changes in any category
                if (
                    missingDocsForApp.verified_docs.length > 0 ||
                    missingDocsForApp.missing_documents.length > 0 ||
                    missingDocsForApp.not_applicable_docs.length > 0 ||
                    missingDocsForApp.damaged_docs.length > 0
                ) {
                    return missingDocsForApp;
                }
                return null;
            })
            .filter(Boolean);

        const callbackData = {
            tracking_number: data.tracking_number,
            branch: data.branch,
            docs: missingDocsJSON,
        };

        console.log('Submit Missing Docs data - ', callbackData)

        if (onMissingDocs) {
            onMissingDocs(callbackData);
        }
    };

    console.log("selected values", selectedValues);

    const columns = [
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (text, record) => {
                const status = statusDetails.find(
                    (item) => item.app_id === record.APP_ID
                );
                const verification = status?.verification;

                // Check if any document for this record is marked as "Missing"
                const hasMissingDocs = Object.values(selectedValues[record.APP_ID] || {}).includes(
                    "Missing"
                );

                // Display icons based on status, whether the row is selected, and if any document is "Missing"
                if (selectedRows[record.APP_ID]) {
                    return (
                        <CheckCircleFilled
                            style={{ color: "green", fontSize: "28px" }}
                            onClick={() => handleRowSelect(record.APP_ID)}
                        />
                    );
                }

                if (hasMissingDocs) {
                    return (
                        <ExclamationCircleOutlined
                            style={{ color: "red", fontSize: "28px" }}
                            onClick={() => handleRowSelect(record.APP_ID)}
                        />
                    );
                }

                if (verification === "5") {
                    return (
                        <CheckCircleFilled
                            style={{ color: "green", fontSize: "28px" }}
                            onClick={() => handleRowSelect(record.APP_ID)}
                        />
                    );
                } else if (verification === "4") {
                    return (
                        <RxCrossCircled
                            style={{ color: "grey", fontSize: "28px" }}
                            onClick={() => handleRowSelect(record.APP_ID)}
                        />
                    );
                } else if (verification === "8") {
                    return (
                        <ExclamationCircleOutlined
                            style={{ color: "red", fontSize: "28px" }}
                            onClick={() => handleRowSelect(record.APP_ID)}
                        />
                    );
                } else {
                    return (
                        <ExclamationCircleOutlined
                            style={{ color: "orange", fontSize: "28px" }}
                            onClick={() => handleRowSelect(record.APP_ID)}
                        />
                    );
                }
            },
        },
        {
            title: "Application Id",
            dataIndex: "APP_ID",
            key: "APP_ID",
        },
        {
            title: "OXYZO ID",
            dataIndex: "PARTNER_LOAN_ID",
            key: "PARTNER_LOAN_ID",
        }
        // ,
        // {
        //     title: "BRANCH",
        //     dataIndex: "BR_CD",
        //     key: "BR_CD",
        // }
        ,
        {
            title: "NAME",
            dataIndex: "APPLICANT_NAME",
            key: "APPLICANT_NAME",
        }
        ,
        {
            title: "PRODUCT",
            dataIndex: "PRODUCT",
            key: "PRODUCT",
        }
    ];


    const dataSource = matchedAppIDs.map((app, index) => ({
        ...app,
        key: index + 1,
    }));

    console.log("dataSource", dataSource)

    const isActionDisabled = data.status === "3" && data.verification === "5";

    console.log("isModified??", isModified)
    const handleDrawerClose = () => {
        if (isModified) {
            setOpenDialog(true)
        }
        else {
            onClose()
        }

    };

    const handleSaveAndClose = () => {
        handleSubmitMissingDocs();
        // onClose();
        setOpenDialog(false);
    };

    const handleCancelAndClose = () => {
        setOpenDialog(false);
        onClose();
    };


    const getStatusText = (statusCode) => {
        switch (statusCode) {
            case "1":
                return "In-Transit";
            case "2":
                return "In-Query";
            case "3":
                return "Received";
            default:
                return "";
        }
    };

    const getVerificationText = (statusCode) => {
        switch (statusCode) {
            case "4":
                return "Pending at HO";
            case "5":
                return "Verified";
            case "8":
                return "Missing/Damaged Documents and Pending";
            default:
                return "";
        }
    };

    return (

        <Drawer
            title={
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Popover
                        title="In-Transit"
                        trigger="hover"
                    >
                        <InfoCircleOutlined
                            style={{
                                marginRight: "8px",
                                color: "#1890ff",
                                fontSize: "28px",
                            }}
                        />
                    </Popover>

                    <Title
                        level={4}
                        style={{
                            marginBottom: "16px",
                            color: "rgba(0, 0, 0, 0.7)",
                            fontSize: "20px",
                            fontFamily: "Poppins",
                            marginLeft: "5px",
                        }}
                    >
                        Courier details
                    </Title>
                </div>
            }
            visible={visible}
            onClose={handleDrawerClose}
            width={850}
        >
            <div style={{ padding: "0 20px" }}>
                <Space
                    style={{
                        display: "flex",
                        marginBottom: "8px",
                        padding: "0 8px",
                        borderRadius: "4px",
                        backgroundColor: "#ffffff",
                        boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                        fontSize: "20px",
                        fontFamily: "Poppins",
                        display: "flex",
                        justifyContent: "space-between",
                        height: "50px"
                    }}

                >
                    <strong style={{ color: "#555" }}>Status :</strong>

                    <Text
                        style={{
                            fontSize: "20px",
                            fontFamily: "Poppins",
                            display: "flex",
                            justifyContent: "space-between",
                            backgroundColor: "green",
                            padding: "0px 8px",
                            color: "white",
                            borderRadius: "6px",
                        }}
                    >
                        {getStatusText(data.status)}
                    </Text>

                </Space>

                <Space
                    style={{
                        display: "flex",
                        marginBottom: "8px",
                        padding: "8px",
                        borderRadius: "4px",
                        backgroundColor: "#ffffff",
                        boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                        fontSize: "20px",
                        fontFamily: "Poppins",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <strong style={{ color: "#555" }}>POD Number :</strong>
                    <Text
                        style={{
                            fontSize: "18px",
                            fontFamily: "Poppins",
                            display: "flex",
                            justifyContent: "space-between",
                            fontWeight: "800",
                        }}
                    >
                        {data.tracking_number}
                    </Text>
                </Space>

                <Space
                    style={{
                        display: "flex",
                        marginBottom: "8px",
                        padding: "8px",
                        borderRadius: "4px",
                        backgroundColor: "#ffffff",
                        boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                        fontSize: "20px",
                        fontFamily: "Poppins",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <strong style={{ color: "#555" }}>Verification:</strong>
                    <Text

                    >
                        {data?.verification === "8" ? (
                            // Display two buttons for "Missing/Damaged Documents" and "Pending"
                            <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
                                <button style={{
                                    backgroundColor: "red", color: "white", display: "flex",
                                    marginBottom: "8px",
                                    padding: "8px",
                                    borderRadius: "4px",
                                    // boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                                    fontSize: "20px",
                                    fontFamily: "Poppins",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    border: "red solid 2px"

                                }}>
                                    Missing/Damaged Documents
                                </button>
                                <button style={{
                                    backgroundColor: "yellow", color: "black", display: "flex",
                                    border: "yellow solid 2px",
                                    marginBottom: "8px",
                                    padding: "8px",
                                    borderRadius: "4px",
                                    // boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                                    fontSize: "20px",
                                    fontFamily: "Poppins",
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}>
                                    Pending
                                </button>
                            </div>
                        ) : (
                            <Text
                                style={{
                                    fontSize: "18px",
                                    fontFamily: "Poppins",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    backgroundColor:
                                        data.verification === "5"
                                            ? "green"
                                            : data.verification === "4"
                                                ? "orange"
                                                : data.verification === "8"
                                                    ? "red"
                                                    : "",

                                    padding: "3px 8px",
                                    color: "white",
                                    borderRadius: "6px",
                                }}
                            >
                                {getVerificationText(data?.verification)}
                            </Text>
                        )}
                    </Text>
                </Space>

                <Space
                    style={{
                        display: "flex",
                        marginBottom: "8px",
                        padding: "8px",
                        borderRadius: "4px",
                        backgroundColor: "#ffffff",
                        boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                        fontSize: "20px",
                        fontFamily: "Poppins",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <strong style={{ color: "#555" }}>Branch :</strong>
                    <Text
                        style={{
                            fontSize: "18px",
                            fontFamily: "Poppins",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        {data.branch}
                    </Text>
                </Space>





                <MyStyledTable
                    columns={columns}
                    dataSource={dataSource}
                    pagination={{
                        pageSize: 6,
                        showSizeChanger: true,
                        pageSizeOptions: ["5", "10", "20", "50"],
                    }}
                    style={{ marginTop: "30px" }}
                    expandable={{
                        expandedRowRender: (record) => {
                            const checklist = getChecklist(record.PRODUCT);
                            return (
                                <List
                                    size="small"
                                    bordered
                                    dataSource={checklist}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <span style={{ marginRight: "20px", fontSize: "16px" }}>
                                                {item}
                                            </span>

                                            {
                                                data.verification !== "5" ?
                                                    (<Select
                                                        value={selectedValues[record.APP_ID]?.[item] || "Verified"}
                                                        onChange={(value) =>
                                                            handleDropdownChange(record.APP_ID, item, value)
                                                        }
                                                        style={{ width: 120 }}
                                                    >
                                                        <Option value="Verified">Verified</Option>
                                                        <Option value="Missing">Missing</Option>
                                                        <Option value="Damaged">Damaged</Option>
                                                        <Option value="N/A">N/A</Option>
                                                    </Select>) : null
                                            }
                                        </List.Item>
                                    )}
                                />
                            )
                        },

                        expandIcon: ({ expanded, onExpand, record }) =>
                            expanded ? (
                                <IoIosArrowDropup onClick={e => onExpand(record, e)} style={{ fontSize: "20px", height: "20px" }} />
                            ) : (
                                <IoIosArrowDropdown onClick={e => onExpand(record, e)} style={{ fontSize: "20px", height: ":20px" }} />
                            )
                    }}



                />

                {data.verification !== "5" && isModified && (
                    <Popconfirm
                        title="Are you sure you want to submit the changes?"
                        onConfirm={handleSubmitMissingDocs}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="primary"
                            loading={loading}
                            style={{ marginTop: 20 }}
                        >
                            Submit
                        </Button>
                    </Popconfirm>
                )}



            </div>

            {/* Confirmation Dialog */}
            <Modal
                visible={openDialog}
                title="Unsaved Changes"
                onOk={handleSaveAndClose}
                onCancel={handleCancelAndClose}
                cancelText="Don't Save"
                okText="Save Changes"
                centered
            >
                <Text>Do you want to save ?</Text>
            </Modal>
        </Drawer>
    );
};

export default AfterReceivedHo;
