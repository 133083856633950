import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BASE_URL } from '../apiConfig';

// Helper function to format the date as `dd-MMM`
const formatDate = () => {
    const options = { day: '2-digit', month: 'short' };
    const date = new Date().toLocaleDateString('en-GB', options);
    return date.replace(/ /g, '-');
};


export const downloadZohoMisLead = createAsyncThunk(
    'downloadZohoMisLead/downloadReport',
    async ({ jwtBearer }, { rejectWithValue }) => {
        try {
            const response = await fetch(`${BASE_URL}pipeline/zoho/lead/download/`, {
                headers: {
                    Authorization: `Bearer ${jwtBearer}`,
                },
            });

            console.log("response of zoho lead api", response);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const blob = await response.blob();
            console.log("blob type: " + blob.type);
            const url = URL.createObjectURL(blob);


            const link = document.createElement('a');
            link.href = url;
            link.download = `Mis_Lead_${formatDate()}.xlsx`;
            link.click();
            URL.revokeObjectURL(url);

        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
export const downloadZohoFTMLead = createAsyncThunk(
    'downloadZohoFTMLead/downloadReport',
    async ({ jwtBearer }, { rejectWithValue }) => {
        try {
            const response = await fetch(`${BASE_URL}pipeline/zoho/zero/lead/ftm/`, {
                headers: {
                    Authorization: `Bearer ${jwtBearer}`,
                },
            });

            console.log("response of zoho lead api", response);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const blob = await response.blob();
            console.log("blob type: " + blob.type);
            const url = URL.createObjectURL(blob);


            const link = document.createElement('a');
            link.href = url;
            link.download = `Lead_Ftm_${formatDate()}.xlsx`;
            link.click();
            URL.revokeObjectURL(url);

        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
export const downloadZohoFTDLead = createAsyncThunk(
    'downloadZohoFTDLead/downloadReport',
    async ({ jwtBearer }, { rejectWithValue }) => {
        try {
            const response = await fetch(`${BASE_URL}pipeline/zoho/zero/lead/ftd/`, {
                headers: {
                    Authorization: `Bearer ${jwtBearer}`,
                },
            });

            console.log("response of zoho lead api", response);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const blob = await response.blob();
            console.log("blob type: " + blob.type);
            const url = URL.createObjectURL(blob);


            const link = document.createElement('a');
            link.href = url;
            link.download = `Mis_Ftd_${formatDate()}.xlsx`;
            link.click();
            URL.revokeObjectURL(url);

        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const downloadZohoMisLeadSlice = createSlice({
    name: 'downloadZohoMisLead',
    initialState: {
        isDownloading: false,
        isErrorDownload: false,
    },
    extraReducers: (builder) => {
        builder
            .addCase(downloadZohoMisLead.pending, (state) => {
                state.isDownloading = true;
                state.isErrorDownload = false;
            })
            .addCase(downloadZohoMisLead.fulfilled, (state) => {
                state.isDownloading = false;
            })
            .addCase(downloadZohoMisLead.rejected, (state, action) => {
                state.isDownloading = false;
                state.isErrorDownload = true;
                console.error('Download failed:', action.payload);
            })


            .addCase(downloadZohoFTMLead.pending, (state) => {
                state.isDownloadingFTM = true;
                state.isErrorDownloadFTM = false;
            })
            .addCase(downloadZohoFTMLead.fulfilled, (state) => {
                state.isDownloadingFTM = false;
            })
            .addCase(downloadZohoFTMLead.rejected, (state, action) => {
                state.isDownloadingFTM = false;
                state.isErrorDownloadFTM = true;
                console.error('Download failed:', action.payload);
            })

            .addCase(downloadZohoFTDLead.pending, (state) => {
                state.isDownloadingFTD = true;
                state.isErrorDownloadFTD = false;
            })
            .addCase(downloadZohoFTDLead.fulfilled, (state) => {
                state.isDownloadingFTD = false;
            })
            .addCase(downloadZohoFTDLead.rejected, (state, action) => {
                state.isDownloadingFTD = false;
                state.isErrorDownloadFTD = true;
                console.error('Download failed:', action.payload);
            });
    },
});

export default downloadZohoMisLeadSlice.reducer