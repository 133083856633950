import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Input,
  Typography,
  Space,
  List,
  Popconfirm,
  Select,
  Drawer,
  Divider,
  Popover,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { MyDetailedStyledTable } from "../../style/styleComp";

const { Text, Title } = Typography;
const { Option } = Select;

const DetailModal = ({ visible, onClose, data, app_id, onSave }) => {
  if (data == null) {
    return
  }
  console.log("returning transit data - ", data);
  console.log("returning transit app_id - ", app_id);
  const [isEditingTracking, setIsEditingTracking] = useState(false);
  const [isEditingAppId, setIsEditingAppId] = useState(false);
  const [editedTrackingNumber, setEditedTrackingNumber] = useState("");
  const [editedAppIds, setEditedAppIds] = useState([]);

  const [selectedAppId, setSelectedAppId] = useState(null);
  const [selectedAppIds, setSelectedAppIds] = useState([]);

  // Populate state with existing data when modal is opened
  useEffect(() => {
    if (data?.[0]?.trk_no) {
      setEditedTrackingNumber(data[0]?.trk_no);
    }
    if (data) {
      const appIds = data.map((item) => item.app_id);
      console.log("Selected App IDs - ", appIds);
      setEditedAppIds(appIds);
    }
  }, [data]);

  const handleEditTrackingClick = () => {
    setIsEditingTracking(true);
  };

  const handleSaveTrackingClick = () => {
    onSave({
      updatedField: "tracking_number",
      newValue: editedTrackingNumber,
      updatedData: { ...data, trk_no: editedTrackingNumber },
    });
    setIsEditingTracking(false);
  };

  const handleCancelTrackingClick = () => {
    setEditedTrackingNumber(data[0]?.trk_no);
    setIsEditingTracking(false);
  };

  const handleTrackingInputChange = (e) => {
    setEditedTrackingNumber(e.target.value);
  };

  const handleAppIdChange = (index, value) => {
    const updatedAppIds = [...editedAppIds];
    updatedAppIds[index] = value;
    setEditedAppIds(updatedAppIds);
  };

  const handleSelectAppId = (value) => {
    setSelectedAppId(value);
  };

  const handleAddAppId = () => {
    if (selectedAppId && !editedAppIds.includes(selectedAppId)) {
      setEditedAppIds([...editedAppIds, selectedAppId]);
      setSelectedAppId(null);
    }
  };

//   const handleDeleteAppId = (index) => {
//     const updatedAppIds = editedAppIds.filter((_, i) => i !== index);
//     setEditedAppIds(updatedAppIds);
//   };

//   const handleSaveAppIdsClick = () => {
//     console.log("Edited App ID - ", data[0]?.trk_no);
//     onSave({
//       trk_no: data[0]?.trk_no,
//       updatedField: "application_ids",
//       newValue: editedAppIds,
//       updatedData: { ...data, application_ids: editedAppIds },
//     });
//     setIsEditingAppId(false);
//   };



// Handle delete function, expecting index only
const handleDeleteAppId = (index) => {
  console.log("in handle deleteappid");

  // Remove the item from editedAppIds based on the index
  const updatedAppIds = editedAppIds.filter((_, i) => i !== index);
  setEditedAppIds(updatedAppIds);

  // Trigger the API call with the updated application IDs
  onSave({
    trk_no: data[0]?.trk_no,
    updatedField: "application_ids",
    newValue: updatedAppIds,
    updatedData: { ...data, application_ids: updatedAppIds },
  });

  console.log("on save", onSave);
};

  const handleSaveAppIdsClick = () => {
    console.log("Edited App ID - ", data[0]?.trk_no);
    onSave({
      trk_no: data[0]?.trk_no,
      updatedField: "application_ids",
      newValue: editedAppIds,
      updatedData: { ...data, application_ids: editedAppIds },
    });
    setIsEditingAppId(false);
  };
  


  const handleCancelAppIdsClick = () => {
    setEditedAppIds(data?.application_ids || []);
    setIsEditingAppId(false);
  };

  const getStatusText = (statusCode) => {
    switch (statusCode) {
      case "1":
        return "In-Transit";
      case "2":
        return "In-Query";
      case "3":
        return "Received";
      default:
        return "";
    }
  };

  const getVerificationText = (statusCode) => {
    switch (statusCode) {
      case "4":
        return "Pending at Branch";
      case "5":
        return "Verified";
      case "8":
        return "Missing/Damaged Documents";
      default:
        return "";
    }
  };


  return (
    <Drawer
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          <Popover
            title="In-Transit"
            trigger="hover"
          >
            <InfoCircleOutlined
              style={{
                marginRight: "8px",
                color: "#1890ff",
                fontSize: "28px",
              }}
            />
          </Popover>

          <Title
            level={4}
            style={{
              marginBottom: "16px",
              color: "rgba(0, 0, 0, 0.7)",
              fontSize: "20px",
              fontFamily: "Poppins",
              marginLeft: "5px",
            }}
          >
            Courier details
          </Title>
        </div>
      }
      visible={visible}
      onClose={onClose}
      width={700}
    >
      <div style={{ padding: "0 20px" }}>
        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "0 8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>Status:</strong>
          <Text
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {getStatusText(data[0]?.status)}
          </Text>
        </Space>

        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>Tracking Number:</strong>
          <Text
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              display: "flex",
              justifyContent: "space-between",
              fontWeight: "800",
            }}
          >
            {data[0]?.trk_no}
          </Text>
        </Space>

        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>Verification:</strong>
          <Text
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "orange",
              padding: "3px 8px",
              color: "white",
              borderRadius: "6px",
            }}
          >
            {getVerificationText(data[0]?.verification)}
          </Text>
        </Space>

        <Space
          style={{
            display: "flex",
            marginBottom: "8px",
            padding: "8px",
            borderRadius: "4px",
            backgroundColor: "#ffffff",
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            fontSize: "20px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ color: "#555" }}>Branch:</strong>
          <Text
            style={{
              fontSize: "18px",
              fontFamily: "Poppins",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {data[0]?.branch}
          </Text>
        </Space>
      </div>

      {/* Tracking Number Section */}
      <div
        style={{
          marginTop: "16px",
          marginBottom: "30px",
          fontSize: "20px",
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        <h4>POD Number:</h4>
        <Space style={{ marginTop: "8px", fontWeight: "800" }}>
          <Input
            value={editedTrackingNumber}
            onChange={handleTrackingInputChange}
            disabled={!isEditingTracking}
            style={{ flex: 1, backgroundColor: "#F4F6FF", color: "black" }}
          />
          {!isEditingTracking ? (
            <Button
              type="primary"
              onClick={handleEditTrackingClick}
              style={{ border: "2px solid #1E90FF" }}
            >
              Update POD Number
            </Button>
          ) : (
            <>
              <Button onClick={handleSaveTrackingClick} type="primary">
                Save
              </Button>
              <Button onClick={handleCancelTrackingClick}>Cancel</Button>
            </>
          )}
        </Space>
      </div>

      {/* Existing App IDs Table */}
      <MyDetailedStyledTable
  bordered
  dataSource={editedAppIds.map((app_id, index) => ({
    key: index,
    app_id,
  }))}
  pagination={data.length > 5 ? { pageSize: 5 } : false}
  columns={[
    {
      title: "Application id",
      dataIndex: "app_id",
      render: (text) => {
        return text;
      },
    },
    {
      title: "Delete",
      render: (text, record, index) => (  // Ensure index is available here
        <Space>
          <Popconfirm
            placement="leftTop"
            title={`Are you sure to delete this application id?`}
            onConfirm={() => handleDeleteAppId(index)}  // Pass index correctly here
            okText="Yes"
            cancelText="No"
            style={{ height: "100px", width: "100px" }}
          >
            <DeleteOutlined style={{ color: "red" }} />
          </Popconfirm>
        </Space>
      ),
    },
  ]}
/>

      {/* before edit app ids */}
      <div
        style={{
          padding: "16px 0",
          backgroundColor: "#f9f9f9",
          borderRadius: "8px",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          marginTop: "30px",
          overflowX: "hidden",
          margin: "20px 25px",
        }}
      >
        <>
          <h2 style={{ marginLeft: "5px", margin: "15px 30px" }}>
            Add  New Application ID(s)
          </h2>

          <Space tyle={{ marginTop: "8px", width: "100%", margin: "15px 30px" }}>
            <Select
              value={selectedAppId}
              onChange={handleSelectAppId}
              placeholder="Select Application ID"
              style={{
                flex: 1,
                minWidth: "300px",
                width: "100%",
                height: "40px",
              }}
            >
              {app_id
                .filter((item) => item.branch === data[0]?.branch) // Match branch with data[0].branch
                .flatMap((item) => item.application_ids) // Get application_ids from the filtered item
                .filter((id) => !editedAppIds.includes(id)) // Exclude already selected IDs
                .map((appId) => (
                  <Option key={appId} value={appId}>
                    {appId}
                  </Option>
                ))}
            </Select>

            <Button
              onClick={handleAddAppId}
              type="primary"
              icon={<PlusOutlined />}
            >
              Add
            </Button>
          </Space>
          <Space style={{ marginTop: "16px" }}>
            <Button onClick={handleSaveAppIdsClick} type="primary">
              Save Application IDs
            </Button>
            <Button onClick={handleCancelAppIdsClick}>Cancel</Button>
          </Space>
        </>
      </div>
    </Drawer>
  );
};

export default DetailModal;
