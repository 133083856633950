import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Tabs,
  Button,
  Input,
  Tag,
  Card,
  Form,
  Space,
  Divider,
  Progress,
  Row,
  Col,
  Radio,
  Modal,
  Select,
  Steps,
  Spin,
  FloatButton,
  Slider,
  InputNumber,
  Collapse,
  Drawer,
  Alert,
  List,
  Upload,
  notification,
} from "antd";
import styled from "styled-components";
import truckAnimation from "../../lotties/loaderTruck.json";
import Lottie from "lottie-react";
import {
  SearchOutlined,
  PlusOutlined,
  DeleteOutlined,
  CodeSandboxOutlined,
  DropboxOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import jwt_decode from "jwt-decode";
import {
  StyledModal,
  StyledCard,
  StyledRow,
  StyledCol,
  StyledTable,
  RefreshButton,
  StyledButton,
  CardContainer,
  MyStyledTable,
  MyDetailedStyledTable,
} from "../../style/styleComp";
import { useAuth } from "../../../../context/AuthContext";
import { fetchPDDWarehouse } from "../../../../redux/slice/getPDDDWarehouseData";
import { fetchPDDAppID } from "../../../../redux/slice/getPDDAppID";
import { fetchPDDAppIDRFW } from "../../../../redux/slice/getPDDAppIDRFW";
import { fetchPDDAppIDRFWPhy } from "../../../../redux/slice/getPDDAppIDRFWPhy";
import { fetchPDDMaster } from "../../../../redux/slice/getPDDMaster";
import { HashLoader } from "react-spinners";
import { addWarehouseEntry } from "../../../../redux/slice/pddAddWarehouseEntry";
import { updateBranchEntry } from "../../../../redux/slice/pddUpdateBranchEntry";
import { addMissingDocs } from "../../../../redux/slice/pddAddMissingDocs";
import { RiRefreshLine } from "react-icons/ri";
import { useSpring, animated } from "react-spring";
import "../../style/customStyles.css";
import WarehouseUploadDrawer from "./WarehouseUploadDrawer";
import DocsDetailsDrawer from "./DocsDetailsDrawer";
import moment from "moment";
import { uploadNocCalFile } from "../../../../redux/slice/uploadNocCal";
const { Title, Text } = Typography;

const { Panel } = Collapse;

const { Option } = Select;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const HoToVendor = ({ extraProp }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [openBox, setOpenBox] = useState(true);
  const [loading, setLoading] = useState(true);
  const [activeTabKey, setActiveTabKey] = useState("allTables");
  const [tableData, setTableData] = useState([]);
  const [showWarehouseUpload, setShowWarehouseUpload] = useState(false);
  const [selectedAppIDs, setSelectedAppIDs] = useState([]);
  const [selectedAppIDsPhy, setSelectedAppIDsPhy] = useState([]);
  const [searchField, setSearchField] = useState("Select");
  const [searchValue, setSearchValue] = useState("");
  const [modalVisible, setFileModalVisible] = useState(false);
  const [queryModalVisible, setPhysicalModalVisible] = useState(false);
  const [selectedCardData, setSelectedCardData] = useState(null);
  const { authState } = useAuth();
  const [vendors, setVendors] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [isAnimated, setIsAnimated] = useState(false);
  const [enableSearch, setEnableSearch] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedDocDetails, setSelectedDocDetails] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentAppId, setCurrentAppId] = useState("");


  var token_decoded = authState.token;
  var jwtBearer = authState.token;
  var jwt_payload = "";
  var user_branch = "";
  if (jwtBearer) {
    jwt_payload = jwt_decode(jwtBearer);
    user_branch = extraProp.branch_id;
  }

  const [branches, setBranches] = useState([]);

  useEffect(() => {
    // Parse the user_branch string into an array of branch IDs
    const parsedBranches = user_branch.replace(/[()]/g, "").split(",");
    console.log("parsedBranches", parsedBranches);
    setBranches(parsedBranches);
  }, [user_branch]);

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const fetchData = () => {
    console.log("Fetching chat data and app ID...");
    setLoading(true);

    Promise.all([
      dispatch(fetchPDDWarehouse({ jwtBearer })),
      dispatch(fetchPDDAppID({ jwtBearer })),
      dispatch(fetchPDDMaster({ jwtBearer })),
    ])
      .then(() => {
        console.log("Chat data and app ID fetched successfully.");
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching chat data or app ID:", error);
        setLoading(false);
      });
  };

  const fetchAvailableAppIDs = () => {
    console.log("Fetching chat data and app ID...");

    // Dispatch both actions in parallel
    Promise.all([
      dispatch(fetchPDDAppIDRFW({ jwtBearer })),
      dispatch(fetchPDDAppIDRFWPhy({ jwtBearer })),
    ])
      .then(() => {
        console.log("Chat data and app ID fetched successfully.");
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching chat data or app ID:", error);
        setLoading(false);
      });
  };

  // Initial fetch on component mount
  useEffect(() => {
    fetchData();

  }, [dispatch]); // Fetch data initially and set up interval

  const handleRefresh = () => {
    setIsAnimated(true);
    setLoading(true);
    console.log("refresing");
    setTimeout(() => {
      setLoading(false);
      setIsAnimated(false);
    }, 1000);
    fetchData();
  };



  const addBranchNewEntry = () => {
    // fetchAvailableAppIDs();
    setShowWarehouseUpload(true);
  };

  const { uploading, fileUrl, error } = useSelector((state) => state.uploadNocCal);


  const extractApplicationIds = (appIDDataRFW) => {
    const applicationIds = [];

    // Check if appIDDataRFW is an array
    if (Array.isArray(appIDDataRFW)) {
      appIDDataRFW.forEach((branchData) => {
        branchData.application_ids.forEach((appId) => {
          applicationIds.push(appId);
        });
      });
    } else {
      console.error("appIDDataRFW is not an array:", appIDDataRFW);
    }

    return applicationIds;
  };

  const handleRecall = (record) => {
    Modal.confirm({
      title: "Are you sure you want to recall the documents?",
      content: `This action will recall the documents for Application ID: ${record.app_id}.`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => confirmRecall(record),
    });
  };


  const confirmRecall = (record) => {
    const app_id_array = [record.app_id];
    console.log("Documents recalled for Application ID:", app_id_array);
    const requestBody = {
      type: "1",
      app_id: app_id_array,
    };

    console.log("confirm req body", requestBody);

    dispatch(addWarehouseEntry({ jwtBearer, requestBody }))
      .then(() => {
        dispatch(fetchPDDWarehouse({ jwtBearer }));
        form.resetFields();
        setSelectedAppIDs([]);
        setSelectedAppIDsPhy([]);
        setShowAddBranchEntryModalVisible(false);
      })
      .catch((error) => {
        console.error("Error updating reschedule date:", error);
      });
  };

  const chatData = useSelector(
    (state) => state.fetchPDDWarehouse?.data?.message || []
  );

  console.log("chatData-fetchPDDWarehouse", chatData);

  const handleFileChange = (info) => {
    if (info.file.status === "done") {
      notification.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      notification.error(`${info.file.name} file upload failed.`);
    }
  };

  // Handle file upload (triggered by button click)
  const handleFileUpload = async () => {
    if (!selectedFile || !jwtBearer) {
      console.error("Please select a file and ensure you are logged in.");
      return;
    }

    const reqBody = {
      applicationId: currentAppId,
      fileType: "noc",
    }

    console.log("reqBody", reqBody)

    dispatch(
      uploadNocCalFile({
        file: selectedFile,
        ...reqBody,
        jwtBearer,
      })
    )
      .then(() => {
        dispatch(fetchPDDWarehouse({ jwtBearer }));
        form.resetFields();
        setSelectedAppIDs([]);
        setSelectedAppIDsPhy([]);
        setShowAddBranchEntryModalVisible(false);
      })
      .catch((error) => {
        console.error("Error updating reschedule date:", error);
      });

  };

  const columns = [
    {
      title: "APPLICATION ID",
      dataIndex: "app_id",
      key: "app_id",
    },
    {
      title: "NAME",
      dataIndex: "app_name",
      key: "app_name",
    },
    {
      title: "OXYZO ID",
      dataIndex: "partner_loan_id",
      key: "partner_loan_id",
    },
    {
      title: "BRANCH",
      dataIndex: "branch",
      key: "branch",
    },
    {
      title: "POD NUMBER",
      dataIndex: "trk_no",
      key: "trk_no",
    },
    {
      title: "PRODUCT",
      dataIndex: "product",
      key: "product",
    },
    // {
    //   title: "STATUS",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (value) => {
    //     if (value === "12") {
    //       return <span>In Warehouse</span>;
    //     } else if (value === "13") {
    //       return <span>Recalled</span>;
    //     }
    //     return value;
    //   },
    // },
    {
      title: "RETURN STATUS",
      dataIndex: "return_status",
      key: "return_status",
      render: (value) => {
        if (value === "12") {
          return <span>In Warehouse</span>;
        } else if (value === "13") {
          return <span>Recalled</span>;
        }
        return value;
      },
    },

    // {
    //   title: "UPDATED AT",
    //   dataIndex: "updated_at",
    //   key: "updated_at",
    //   render: (value) => new Date(value).toLocaleString(),
    // },
    {
      title: "BOX NO",
      dataIndex: "box_no",
      key: "box_no",
      render: (value) => value,
    },
    {
      title: "CARTON NO",
      dataIndex: "carton_no",
      key: "carton_no",
      render: (value) => value,
    },
    {
      title: "FILE BARCODE NO",
      dataIndex: "file_barcode_no",
      key: "file_barcode_no",
    },

    {
      title: "PROP FILE NO",
      dataIndex: "prop_file_no",
      key: "prop_file_no",
      render: (value) => value,
    },
    {
      title: "PROP FILE BARCODE NO",
      dataIndex: "prop_file_barcode_no",
      key: "prop_file_barcode_no",
      render: (value) => value,
    },
    // {
    //   title: "CREATED AT",
    //   dataIndex: "created_at",
    //   key: "created_at",
    //   render: (value) => moment(value).utc().local().format('YYYY-MM-DD HH:mm:ss A'),
    // },
    {
      title: "DOC DETAILS",
      dataIndex: "doc_details",
      key: "doc_details",
      render: (value) => {
        // Return a View Docs button that opens the drawer
        return (
          <Button type="link" onClick={() => handleViewDocs(value)}>
            View Docs
          </Button>
        );
      },
    },
    {
      title: "ACTIONS",
      key: "actions",
      render: (_, record) => {
        const {
          product,
          box_no,
          carton_no,
          file_barcode_no,
          prop_file_no,
          prop_file_barcode_no,
        } = record;

        console.log("record action", record);

        const isRecallEnabled =
          (product === "PL-USEC" && box_no && carton_no && file_barcode_no) ||
          (product === "PL-SEC" &&
            box_no &&
            carton_no &&
            file_barcode_no &&
            prop_file_no &&
            prop_file_barcode_no);

        console.log("isRecallEnabled?", isRecallEnabled);

        return (
          record.status !== "13" && (
            <Button
              type="primary"
              onClick={() => handleRecall(record)}
              disabled={!isRecallEnabled}
            >
              Recall
            </Button>
          )
        );
      },
    },
    // {
    //   title: "noc",
    //   key: "noc_file",
    //   render: (_, record) => {
    //     const { app_id, status,noc_file } = record;

    //     if (status === "13") {
    //       return (
    //         <div style={{ display: "flex", flexDirection: "row",justifyContent:"center",alignItems:"center" }}>
    //           <Upload
    //             showUploadList={false}
    //             beforeUpload={(file) => {
    //               setSelectedFile(file);
    //               setCurrentAppId(app_id); 
    //               return false; 
    //             }}
    //           >
    //             <Button icon={<UploadOutlined />} style={{marginTop:"10px"}}>Select File</Button>
    //           </Upload>
    //           <Button
    //             type="primary"
    //             onClick={handleFileUpload}
    //             loading={uploading}
    //             style={{ marginTop: 10 }}
    //           >
    //             {uploading ? "Uploading..." : "Upload"}
    //           </Button>
    //         </div>
    //       );
    //     }

    //     return null;
    //   },
    // },
    {
      title: "noc",
      key: "noc_file",
      render: (_, record) => {
        const { app_id, status, noc_file } = record;

        // Check if noc_file has the value '10'
        if (noc_file) {
          return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <a href={noc_file} target="_blank" rel="noopener noreferrer">Download</a>
            </div>
          );
        }

        // If status is "13", show the file upload components
        if (status === "13") {
          return (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "10px" }}>
              <Upload
                showUploadList={false}
                beforeUpload={(file) => {
                  setSelectedFile(file);
                  setCurrentAppId(app_id);
                  return false; // Prevent auto-upload
                }}
              >
                <Button icon={<UploadOutlined />} style={{ marginTop: "10px" }}>Select File</Button>
              </Upload>
              <Button
                type="primary"
                onClick={handleFileUpload}
                loading={uploading}
                style={{ marginTop: 10 }}
              >
                {uploading ? "Uploading..." : "Upload"}
              </Button>
            </div>
          );
        }

        return null;
      },
    }


  ];

  const handleViewDocs = (docDetails) => {
    setSelectedDocDetails(docDetails);
    setDrawerVisible(true);
  };

  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };

  const receivedDocsData = useSelector(
    (state) => state.fetchPDDWarehouse?.data?.received_docs || []
  );
  const physicalDocs = useSelector(
    (state) => state.fetchPDDWarehouse?.data?.physical_docs || []
  );

  console.log("Physical docs data - ", receivedDocsData);

  // const applicationIds = extractApplicationIds(receivedDocsData, appIDData);
  // console.log('Extracted app id - ', applicationIds);

  const appIDData = useSelector(
    (state) => state.fetchPDDAppID?.data?.message || []
  );
  // console.log("appIdData",appIDData)
  const appIDDataRFW = useSelector(
    (state) => state.fetchPDDAppIDRFW?.data?.message || []
  );
  console.log("appIDDataRFW", appIDDataRFW);
  const appIDDataRFWPhy = useSelector(
    (state) => state.fetchPDDAppIDRFWPhy?.data?.message || []
  );
  console.log("appIDDataRFWPhy", appIDDataRFWPhy);
  const masterData = useSelector(
    (state) => state.fetchPDDMaster?.data?.message || []
  );
  // console.log("masterData",masterData)

  // const applicationIds = extractApplicationIds(receivedDocsData, appIDData);
  const applicationIds = extractApplicationIds(appIDDataRFW);
  console.log("Extracted app id - ", applicationIds);

  const availableApplicationIds = applicationIds.filter(
    (appId) => !selectedAppIDs.includes(appId)
  );

  const applicationIdsPhy = extractApplicationIds(appIDDataRFWPhy);

  const availableApplicationIdsPhy = applicationIdsPhy.filter(
    (appId) => !selectedAppIDsPhy.includes(appId)
  );

  const courierVendorData = masterData.find(
    (item) => item.field === "courier_vendor"
  );

  useEffect(() => {
    if (courierVendorData) {
      const vendorOptions = courierVendorData.value.map((vendor) => ({
        value: vendor,
        label: vendor,
      }));
      setVendors(vendorOptions);
    } else {
      // Handle the case where courierVendorData is undefined, e.g., setVendors to an empty array
      setVendors([]);
    }
  }, [courierVendorData, setVendors]);

  // console.log("Master Data - ", masterData);
  // Further processing to remove spaces, if necessary

  // Convert jwt_branch string to an array of branch codes
  const branchArray = user_branch.replace(/[()]/g, "").split(",");

  // Filter appIDData to include only entries where BR_CD matches jwt_branch
  const cleanAppIDData = appIDData.filter((app) =>
    branchArray.includes(app.BR_CD.split(" ")[0])
  );
  // console.log("App ID data - ", appIDData);

  // console.log("chat data warehouse - ", chatData);

  useEffect(() => {
    console.log("State refreshed");
    if (chatData && chatData.length > 0) {
      const formattedData = chatData.map((item) => {
        const formattedItem = {};

        // Dynamically add all keys and values from item to formattedItem
        Object.keys(item).forEach((key) => {
          formattedItem[key] = item[key];
        });

        console.log("formattedItem", formattedItem);

        return formattedItem;
      });

      // console.log("Table data - ", formattedData);
      setTableData(formattedData);
    }
  }, [chatData]);

  const handleRowClick = async (record) => {
    console.log("Selected record - ", record);
  };

  useEffect(() => {
    if (!searchValue || !searchField) {
      setFilteredData(chatData); // No search applied
    } else {
      const filtered = chatData.filter((item) => {
        const fieldValue = item[searchField];
        if (Array.isArray(fieldValue)) {
          return fieldValue.some((subValue) =>
            subValue
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          );
        }
        return fieldValue
          ?.toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      setFilteredData(filtered);
    }
  }, [chatData, searchValue, searchField]);

  console.log("filtered data", filteredData);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearchFieldChange = (value) => {
    setSearchField(value);
    setEnableSearch(false); // Enable the search input
  };

  // Convert jwt_group string to an array of branch IDs
  const jwtGroupArray = user_branch.slice(1, -1).split(",");

  const handleCloseModal = () => {
    setShowWarehouseUpload(false);
    setFileModalVisible(false);
    setPhysicalModalVisible(false);
    setSelectedCardData(null);
  };

  const floatButtonProps = useSpring({
    to: { transform: "translateY(0px)", opacity: 1 },
    from: { transform: "translateY(100px)", opacity: 0 },
    config: { tension: 200, friction: 10 },
  });

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const onFinish = (values) => {
    console.log("Submitted values - ", values, selectedOption);
    if (selectedOption == "addFile") {
      const requestBody = {
        type: "3",
        kyc_carton: values.boxNumber,
        app_id: selectedAppIDs,
      };
      dispatch(addWarehouseEntry({ jwtBearer, requestBody }))
        .then(() => {
          // Dispatch fetchChatData after updateRescheduleDate is successful
          dispatch(fetchPDDWarehouse({ jwtBearer }));
          // Reset the form fields after submission
          form.resetFields();
          // Clear the selected application IDs as well
          setSelectedAppIDs([]);
          setSelectedAppIDsPhy([]);
          // Optionally, close the modal if that's the desired behavior
          setShowWarehouseUpload(false);
        })
        .catch((error) => {
          console.error("Error updating reschedule date:", error);
        });
    } else {
      const requestBody = {
        type: "2",
        phy_carton: values.cartonNumber,
        boxes: values.files,
      };

      dispatch(addWarehouseEntry({ jwtBearer, requestBody }))
        .then(() => {
          // Dispatch fetchChatData after updateRescheduleDate is successful
          dispatch(fetchPDDWarehouse({ jwtBearer }));
          // Reset the form fields after submission
          form.resetFields();
          // Clear the selected application IDs as well
          setSelectedAppIDs([]);
          setSelectedAppIDsPhy([]);
          // Optionally, close the modal if that's the desired behavior
          setShowWarehouseUpload(false);
        })
        .catch((error) => {
          console.error("Error updating reschedule date:", error);
        });
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  const handleSelectChange = (value) => {
    if (!selectedAppIDs.includes(value)) {
      setSelectedAppIDs([...selectedAppIDs, value]);
    }
  };

  const handleRemoveAppID = (appID) => {
    setSelectedAppIDs(selectedAppIDs.filter((id) => id !== appID));
  };

  const handleAppIDChange = (name, value) => {
    setSelectedAppIDs((prev) => {
      const updated = [...prev];
      if (value) {
        updated.push(value);
      } else {
        const index = updated.indexOf(name);
        if (index > -1) {
          updated.splice(index, 1);
        }
      }
      return updated;
    });
  };

  const boxShadowFunc = (e) => {
    console.log("Color func - ", e);
    switch (e) {
      case "In-Transit":
        return "0px 4px 8px rgba(245, 144, 39, 0.3)";
      case "In-Query":
        return "0px 4px 8px rgba(255, 0, 0, 0.3)";
      case "Received":
        return "0px 4px 8px rgba(33, 206, 66, 0.8)";
      default:
        return "0px 4px 8px rgba(0, 0, 0, 0.1)";
    }
  };

  return (
    <>
      <>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Form layout="inline" style={{ marginLeft: "10px" }}>
            <div
              className="selectAndsearch"
              style={{ display: "flex", margin: "0 30px" }}
            >
              <Form.Item style={{ marginBottom: "20px" }}>
                <Select
                  value={searchField}
                  onChange={handleSearchFieldChange}
                  style={{
                    width: 170,
                    height: 49,
                    borderRadius: "5px",
                    border: "1px solid #d9d9d9",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    fontSize: "16px",
                    //   textAlign:"center"
                  }}
                  dropdownStyle={{
                    borderRadius: "5px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Option value="tracking_number">Tracking Number</Option>
                  <Option value="application_ids">Application ID</Option>
                </Select>
              </Form.Item>

              <Form.Item className="searchbarContainer">
                <Input
                  placeholder="Search..."
                  value={searchValue}
                  onChange={handleSearchChange}
                  prefix={
                    <SearchOutlined
                      style={{ color: "#1890ff", marginRight: "10px" }}
                    />
                  }
                  style={{
                    height: 49,
                    borderRadius: "5px",
                    border: "1px solid #d9d9d9",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    fontSize: "16px",
                  }}
                  className="mySearchBar"
                  disabled={enableSearch}
                />
              </Form.Item>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "78vw",
                textAlign: "end",
              }}
            >
              <animated.div
                style={{
                  ...floatButtonProps,
                  position: "fixed",
                  top: "140px",
                  right: "20px",
                }}
              ></animated.div>
            </div>

            <animated.div
              style={{
                ...floatButtonProps,
                position: "fixed",
                bottom: "20px",
                right: "20px",
              }}
            >
              <Button
                type="primary"
                shape="circle"
                icon={
                  openBox ? (
                    <CodeSandboxOutlined
                      style={{ fontSize: "36px", marginLeft: "3px" }}
                    />
                  ) : (
                    <DropboxOutlined
                      style={{ fontSize: "36px", marginLeft: "3px" }}
                    />
                  )
                }
                size="large"
                onClick={addBranchNewEntry}
                // onClick={openBoxDrawer}
                onMouseEnter={() => setOpenBox(false)}
                onMouseLeave={() => setOpenBox(true)}
                style={{
                  width: "70px", // Increased width
                  height: "70px", // Increased height
                  fontSize: "24px", // Increased font size
                  backgroundColor: "red",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                }}
              />
            </animated.div>
          </Form>

          {/* refresh icon */}
          <div style={{ marginRight: "50px" }}>
            <Button
              type="primary"
              shape="circle"
              size="large"
              onClick={handleRefresh}
              style={{
                width: "40px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                backgroundColor: "white",
                position: "relative",
                padding: 0,
                borderRadius: "50%",
              }}
            >
              {loading ? (
                <span
                  className={loading ? "spin" : ""}
                  style={{
                    fontSize: "16px",
                    fontWeight: "900",
                    color: "blue",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    transformOrigin: "50% 50%",
                  }}
                >
                  <RiRefreshLine
                    style={{
                      fontSize: "40px",
                      color: "blue",
                      marginLeft: "4px",
                    }}
                    className="spin"
                  />
                </span>
              ) : (
                <RiRefreshLine
                  style={{
                    fontSize: "40px",
                    color: "blue",
                    marginLeft: "4px",
                  }}
                />
              )}
            </Button>
          </div>
        </div>

        {loading ? (
          <div className="spinner-container">
            <div
              style={{
                height: "800px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Lottie
                animationData={truckAnimation}
                loop={true}
                style={{ width: "400px", height: "400px" }}
              />
            </div>
          </div>
        ) : (
          <MyStyledTable
            style={{ margin: "0 40px", marginTop: "30px" }}
            columns={columns}
            dataSource={filteredData}
            rowKey="app_id"
            pagination={{ pageSize: 5 }}
          />
        )}
      </>

      <WarehouseUploadDrawer
        showAddBranchEntryModalVisible={showWarehouseUpload}
        handleCloseModal={handleCloseModal}
        jwtBearer={jwtBearer}
        fetchData={fetchData}
      />

      <DocsDetailsDrawer
        docDetails={selectedDocDetails}
        visible={drawerVisible}
        onClose={handleCloseDrawer}
      />
    </>
  );
};

export default HoToVendor;
