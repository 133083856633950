import React, { useEffect, useState } from "react";
import { AiFillCar } from "react-icons/ai";
import { FaTable } from "react-icons/fa";
import { MyStyledTable } from "../../style/styleComp";
import moment from "moment";
import "../../style/customStyles.css";
import { LeftTyre } from "../../style/truck/LeftTyre";
import { RightTyre } from "../../style/truck/RightTyre";
import { Circle } from "../../style/truck/Circle";
import CargoArea from "../../style/truck/CargoArea";
import Window from "../../style/truck/Window";
import EngineHeadLight from "../../style/truck/EngineHeadLight";
import BottomFrame from "../../style/truck/BottomFrame";
import { PiSquaresFour } from "react-icons/pi";
import { IoListOutline } from "react-icons/io5";
import {
  Form,
  Input,
  Select,
  Button,
  InputNumber,
  Card,
  Tag,
  Modal,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import {
  StyledCard,
  RefreshButton,
  CardContainer,
} from "../../style/styleComp";
import { useSelector, useDispatch } from "react-redux";
import { fetchPDD } from "../../../../redux/slice/getPDDData";
import { fetchPDDAppID } from "../../../../redux/slice/getPDDAppID";
import { HashLoader } from "react-spinners";
import BeforeReceiveDrawer from "./HoInTransitDrawer";
import AfterReceivedDrawer from "./HoReceivedDrawer";
import jwt_decode from "jwt-decode";
import { addMissingDocs } from "../../../../redux/slice/pddAddMissingDocs";
import { updateBranchEntry } from "../../../../redux/slice/pddUpdateBranchEntry";
import { RiRefreshLine } from "react-icons/ri";
import { useSpring, animated } from "react-spring";
import truckAnimation from "../../lotties/loaderTruck.json";
import Lottie from "lottie-react";
import { fetchPDDMissingDocs } from "../../../../redux/slice/getPDDMissingDocs";
import { motion } from "framer-motion";

const { Option } = Select;

const BranchToHO = ({ jwtBearer, extraProp }) => {
  const dispatch = useDispatch();
  const [receivedModalVisible, setReceivedModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [searchField, setSearchField] = useState("Select");
  const [searchValue, setSearchValue] = useState("");
  const [modalVisible, setDetailModalVisible] = useState(false);
  const [selectedCardData, setSelectedCardData] = useState(null);
  const [selectedAppIDs, setSelectedAppIDs] = useState([]);
  const [branches, setBranches] = useState([]);
  const [isAnimated, setIsAnimated] = useState(false);
  const [enableSearch, setEnableSearch] = useState(true);
  const [verifyModalVisible, setVerifyModalVisible] = useState(false);
  const [missingPDDDocs, setMissingPDDDocs] = useState("");
  const [viewTable, setViewTable] = useState(false);
  const [selectedButton, setSelectedButton] = useState("truckIcon");

  const fetchData = () => {
    setLoading(true);
    Promise.all([
      dispatch(fetchPDD({ jwtBearer })),
      dispatch(fetchPDDAppID({ jwtBearer })),
    ])
      .then(() => setLoading(false))
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    // const intervalId = setInterval(fetchData, 1000 * 60 * 5);
    // return () => clearInterval(intervalId);
  }, [dispatch, jwtBearer]);

  const chatData = useSelector((state) => state.fetchPDD?.data?.message || []);
  const appIDData = useSelector(
    (state) => state.fetchPDDAppID?.data?.message || []
  );

  const statusData = useSelector((state) => state.fetchPDD?.data?.status || []);

  const countData = useSelector((state) => state.fetchPDD?.data?.count || []);

  console.log("status data", statusData);
  console.log("Count data", countData);
  // const branchArray = jwt_decode(jwtBearer).branch_id.replace(/[()]/g, '').split(',');
  const branchArray = extraProp.branch_id.replace(/[()]/g, "").split(",");

  const cleanAppIDData = appIDData.filter((app) =>
    branchArray.includes(app.BR_CD.split(" ")[0])
  );

  const floatButtonProps = useSpring({
    to: { transform: "translateY(0px)", opacity: 1 },
    from: { transform: "translateY(100px)", opacity: 0 },
    config: { tension: 200, friction: 10 },
  });

  useEffect(() => {
    if (chatData && chatData.length > 0) {
      const formattedData = chatData.map((item) => ({
        ...item,
      }));
      setTableData(formattedData);
    }
  }, [chatData]);

  const getColumns = (data) => {
    if (!data || data.length === 0) return [];

    const keys = Object.keys(data[0]);
    return keys.map((key) => ({
      title: (
        <strong style={{ fontSize: "18px" }}>
          {key.replace(/_/g, " ").toUpperCase()}
        </strong>
      ),
      dataIndex: key,
      key: key,
      render: (text) => (
        <span style={{ fontSize: "18px" }}>
          {Array.isArray(text) ? text.join(", ") : text}
        </span>
      ),
    }));
  };

  const columns = getColumns(chatData);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearchFieldChange = (value) => {
    setSearchField(value);
    if (value) {
      setEnableSearch(false);
    }
  };

  var filteredData = tableData.filter((item) => {
    if (!searchValue) return true;
    const valueToSearch = item[searchField]?.toString().toLowerCase();
    return valueToSearch.includes(searchValue.toLowerCase());
  });

  filteredData = filteredData.reverse();

  console.log("Filtered HO Data - ", filteredData);

  const handleSelectChange = (value) => {
    if (!selectedAppIDs.includes(value)) {
      setSelectedAppIDs([...selectedAppIDs, value]);
    }
  };

  const handleRemoveAppID = (appID) => {
    setSelectedAppIDs(selectedAppIDs.filter((id) => id !== appID));
  };

  const handleCloseModal = () => {
    setDetailModalVisible(false);
    setVerifyModalVisible(false);
    setSelectedCardData(null);
  };

  const handleStatusChange = (updatedData) => {
    console.log("Change Status - ", updatedData);
    const requestBody = {
      tracking_number: updatedData.tracking_number,
      app_ids: updatedData.app_id,
      status: updatedData.status,
    };

    dispatch(updateBranchEntry({ jwtBearer, requestBody }))
      .then(() => {
        // dispatch(fetchPDD({ jwtBearer, movement: 'Branch' }));
        dispatch(fetchPDD({ jwtBearer }));
        setDetailModalVisible(false);
      })
      .catch((error) => {
        console.error("Error updating courier status:", error);
      });
  };

  const handleMissingDocs = (updatedData) => {
    const requestBody = {
      branch: updatedData.branch,
      tracking_number: updatedData.tracking_number,
      docs: updatedData.docs,
    };

    dispatch(addMissingDocs({ jwtBearer, requestBody }))
      .then(() => {
        dispatch(fetchPDD({ jwtBearer }));
        setDetailModalVisible(false);
        setVerifyModalVisible(false);
      })
      .catch((error) => {
        console.error("Error updating courier status:", error);
      });
  };

  const handleRefresh = () => {
    setIsAnimated(true);
    setLoading(true);
    console.log("refresing");
    setTimeout(() => {
      setLoading(false);
      setIsAnimated(false);
    }, 1000);
    fetchData();
  };

  const boxShadowFunc = (e) => {
    console.log("Color func - ", e);
    switch (e) {
      case "1":
        return "0px 4px 8px rgba(245, 144, 39, 0.3)";
      case "In-Query":
        return "0px 4px 8px rgba(255, 0, 0, 0.3)";
      case "Received":
        return "0px 4px 8px rgba(33, 206, 66, 0.8)";
      default:
        return "0px 4px 8px rgba(0, 0, 0, 0.1)";
    }
  };

  const bottomFrameVerification = (e) => {
    console.log("verification status - ", e);
    switch (e) {
      case "4":
        return "orange";
      case "5":
        return "green";
      case "8":
        return "red";
      default:
        return "0px 4px 8px rgba(0, 0, 0, 0.1)";
    }
  };
  const headLightGradient = (e) => {
    // console.log("Color func - ", e);
    switch (e) {
      // verification pending
      case "4":
        return "linear-gradient(to right, white,white)";
      // verified
      case "5":
        return "linear-gradient(to right, white,white)";
      // missing/damaged
      case "8":
        return "linear-gradient(to right, rgba(255, 0, 0, 0.8) , rgba(255, 0, 0, 0.1))";
      default:
        return "linear-gradient(to right, white,white)";
    }
  };

  const handleInQueryCheckboxChange = (e) => {
    setIsInQueryChecked(e.target.checked);
  };

  function Circle() {
    return (
      <div
        style={{
          width: "28px",
          height: "13px",
          backgroundColor: "white",
          borderTopLeftRadius: "110px",
          borderTopRightRadius: "110px",
          border: "2px solid gray",
          borderBottom: "0",
        }}
      ></div>
    );
  }

  const handleCardClick = async (record) => {
    console.log("Card status - ", record);
    setSelectedCardData(record);
    if (record.status == "1") {
      setDetailModalVisible(true);
    } else if (record.status == "3") {
      const requestBody = {
        app_ids: record.application_ids,
      };

      const missingResponse = await dispatch(
        fetchPDDMissingDocs({ jwtBearer, requestBody })
      );
      setMissingPDDDocs(missingResponse.payload?.data);
      console.log("Missing Doc Response - ", missingResponse.payload?.data);
      // setAvailableAppIDs(userResponse.payload?.available_app_ids)
      setVerifyModalVisible(true);
    }
  };

  const statusCodeToText = (status) => {
    switch (status) {
      case "1":
        return "In-Transit";
      case "2":
        return "In-Query";
      case "3":
        return "Received";
      default:
        return "Unknown Status";
    }
  };

  const verificationCodeToText = (verification) => {
    switch (verification) {
      case "4":
        return "Verification Pending";
      case "5":
        return "Verified";
      case "8":
        return "Missing/Damaged Documents";
      default:
        return "Unknown Verification";
    }
  };

  const verificationCodeForTableToText = (verification) => {
    console.log("verification status for table", verification);
    switch (verification) {
      case "4":
        return "Verification Pending At HO";
      case "5":
        return "Verified";
      case "8":
        return "Missing/Damaged Documents";
      default:
        return "Unknown Verification";
    }
  };

  const trackingNumberFilters = [
    ...new Set(filteredData.map((record) => record.tracking_number)),
  ].map((value) => ({
    text: value,
    value: value,
  }));

  const myColumns = [
    {
      title: "Tracking Number",
      dataIndex: "tracking_number",
      key: "tracking_number",
      filters: trackingNumberFilters,
      onFilter: (value, record) => record.tracking_number === value,
      filterSearch: true,
    },
    {
      title: "Verification",
      dataIndex: "verification",
      key: "verification",
      render: (verification) => verificationCodeToText(verification),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => statusCodeToText(status),
    },
    { title: "Vendor", dataIndex: "vendor", key: "vendor" },
    { title: "Branch", dataIndex: "branch", key: "branch" },
    { title: "Created At", dataIndex: "created_at", key: "created_at" },
  ];

  // for table
  const myFormattedData = filteredData.map((item) => ({
    tracking_number: item.tracking_number,
    verification: item.verification,
    // verification: verificationCodeForTableToText(item.verification),
    status: item.status,
    vendor: item.vendor,
    branch: item.branch,
    created_at: moment(item.created_at).format("DD-MM-YYYY"),
    fullData: item,
  }));

  const handleTableRowClick = async (row) => {
    console.log("Card status - ", row.fullData);
    setSelectedCardData(row.fullData);
    if (row.fullData.status == "1") {
      setDetailModalVisible(true);
    } else if (row.fullData.status == "3") {
      const requestBody = {
        app_ids: row.fullData.application_ids,
      };

      const missingResponse = await dispatch(
        fetchPDDMissingDocs({ jwtBearer, requestBody })
      );
      setMissingPDDDocs(missingResponse.payload?.data);
      console.log("Missing Doc Response - ", missingResponse.payload?.data);
      // setAvailableAppIDs(userResponse.payload?.available_app_ids)
      setVerifyModalVisible(true);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "0 10px",
        }}
      >
        <Form layout="inline">
          <div
            className="selectAndsearch"
            style={{ display: "flex", margin: "0 30px" }}
          >
            <Form.Item style={{ marginBottom: "20px" }}>
              <Select
                value={searchField}
                onChange={handleSearchFieldChange}
                style={{
                  width: 170,
                  height: 49,
                  borderRadius: "5px",
                  border: "1px solid #d9d9d9",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  fontSize: "16px",
                  //   textAlign:"center"
                }}
                dropdownStyle={{
                  borderRadius: "5px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Option value="tracking_number">Tracking Number</Option>
                <Option value="application_ids">Application ID</Option>
              </Select>
            </Form.Item>

            <Form.Item className="searchbarContainer">
              <Input
                placeholder="Search..."
                value={searchValue}
                onChange={handleSearchChange}
                prefix={
                  <SearchOutlined
                    style={{ color: "#1890ff", marginRight: "10px" }}
                  />
                }
                style={{
                  height: 49,
                  borderRadius: "5px",
                  border: "1px solid #d9d9d9",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  fontSize: "16px",
                }}
                className="mySearchBar"
                disabled={enableSearch}
              />
            </Form.Item>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "78vw",
              textAlign: "end",
            }}
          >
            <animated.div
              style={{
                ...floatButtonProps,
                position: "fixed",
                top: "140px",
                right: "20px",
              }}
            ></animated.div>
          </div>
        </Form>

        {/* right container */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "40px",
            marginBottom: "20px",
            justifyContent: "center",
            marginRight: "50px",
            position:"relative",
            top:"-50px"
          }}
        >
          {/* car and table */}

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              gap: "5px",
              borderRadius: "10px",
              // padding: "5px",
              // boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            }}
          >
            <button
              onClick={() => {
                setViewTable(false);
                setSelectedButton("truckIcon");
              }}
              style={{
                background:
                  selectedButton === "truckIcon" ? "#f0f0f0" : "transparent",
                border: "none",
                fontSize: "28px",
                cursor: "pointer",
                // padding: "5px 10px",
                paddingTop: "10px",
                paddingBottom: "5px",
                paddingLeft: "7px",
                paddingRight: "7px",
                borderRadius: "8px",
                transition: "background-color 0.3s ease, transform 0.2s ease",
                boxShadow:
                  selectedButton === "truckIcon"
                    ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                    : "none",
              }}
              onMouseEnter={(e) => (e.target.style.transform = "scale(1.1)")}
              onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
            >
              <PiSquaresFour />
            </button>
            <button
              onClick={() => {
                setViewTable(true);
                setSelectedButton("table");
              }}
              style={{
                background:
                  selectedButton === "table" ? "#f0f0f0" : "transparent",
                border: "none",
                fontSize: "28px",
                cursor: "pointer",
                paddingTop: "10px",
                paddingBottom: "5px",
                paddingLeft: "7px",
                paddingRight: "7px",
                borderRadius: "8px",
                transition: "background-color 0.3s ease, transform 0.2s ease",
                boxShadow:
                  selectedButton === "table"
                    ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                    : "none",
              }}
              onMouseEnter={(e) => (e.target.style.transform = "scale(1.1)")}
              onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
            >
              <IoListOutline />
            </button>
          </div>

          {/* refresh icon */}
          <div>
            <Button
              type="primary"
              shape="circle"
              size="large"
              onClick={handleRefresh}
              style={{
                width: "40px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                backgroundColor: "white",
                position: "relative",
                padding: 0,
                borderRadius: "50%",
              }}
            >
              {loading ? (
                <span
                  className={loading ? "spin" : ""}
                  style={{
                    fontSize: "16px",
                    fontWeight: "900",
                    color: "blue",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    transformOrigin: "50% 50%",
                  }}
                >
                  <RiRefreshLine
                    style={{
                      fontSize: "40px",
                      color: "blue",
                      marginLeft: "4px",
                    }}
                    className="spin"
                  />
                </span>
              ) : (
                <RiRefreshLine
                  style={{
                    fontSize: "40px",
                    color: "blue",
                    marginLeft: "4px",
                  }}
                />
              )}
            </Button>
          </div>
        </div>
      </div>
      {loading ? (
        // <div className='spinner-container'>
        //     <HashLoader color="#36d7b7" />
        // </div>
        <div
          style={{
            height: "400px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Lottie
            animationData={truckAnimation}
            loop={true}
            style={{ width: "400px", height: "400px" }}
          />
        </div>
      ) : (
        <>
          {viewTable ? (
            <MyStyledTable
              columns={myColumns}
              dataSource={myFormattedData}
              rowKey="tracking_number"
              pagination={{
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["5", "10", "20", "50"],
              }}
              onRow={(record) => ({
                onClick: () => handleTableRowClick(record),
              })}
              style={{margin:"30px"}}
            />
          ) : (
            <CardContainer
              style={{
                marginLeft: "2rem",
                marginRight: "2rem",
                margin: "0 40px",
                marginTop: "20px",
              }}
            >
              {filteredData.map((data, index) => (
                <card
                  style={{
                    position: "relative",
                    marginBottom: "40px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleCardClick(data)}
                >
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        width: "150px",
                        height: "60px",
                        border: "2px solid black",
                        backgroundColor: "transparent",
                        // position: 'absolute',
                        top: "10px",
                        left: "0px",
                        // borderRadius: '5px',
                        borderRadius: "10px 10px 0 0",
                      }}
                    >
                      <CargoArea data={data} />
                    </div>
                    <Window data={data} />
                    <div style={{ alignSelf: "flex-end" }}>
                      <EngineHeadLight data={data} />
                    </div>
                  </div>
                  <div style={{ position: "relative" }}>
                    <BottomFrame data={data} />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        position: "absolute",
                        top: "0",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          left: "180px",
                          top: "2.5px",
                        }}
                      >
                        <Circle />
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          left: "35px",
                          top: "2.5px",
                        }}
                      >
                        <Circle />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          {" "}
                          <LeftTyre />
                        </div>
                        <div>
                          <RightTyre />
                        </div>
                      </div>
                    </div>
                  </div>
                </card>
              ))}
            </CardContainer>
          )}
        </>
      )}
      <BeforeReceiveDrawer
        visible={modalVisible}
        onClose={handleCloseModal}
        data={selectedCardData}
        all_app_id={appIDData}
        app_id={cleanAppIDData}
        onStatusChange={handleStatusChange}
        onMissingDocs={handleMissingDocs}
        statusDetails={statusData}
        countDetails={countData}
      />

      <AfterReceivedDrawer
        visible={verifyModalVisible}
        onClose={handleCloseModal}
        data={selectedCardData}
        all_app_id={appIDData}
        app_id={cleanAppIDData}
        missing_docs={missingPDDDocs}
        // onStatusChange={handleStatusChange}
        onMissingDocs={handleMissingDocs}
        statusDetails={statusData}
      />
    </>
  );
};

export default BranchToHO;
