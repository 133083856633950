import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../apiConfig';

// Thunk to handle file upload
export const uploadNocCalFile = createAsyncThunk(
  'upload/uploadNocCalFile',
  async ({ file, applicationId, fileType, jwtBearer }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('application_id', applicationId);
      formData.append('file_type', fileType);

      const config = {
        headers: {
          Authorization: `Bearer ${jwtBearer}`,
          'Content-Type': 'multipart/form-data',
        },
      };

      const response = await axios.post(`${BASE_URL}pdd/upload/noc/cal/`, formData, config);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.detail || error.message);
    }
  }
);

// Initial state for the upload
const initialState = {
  uploading: false,
  fileUrl: null,
  error: null,
};

// Create the slice
const uploadSlice = createSlice({
  name: 'uploadNocCal',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadNocCalFile.pending, (state) => {
        state.uploading = true;
        state.error = null;
      })
      .addCase(uploadNocCalFile.fulfilled, (state, action) => {
        state.uploading = false;
        state.fileUrl = action.payload.url;
      })
      .addCase(uploadNocCalFile.rejected, (state, action) => {
        state.uploading = false;
        state.error = action.payload;
      });
  },
});


export default uploadSlice.reducer;
