import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../apiConfig'; 

export const uploadDocuments = createAsyncThunk(
  'uploadDocuments',
  async ({ jwtBearer, type, file }) => {
    
    const formData = new FormData();
    
    formData.append('file', file);
    formData.append('type', type);

    formData.forEach((value, key) => {
      console.log(`${key}: ${value}`); 
    });

    const config = {
      headers: {
        Authorization: `Bearer ${jwtBearer}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    try {
      const response = await axios.post(
        `${BASE_URL}pdd/upload/doc/to/warehouse?type=${type}`,
        formData,
        config 
      );

      return response.data;
    } catch (error) {
      console.error('Error uploading document:', error);
      if (error.response) {
        throw new Error(error.response.data.detail || 'Failed to upload file');
      }
      throw error; 
    }
  }
);

const uploadDocumentsSlice = createSlice({
  name: 'uploadPddDocuments',
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    errorMessage: null,
  },
  reducers: {}, 
  extraReducers: (builder) => {
    builder.addCase(uploadDocuments.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.errorMessage = null;
    });
    builder.addCase(uploadDocuments.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isError = false;
      state.errorMessage = null;
    });
    builder.addCase(uploadDocuments.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message; 
    });
  },
});

export default uploadDocumentsSlice.reducer;
